// import { TypeKeys as types } from './constants';
import { TypeKeys as generalTypes } from '../general/constants'
import { OfferAction } from './actions'
import { OfferState } from './types'

const initState = {
  detailsById: {},
}

const expenses = (
  state: OfferState = {
    detailsById: {},
    ...initState,
  },
  action: OfferAction
): OfferState => {
  switch (action.type) {
    case generalTypes.ADD_DATA: {
      if (action.payload.resource === 'offers') {
        return {
          ...state,
          detailsById: {
            ...state.detailsById,
            ...action.payload.data,
          },
        }
      } else {
        return state
      }
    }

    case generalTypes.CLEAR_ALL: {
      return {
        ...state,
        detailsById: {},
      }
    }
    /* todo: implement again
    case types.REPLACE_ALL: {
      return { ...state, detailsById: action.payload, currentEvent: Object.keys(action.payload)[0] };
    }*/

    default:
      return state
  }
}

export default expenses
