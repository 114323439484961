import { TypeKeys as types } from './constants'
import { GeneralActions } from '../general/actions'
import { Contact } from '../contacts/types'
import { Company } from '../companies/types'
import { ImportInfo } from './selectors'

interface AddContactsAction {
  type: types.ADD_CONTACTS
  payload: {
    contacts: Contact[]
  }
}

interface AddCompaniesAction {
  type: types.ADD_COMPANIES
  payload: {
    companies: Company[]
  }
}

interface RemoveContactsAction {
  type: types.REMOVE_CONTACTS
  payload: {}
}

interface RemoveCompaniesAction {
  type: types.REMOVE_COMPANIES
  payload: {}
}

interface SyncComaticCompanyAction {
  type: types.SYNC_COMPANIES
  payload: {
    info: ImportInfo
  }
}

interface SyncComaticContactAction {
  type: types.SYNC_CONTACTS
  payload: {
    info: ImportInfo
  }
}

interface ResetAction {
  type: types.RESET
  payload: {}
}

interface SyncAdvertizerCompaniesAction {
  type: types.SYNC_ADVERTIZER_COMPANIES
  payload: {}
}

interface SyncAdvertizerContactsAction {
  type: types.SYNC_ADVERTIZER_CONTACTS
  payload: {}
}

interface SkipCompanyImportAction {
  type: types.SKIP_COMPANY_IMPORT
  payload: {}
}

interface SkipComaticContactsAction {
  type: types.SKIP_CONTACT_IMPORT
  payload: {}
}

export type ComaticAction =
  | ResetAction
  | AddContactsAction
  | RemoveContactsAction
  | RemoveCompaniesAction
  | AddCompaniesAction
  | GeneralActions
  | SyncComaticContactAction
  | SyncComaticCompanyAction
  | SkipCompanyImportAction
  | SkipComaticContactsAction

/**
 * Selects the gender with the corresponding ID
 * @param id
 */
export const addContacts = (contacts: Contact[]): AddContactsAction => ({
  type: types.ADD_CONTACTS,
  payload: {
    contacts,
  },
})

export const removeContacts = (): RemoveContactsAction => ({
  type: types.REMOVE_CONTACTS,
  payload: {},
})

export const syncComaticContacts = (info: ImportInfo): SyncComaticContactAction => ({
  type: types.SYNC_CONTACTS,
  payload: {
    info,
  },
})

export const skipComaticContacts = (): SkipComaticContactsAction => ({
  type: types.SKIP_CONTACT_IMPORT,
  payload: {},
})

export const addCompanies = (companies: Company[]): AddCompaniesAction => ({
  type: types.ADD_COMPANIES,
  payload: {
    companies,
  },
})

export const removeCompanies = (): RemoveCompaniesAction => ({
  type: types.REMOVE_COMPANIES,
  payload: {},
})

export const syncComaticCompanies = (info: ImportInfo): SyncComaticCompanyAction => ({
  type: types.SYNC_COMPANIES,
  payload: {
    info,
  },
})

export const skipComaticCompanies = (): SkipCompanyImportAction => ({
  type: types.SKIP_COMPANY_IMPORT,
  payload: {},
})

export const syncAdvertizerCompanies = (): SyncAdvertizerCompaniesAction => ({
  type: types.SYNC_ADVERTIZER_COMPANIES,
  payload: {},
})

export const syncAdvertizerContacts = (): SyncAdvertizerContactsAction => ({
  type: types.SYNC_ADVERTIZER_CONTACTS,
  payload: {},
})

export const reset = (): ResetAction => ({
  type: types.RESET,
  payload: {},
})

export default {
  addContacts,
  addCompanies,
}
