import * as R from 'ramda'
import { RootState } from '../../store'
import { getActivities, getActivitiesOfUser } from '../activities/selectors'
import { Activity } from '../activities/types'
import { getFilter } from '../general/selectors'
import { StatisticNames } from '../general/types'
import { getFilteredState } from './statFilter'

export type EmployeeTaskStat = {
  description: string
  hours: number
  amount: number
  percent: number
}

export const getData = (rootState: RootState, userId: number): EmployeeTaskStat[] => {
  const filter = getFilter(rootState, StatisticNames.employee)

  const allStates = getFilteredState(rootState, filter)
  const state = allStates.filteredState

  const activities = getActivitiesOfUser(R.values(getActivities(state)), userId)

  const result: EmployeeTaskStat[] = R.reduce(
    (accumulator: EmployeeTaskStat[], value: Activity) => {
      const idx = R.findIndex(R.propEq('description', value.task.name))(accumulator)

      const employeeTask: EmployeeTaskStat = {
        description: value.task.name,
        hours: value.hours,
        amount: value.hourly_rate * value.hours,
        percent: 0,
      }

      if (idx < 0) {
        return [...accumulator, employeeTask]
      }

      const previousEmployeeTask = { ...accumulator[idx] }
      const withoutFoundTask = [...accumulator.slice(0, idx), ...accumulator.slice(idx + 1)]
      const resultTemp = [
        ...withoutFoundTask,
        // update task
        {
          description: previousEmployeeTask.description,
          hours: previousEmployeeTask.hours + employeeTask.hours,
          amount: previousEmployeeTask.amount + employeeTask.amount,
          percent: previousEmployeeTask.percent,
        },
      ]
      return resultTemp
    },
    []
  )(activities)

  const sumHours: number = R.reduce(
    (accumulator: number, value: EmployeeTaskStat) => {
      return accumulator + value.hours
    },
    0,
    result
  )

  const withPercent: EmployeeTaskStat[] = R.map((value: EmployeeTaskStat) => {
    return {
      ...value,
      percent: (value.hours / sumHours) * 100,
    }
  })(result)

  const sortByName: (e: EmployeeTaskStat[]) => EmployeeTaskStat[] = R.sortBy(R.prop('description'))

  const sortedResult: EmployeeTaskStat[] = sortByName(withPercent)

  return sortedResult
}
