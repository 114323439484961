import { compressToUTF16, decompressFromUTF16 } from 'lz-string'
import { RootState } from '../store'

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(decompressFromUTF16(serializedState))
  } catch (err) {
    return undefined
  }
}

export const saveState = (state: Partial<RootState>) => {
  try {
    const serializedState = compressToUTF16(JSON.stringify(state))
    localStorage.setItem('state', serializedState)
  } catch (err) {
    console.log('Error in saveState ' + JSON.stringify(err))
    // ignore write errors
  }
}
