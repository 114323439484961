import * as React from 'react'
import * as H from 'history'
import { compose } from 'recompose'
import { withStyles, WithStyles } from '@material-ui/core'
import { appStyle, AppClasses } from './appStyle'
import Sidebar from '../Sidebar'
import { RoutePath } from '../../page/routes'
import Header from '../Header/Header'

const sidebarImage = '/img/sidebar-3.jpg'
const logo = '/img/new_logo.png'

type LayoutProps = {
  routes: RoutePath[]
  // The location is needed to avoid render blocking of the layout component
  location?: H.Location
}

type ComponentProps = LayoutProps & WithStyles<AppClasses>

const Layout: React.StatelessComponent<ComponentProps> = props => {
  const { classes, ...rest } = props
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={props.routes}
        logoText={'Statistik tool'}
        logo={logo}
        image={sidebarImage}
        color="blue"
        {...rest}
      />
      <div className={classes.mainPanel}>
        <Header routes={props.routes} color={'primary'} {...rest} />
        <div className={classes.content}>{props.children}</div>
      </div>
    </div>
  )
}

export default compose<ComponentProps, LayoutProps>(withStyles(appStyle))(Layout)
