import { call, put, take } from 'redux-saga/effects'
import { TypeKeys } from '../modules/authentication/constants'
import { authenticate } from './api/authentication'
import { TypeKeys as generalTypes } from '../modules/general/constants'
import { fetchUsers } from './users'

function* logout() {
  // Empty localstorage from existing data
  yield localStorage.clear()
  yield put({
    type: generalTypes.CLEAR_ALL,
    payload: {
      reason: 'logout',
    },
  })
}

export function* logoutFlow() {
  while (true) {
    yield take(TypeKeys.LOGOUT)
    yield call(logout)
    yield put({
      type: TypeKeys.LOGOUT_SUCCESSFUL,
      payload: {
        isAuthenticated: false,
      },
    })
  }
}

/* with reducer
export function* saveToken(answer: {api_key: string, user_id: string}) {
  yield localStorage.setItem('token', answer.api_key);
  yield localStorage.setItem('userId', answer.user_id);
}*/

export function* loginFlow() {
  while (true) {
    try {
      const {
        payload: { email, password },
      } = yield take(TypeKeys.LOGIN)

      const { data } = yield call(authenticate, email, password)

      if (data.api_key && data.user_id) {
        // yield call(saveToken, data)
        yield put({
          type: TypeKeys.LOGIN_SUCCESSFUL,
          payload: {
            token: data.api_key,
            userId: data.user_id,
          },
        })

        // Check for additional rights immediately (only internal employees relevant)
        yield call(fetchUsers)
      }
      // Username or password wrong
      if (data.message) {
        yield put({
          type: TypeKeys.LOGIN_UNSUCCESSFUL,
          payload: {
            message: data.message,
          },
        })
      }
    } catch (err) {
      yield put({
        type: TypeKeys.LOGIN_ERROR,
        payload: {
          error: err,
        },
      })
    }
  }
}
