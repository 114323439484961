import * as R from 'ramda'
import { RootState } from '../../store'
import { getActivities, getActivitiesOfExtendedProjectList } from '../activities/selectors'
import {
  ExtendedProject,
  getProjects,
  // getSpesen, // Neu: Spesen in Zusatzleistungen
  mapToExtendedProjects,
} from '../projects/selectors'
import { getCustomers } from '../companies/selectors'
import {
  getExpenses,
  getExpensesOfExtendedProjectList,
  getExpensesTotal,
} from '../expenses/selectors'
import {
  getInvoices,
  getInvoicesOfExtendedProjectList,
  getInvoicesTotal,
} from '../invoices/selectors'
import { getActivitiesTotal } from '../activities/selectors'
import { Activity } from '../activities/types'
import { Company } from '../companies/types'
import { getFilter } from '../general/selectors'
import { StatisticNames } from '../general/types'
import { getFilteredState } from './statFilter'
import { getOffers } from '../offers/selectors'

// Administration, Projekt Management etc.
type TaskTotal = {
  [key: string]: number
}

export type CustomerTask = {
  customerNr?: number
  customerName: string
  billed: number
  fee: number // Honorar
  expenses: number // Zusatzleistungen
  // spesen: number // Neu: Spesen in Zusatzleistungen
  temporary: number // Prov. & AA
  tasks: TaskTotal // Leistungsgruppe
}

const getTaskStat =
  (projects: ExtendedProject[]) =>
  (customer: Company): CustomerTask => {
    const sameCustomer: (project: ExtendedProject) => boolean = R.pathEq(
      ['customer', 'id'],
      customer.id
    )

    const relProjects: ExtendedProject[] = R.filter(sameCustomer)(projects)

    // Billed amount
    const allInvoices = getInvoicesOfExtendedProjectList(relProjects)
    const billed = getInvoicesTotal(allInvoices)

    // Honorar
    const allActivities = getActivitiesOfExtendedProjectList(relProjects)
    const fee = getActivitiesTotal(allActivities)

    // TaskTotal
    const groupedActivities = R.groupBy((activity: Activity) => {
      return activity.task.name
    })(allActivities)

    const taskTotal: TaskTotal = R.map((activities: Activity[]) => {
      const sum = getActivitiesTotal(activities)
      return sum
    })(groupedActivities)

    const allExpenses = getExpensesOfExtendedProjectList(relProjects)
    const expenses = getExpensesTotal(allExpenses)
    // const spesen = getSpesen(fee) // Neu: Spesen in Zusatzleistungen

    return {
      customerNr: customer.id,
      customerName: customer.name,
      billed,
      tasks: taskTotal,
      expenses,
      // spesen, // Neu: Spesen in Zusatzleistungen
      fee,
      // Todo: Temporary unknown
      temporary: 0,
    }
  }

export const getData = (rootState: RootState): CustomerTask[] => {
  const filter = getFilter(rootState, StatisticNames.customer)

  const allStates = getFilteredState(rootState, filter)
  const state = allStates.filteredState

  const projects = getProjects(state)
  const offers = getOffers(state)
  const customers = getCustomers(state)
  const activitities = getActivities(state)
  const expenses = getExpenses(state)
  const invoices = getInvoices(state)

  const extendedProjects = mapToExtendedProjects(
    R.values(projects),
    customers,
    R.values(activitities),
    R.values(expenses),
    R.values(invoices),
    R.values(offers)
  )
  const getTaskStatOfCustomer = getTaskStat(extendedProjects)

  return R.map(getTaskStatOfCustomer)(customers)
}
