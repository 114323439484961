import * as React from 'react'
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment'
import { ChangeEvent } from 'react'
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { DataFilter } from '../../state/modules/stats/statFilter'
import { StatisticNames } from '../../state/modules/general/types'
import { RootState } from '../../state/store'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { getProjectLabels } from '../../state/modules/projects/selectors'
import { getFilter } from '../../state/modules/general/selectors'
import { updateFilter } from '../../state/modules/general/actions'
import { Moment } from 'moment'
import './daypickerStyle.css'
import DatePickerCustomInput from './DatePickerCustomInput'

type PublicProps = {
  statistic: StatisticNames
  disabledStart?: boolean
}

type InjectedProps = {
  filter: DataFilter
  changeLabel: (statistic: StatisticNames, label: string) => any
  changeEndDate: (statistic: StatisticNames, date: Moment) => any
  changeStartDate: (statistic: StatisticNames, date: Moment) => any

  labels: string[]
  statistic: StatisticNames
  disabledStart?: boolean
}

const AllFilter: React.StatelessComponent<InjectedProps> = ({
  changeLabel,
  changeStartDate,
  changeEndDate,
  filter,
  labels,
  statistic,
  disabledStart,
}) => {
  // Helper functions
  const change = (myStat: StatisticNames) => (e: ChangeEvent<HTMLSelectElement>) =>
    changeLabel(myStat, e.target.value)
  const changeDate = (myStat: StatisticNames, start: boolean) => (date: Date) => {
    start ? changeStartDate(myStat, moment(date)) : changeEndDate(myStat, moment(date))
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <FormControl>
          <InputLabel htmlFor="label-select">Projekt-Label</InputLabel>
          <Select
            value={filter.labels[0] ? filter.labels[0] : ''}
            onChange={change(statistic)}
            inputProps={{
              name: 'age',
              id: 'label-select',
            }}
            style={{ width: '140px' }}
          >
            <MenuItem value="" selected={!filter.labels[0]}>
              <em>Keine Auswahl</em>
            </MenuItem>
            {labels.map(label => (
              <MenuItem key={label} value={label} selected={filter.labels[0] === label}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl>
          <DayPickerInput
            component={DatePickerCustomInput({
              label: 'Von',
            })}
            format="DD.MM.YYYY"
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder={``}
            dayPickerProps={{
              locale: 'de-ch',
              localeUtils: MomentLocaleUtils,
              /*disabledDays: [
                {
                  after: filter.endDate ? filter.endDate.toDate() : null,
                },
            ]*/
            }}
            onDayChange={changeDate(statistic, true)}
            value={filter.startDate ? filter.startDate.toDate() : undefined}
            inputProps={{
              disabled: !!disabledStart,
            }}
          />
        </FormControl>
        <FormControl>
          <DayPickerInput
            component={DatePickerCustomInput({
              label: 'Bis',
            })}
            format="DD.MM.YYYY"
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder={``}
            dayPickerProps={{
              locale: 'de-ch',
              localeUtils: MomentLocaleUtils,
              /*disabledDays: [
                {
                  before: filter.startDate ? filter.startDate.toDate() : null,
                },
            ]*/
            }}
            onDayChange={changeDate(statistic, false)}
            value={filter.endDate ? filter.endDate.toDate() : undefined}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state: RootState, props: PublicProps) => ({
  labels: getProjectLabels(state),
  filter: getFilter(state, props.statistic),
  ...props,
})

const mapDispatchToProps = {
  // Todo: Check params
  changeLabel: (statistic: StatisticNames, label: string) =>
    updateFilter(statistic, { labels: [label] }),
  changeEndDate: (statistic: StatisticNames, date: Moment) =>
    updateFilter(statistic, {
      endDate: date,
    }),
  changeStartDate: (statistic: StatisticNames, date: Moment) =>
    updateFilter(statistic, {
      startDate: date,
    }),
}

export default compose<InjectedProps, PublicProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AllFilter)
