import moment from 'moment'
import { Moment } from 'moment'
import { User } from '../state/modules/users/types'

export function leapYear(year: number): boolean {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
}

export function getDaysOfEmployeeYear(date: Moment, user: User): number {
  const entryDate = user.custom_properties.Eintrittsdatum
    ? moment(user.custom_properties.Eintrittsdatum, 'DD.MM.YYYY').isSame(date, 'year')
      ? moment(user.custom_properties.Eintrittsdatum, 'DD.MM.YYYY')
      : undefined
    : undefined
  const exitDate = user.custom_properties.Austrittsdatum
    ? moment(user.custom_properties.Austrittsdatum, 'DD.MM.YYYY').isSame(date, 'year')
      ? moment(user.custom_properties.Austrittsdatum, 'DD.MM.YYYY')
      : undefined
    : undefined

  if (!entryDate && !exitDate) {
    return getDaysOfYear(date)
  }
  if (entryDate && exitDate) {
    return exitDate.diff(entryDate, 'days')
  }
  if (entryDate && !exitDate) {
    const test = moment([date.year(), 11, 31])
    return test.diff(entryDate, 'days')
  }
  if (!entryDate && exitDate) {
    return exitDate.diff(moment([date.year(), 0, 1]), 'days')
  }
  console.error('Could not find correct count of days in year. Calculate with 364')
  return 364
}

export function getDaysOfYear(date: Moment): number {
  if (leapYear(date.get('year'))) {
    return 365
  }
  return 364
}

export function getDaysUntilToday(): number {
  const now = new Date()

  const x = moment([now.getFullYear(), now.getMonth(), now.getDate()])
  const y = moment([now.getFullYear()]) // 1 of january

  return x.diff(y, 'days')
}

export function getCurrentYear(): Moment {
  const now = new Date()
  return moment([now.getFullYear()])
}

export function getFirstDateOfYear(date: Moment): Moment {
  return moment([date.get('year'), 0, 1])
}

export function getLastDateOfYear(date: Moment): Moment {
  return moment([date.get('year'), 11, 31])
}

// ISO 8601 string YYYY-MM-DD
export function convertDate(date: string): Moment {
  if (date.length !== 10) {
    throw new Error('wrong date format, expect ISO 8601')
  }
  return moment(date)
}

export function getYearOfDate(date: string | Moment): string {
  const convertedDate = moment(date)

  return convertedDate.year().toString()
}
