export enum TypeKeys {
  CLEAR_ALL = 'general/CLEAR_ALL',
  CLEAR_RESYNC = 'general/CLEAR_RESYNC',

  ADD_DATA = 'general/ADD_DATA',
  TOGGLE_SIDEBAR = 'general/TOGGLE_SIDEBAR',

  REQUEST_DATA = 'general/REQUEST',
  ANSWER_DATA = 'general/ANSWER',

  POST_PUT_DATA = 'general/POST_OR_PUT',
  NOTHING_TO_CHANGE_DATA = 'general/NOTHING_TO_CHANGE_DATA',

  UPDATE_FILTER = 'general/UPDATE_FILTER',

  SYNC_ERROR = 'general/SYNC_ERROR',

  PERSIST_STORAGE_DONE = 'general/PERSIST_STORAGE_DONE',
}
