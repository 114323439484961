import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
// import * as reducers from './modules/index';
import createSagaMiddleware from 'redux-saga'
import { throttle } from 'lodash'
import {
  activities,
  authentication,
  comatic,
  companies,
  contacts,
  expenses,
  general,
  invoices,
  offers,
  projects,
  recurringExpenses,
  schedules,
  users,
} from './modules'
import { ActivityState } from './modules/activities/types'
import { AuthenticationState } from './modules/authentication/types'
import { ComaticState } from './modules/comatic/types'
import { CompanyState } from './modules/companies/types'
import { ContactState } from './modules/contacts/types'
import { ExpenseState } from './modules/expenses/types'
import { GeneralState } from './modules/general/types'
import { InvoiceState } from './modules/invoices/types'
import { OfferState } from './modules/offers/types'
import { ProjectState } from './modules/projects/types'
import { RecurringExpenseState } from './modules/recurringExpenses/types'
import { ScheduleState } from './modules/schedules/types'
import { UserState } from './modules/users/types'
// import { ActivityAction } from "./modules/activities/actions";
import mySaga from './sagas/saga'
import { loadState, saveState } from './localstorage'
import { initFilter } from './modules/general/reducers'
import { getStatRelevantCompanies } from './modules/companies/selectors'
import { SyncContext } from './modules/general/types'
import generalActions from './modules/general/actions'

interface Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: <R>(a: R) => R
}

export type RootState = {
  activities: ActivityState
  authentication: AuthenticationState
  companies: CompanyState
  comatic: ComaticState
  contacts: ContactState
  expenses: ExpenseState
  general: GeneralState
  invoices: InvoiceState
  users: UserState
  offers: OfferState
  recurringExpenses: RecurringExpenseState
  schedules: ScheduleState
  projects: ProjectState
}

const sagaMiddleware = createSagaMiddleware()

// export type AllActions = ActivityAction;

// const composeEnhancers = ((window as Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) || compose;

const rootReducer = combineReducers<RootState>({
  activities,
  authentication,
  comatic,
  companies,
  contacts,
  expenses,
  general,
  invoices,
  users,
  offers,
  recurringExpenses,
  schedules,
  projects,
  // Add more Reducers from imports here!
})

const composeEnhancers =
  ((window as Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) || compose

const persistedState = loadState()

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

store.subscribe(
  throttle(() => {
    const general = store.getState().general;

    const { pendingRequests, persistStorage } = general
    if (pendingRequests === 0 && persistStorage) {
        const state = store.getState();

        const newState = {
          activities: state.activities,
          authentication: state.authentication,
          // comatic: undefined,
          // only save customers of companies
          companies: {
            detailsById: getStatRelevantCompanies(state),
          },
          // do not store contacts, only used for comatic sync
          // contacts: state.contacts,
          expenses: state.expenses,
          recurringExpenses: state.recurringExpenses,
          general: {
            ...state.general,
            persistStorage: false,
            pendingRequests: 0,
            statFilters: {
              aaList: {
                ...initFilter(),
                startDate: null,
              },
              customer: initFilter(),
              employee: initFilter(),
            },
            // only saved partial cause of limited space (without all companies and contacts)
            syncContext: SyncContext.partial,
          },
          invoices: state.invoices,
          offers: state.offers,
          projects: state.projects,
          users: state.users,
          schedules: state.schedules,
        }

        saveState(newState)
        // reset
        store.dispatch(generalActions.persistStorage())
    }
  }, 10000)
)

sagaMiddleware.run(mySaga)

export default store
