import { getAAInfo } from './aa'
import { getInvoicesTotal } from './../../invoices/selectors'
import moment from 'moment'
import { DataFilter } from './../statFilter'
import { ExtendedProject, getCorrection } from './../../projects/selectors'
import { getInvoiceMaxDate } from '../../invoices/selectors'
import { getBudgetIrrelevantExpensesTotal } from '../../expenses/selectors'

export type IsolatedBbe = {
  aaIsTotal: number
  aaBefore: number
  invoiceTotal: number
  bbe: number
}

export const getProjectBBE = (filter: DataFilter) => (project: ExtendedProject): IsolatedBbe => {
  const filterDate = filter.endDate ? filter.endDate : moment()

  // const latestActivity = getLatestActivity(project.activities)
  const invoiceDate = getInvoiceMaxDate(project.invoices)

  // aa from current year
  const invoiceTotal = getInvoicesTotal(project.invoices)
  const aaInfo = getAAInfo(project, false, filter.endDate)
  const aaTotal = aaInfo.subtotal
  const correction = getCorrection(filterDate.year().toString())(project)

  // Distinction to last year
  const lastYear = moment([
    moment(filterDate) // immutable
      .subtract(1, 'year')
      .year(),
    11,
    31,
  ])
  const correctionBefore = getCorrection(lastYear.year().toString())(project)
  const aaInfoBefore = getAAInfo(project, false, lastYear)
  const aaTotalBefore = aaInfoBefore.subtotal

  // Handle active projects
  if (project.active) {
    const aaIsTotal = aaTotal - correction
    const aaBefore = aaTotalBefore - correctionBefore

    return {
      invoiceTotal: 0,
      aaIsTotal,
      aaBefore,
      bbe: 0 + aaIsTotal - aaBefore,
    }
  } else if (!project.active) {
    // determine if project is finished after filterdate
    if (filterDate.isSameOrAfter(invoiceDate, 'day')) {
      const aaBefore = aaTotalBefore - correctionBefore

      if (invoiceDate.isSame(filterDate, 'year')) {
        const expensesTotal = getBudgetIrrelevantExpensesTotal(project.expenses)

        return {
          invoiceTotal: invoiceTotal - expensesTotal,
          aaIsTotal: 0,
          aaBefore,
          bbe: invoiceTotal - expensesTotal + 0 - aaBefore,
        }
      } else {
        // no relevance in year after invoice
        return {
          invoiceTotal: 0,
          aaIsTotal: 0,
          aaBefore: 0,
          bbe: 0,
        }
      }
    } else {
      // archived project, but before invoice date -> Handle like an active project
      const aaIsTotal = aaTotal - correction
      const aaBefore = aaTotalBefore - correctionBefore
      return {
        invoiceTotal: 0,
        aaIsTotal,
        aaBefore,
        bbe: 0 + aaIsTotal - aaBefore,
      }
    }
  }

  // project in different year, so return 0 for all
  return {
    aaIsTotal: 0,
    aaBefore: 0,
    invoiceTotal: 0,
    bbe: 0,
  }
}
