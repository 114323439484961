import * as React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import CustomReactTable from '../CustomTable'
import { getData, EmployeeOverview } from '../../state/modules/stats/employeeSelectors'
import { RootState } from '../../state/store'
import {
  ColumnConfiguration,
  ExcelButton,
  Format,
  getColumns,
  getExport,
  renameColumns,
} from '../../util/table'
import { User } from '../../state/modules/users/types'
import { Typography } from '@material-ui/core'
import AllFilter from '../filter/AllFilter'
import { StatisticNames } from '../../state/modules/general/types'

type EmployeeStatsPropsPublic = {
  user: User
}

interface EmployeeStatsProps {
  data: EmployeeOverview[]
  user: User
}

const EmployeeStats: React.SFC<EmployeeStatsProps> = ({ data, user }) => {
  const columnConfig: ColumnConfiguration[] = [
    { id: 'month', label: 'Monat', format: Format.name },
    {
      id: 'projectTotal',
      label: 'Total',
      format: Format.mixedNumber,
      maxWidth: 90,
      style: { fontWeight: 'bold' },
    },
    { id: 'projectNormal', label: 'Normal', format: Format.mixedNumber, maxWidth: 90 },
    { id: 'projectReduced', label: 'Reduziert', format: Format.mixedNumber, maxWidth: 90 },

    { id: 'invoiceTotalHour', label: 'Stunden', format: Format.mixedNumber, maxWidth: 90 },
    {
      id: 'invoiceTotalAmount',
      label: 'Betrag',
      format: Format.mixedNumber,
      maxWidth: 100,
      style: { fontWeight: 'bold' },
    },

    { id: 'billedNormalHour', label: 'Stunden', format: Format.mixedNumber, maxWidth: 90 },
    { id: 'billedNormalAmount', label: 'Betrag', format: Format.mixedNumber, maxWidth: 100 },
    { id: 'billedReducedHour', label: 'Stunden', format: Format.mixedNumber, maxWidth: 90 },
    { id: 'billedReducedAmount', label: 'Betrag', format: Format.mixedNumber, maxWidth: 100 },

    { id: 'newBusinessHour', label: 'New Bizz', format: Format.mixedNumber, maxWidth: 80 },
    { id: 'customerRelationHour', label: 'Kd-Pfl.', format: Format.mixedNumber, maxWidth: 80 },
    { id: 'marketingHour', label: 'Marketing', format: Format.mixedNumber, maxWidth: 80 },
    { id: 'internalHour', label: 'Intern', format: Format.mixedNumber, maxWidth: 80 },
    { id: 'absenceHour', label: 'Abwesenheit', format: Format.mixedNumber, maxWidth: 80 },

    { id: 'holidayHour', label: 'Ferien', format: Format.mixedNumber, maxWidth: 80 },
    { id: 'sickHour', label: 'Krankheit', format: Format.mixedNumber, maxWidth: 80 },
  ]

  const mapperForExport = {
    invoiceTotalHour: 'Fakturiert Total Stunden',
    invoiceTotalAmount: 'Fakturiert Total Betrag',
    billedNormalHour: 'Fakturiert Normal Stunden',
    billedNormalAmount: 'Fakturiert Normal Betrag',
    billedReducedHour: 'Fakturiert Reduziert Stunden',
    billedReducedAmount: 'Fakturiert Reduziert Betrag',
  }

  const exportColumnConfig = renameColumns(columnConfig, mapperForExport)

  const columns = getColumns(columnConfig)

  const projectColumns = [columns[1], columns[2], columns[3]]
  const billedColumns = [
    {
      Header: 'Fakturiert Total',
      columns: [columns[4], columns[5]],
    },
    {
      Header: 'Fakturiert Normal',
      columns: [columns[6], columns[7]],
    },
    {
      Header: 'Fakturiert Reduziert',
      columns: [columns[8], columns[9]],
    },
  ]
  const internalColums = [
    columns[10],
    columns[11],
    columns[12],
    columns[13],
    columns[14],
    columns[15],
    columns[16]
  ]

  const finalColumns = [
    {
      Header: '',
      columns: [columns[0]],
    },
    {
      Header: 'Projektstunden',
      columns: projectColumns,
    },
    ...billedColumns,
    {
      Header: '',
      columns: internalColums,
    },
  ]

  return (
    <React.Fragment>
      <Typography variant={'title'}>
        {user.firstname} {user.lastname}
      </Typography>
      <AllFilter statistic={StatisticNames.employee} />
      <ExcelButton
        onClick={getExport(
          `Mitarbeitende-${user.firstname}`.substr(0, 31),
          exportColumnConfig,
          data
        )}
      />
      <CustomReactTable
        data={data}
        defaultPageSize={25}
        sortable={false}
        columns={finalColumns}
        pivotBy={[]}
      />
    </React.Fragment>
  )
}

const mapStateToProps = (
  state: RootState,
  props: EmployeeStatsPropsPublic
): EmployeeStatsProps => ({
  data: getData(state, props.user.id),
  user: props.user,
})

export default compose<EmployeeStatsProps, EmployeeStatsPropsPublic>(
  // @ts-ignore
  connect<EmployeeStatsProps>(mapStateToProps)
)(EmployeeStats)
