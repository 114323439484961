import * as React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import CustomReactTable from '../CustomTable'
import { getData, Bbe } from '../../state/modules/stats/bbeSelector'
import { RootState } from '../../state/store'
import {
  ColumnConfiguration,
  ExcelButton,
  Format,
  getColumns,
  getExport,
  SumAggregator,
  Footer,
  totalFooter,
} from '../../util/table'
import AllFilter from '../filter/AllFilter'
import { StatisticNames } from '../../state/modules/general/types'

type BbeStatsPropsPublic = {}

interface BbeStatsProps {
  data: Bbe[]
}

const BbeStats: React.SFC<BbeStatsProps> = ({ data }) => {
  const columnConfig: ColumnConfiguration[] = [
    { id: 'customerName', label: 'Kunde', footer: <Footer>Total:</Footer>, format: Format.name },
    {
      id: 'billedAmount',
      label: 'Fakturiert',
      footer: totalFooter(data, 'billedAmount'),
      aggregate: SumAggregator,
      format: Format.currency,
    },
    {
      id: 'feeCurrent',
      label: 'Total AA aktuell',
      footer: totalFooter(data, 'feeCurrent'),
      aggregate: SumAggregator,
      format: Format.currency,
    },
    {
      id: 'feeDifferent',
      label: 'AA Vorjahr',
      footer: totalFooter(data, 'feeDifferent'),
      aggregate: SumAggregator,
      format: Format.currency,
    },
    {
      id: 'bbe',
      label: 'BBE aktuell',
      footer: totalFooter(data, 'bbe'),
      aggregate: SumAggregator,
      format: Format.currency,
    },
  ]

  const additionalExportHeaders: ColumnConfiguration[] = [
    {
      id: 'agreedAmount',
      label: 'Zugesagt',
      aggregate: SumAggregator,
      format: Format.currency,
    },
    {
      id: 'expectedAmount',
      label: 'Erwartet',
      aggregate: SumAggregator,
      format: Format.currency,
    },
    {
      id: 'additionalAmount',
      label: 'Zusätzlich',
      aggregate: SumAggregator,
      format: Format.currency,
    },
  ]

  const columns = getColumns(columnConfig)

  const exportConfig = [...columnConfig, ...additionalExportHeaders]

  const allColumns = [
    {
      Header: '',
      columns: [columns[0], columns[1]],
    },
    {
      Header: 'Bewertungskorrigiert',
      columns: [columns[2], columns[3]],
    },
    {
      Header: '',
      columns: [columns[4] /*columns[5], columns[6], columns[7] */],
    },
  ]

  return (
    <React.Fragment>
      <AllFilter statistic={StatisticNames.customer} disabledStart={true} />
      <ExcelButton onClick={getExport('BruttoBetriebsertrag', exportConfig, data)} />
      <CustomReactTable data={data} sortable={false} columns={allColumns} pivotBy={[]} />
    </React.Fragment>
  )
}

const mapStateToProps = (state: RootState, props: BbeStatsPropsPublic) => ({
  data: getData(state),
})

export default compose<BbeStatsProps, BbeStatsPropsPublic>(connect(mapStateToProps))(BbeStats)
