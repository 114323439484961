import * as React from 'react'
import { User } from '../../state/modules/users/types'
import {
  Avatar,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'

type EmployeeSelectionProps = {
  activeUserId: number
  hideInfo?: boolean
  users: User[]
}

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    link: {
      textDecoration: 'none',
    },
  })

type StyleProps = WithStyles<typeof styles>

const EmployeeSelection: React.StatelessComponent<EmployeeSelectionProps & StyleProps> = ({
  activeUserId,
  classes,
  hideInfo,
  users,
}) => {
  let info

  if (!hideInfo) {
    info = (
      <React.Fragment>
        <Typography variant={'headline'}>Aktuelle Auswertungen</Typography>
        <Link className={classes.link} to={'/employee/overview'}>
          <ListItem button key={'rekap'} disabled={activeUserId === 1}>
            <ListItemIcon>
              <Avatar className={classes.avatar}>R</Avatar>
            </ListItemIcon>
            <ListItemText>Rekap (Alle Mitarbeitende)</ListItemText>
          </ListItem>
        </Link>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <List component="nav">
        {info}

        {users.map(u => {
          return (
            <Link className={classes.link} key={u.id} to={`/employee/${u.id}`}>
              <ListItem button disabled={u.id === activeUserId}>
                <ListItemIcon>
                  <Avatar className={classes.avatar}>{`${u.firstname[0]}${u.lastname[0]}`}</Avatar>
                </ListItemIcon>
                <ListItemText inset primary={`${u.firstname} ${u.lastname}`} />
              </ListItem>
            </Link>
          )
        })}
      </List>
    </React.Fragment>
  )
}

export default compose<EmployeeSelectionProps & StyleProps, EmployeeSelectionProps>(
  withStyles(styles)
)(EmployeeSelection)
