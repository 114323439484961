import * as R from 'ramda'
import { RootState } from '../../store'
import { Contact } from '../contacts/types'
import { Company } from '../companies/types'
import { getCompanies, getCompanyOfAdvertizerId } from '../companies/selectors'
import { getContacts } from '../contacts/selectors'
import { AdditionalCompany, AdditionalState, AdditionalContact } from './types'

// We use the same store, just for a one time import
export const getAdvertizerCompanies = (state: RootState): Company[] => {
  return state.comatic.companies
}

export const getAdvertizerContacts = (state: RootState): Contact[] => {
  return state.comatic.contacts
}

export const compareCompany = (c1: Company, c2: Company): boolean => {
  return R.allPass([
    R.eqProps('name'),
    // R.eqProps('website'),
    R.eqProps('phone'),
    // R.eqProps('fax'),
    R.eqProps('labels'),
    R.eqBy(
      R.pipe(
        // @ts-ignore
        R.prop('address'),
        R.replace(/\r\n/g, '\n')
      )
    ),
    // R.eqProps('address'),
  ])(c1, c2)
}

export const compareAdvertizerId = <T>(c1: T, c2: T): boolean => {
  const getId = R.path(['custom_properties', 'AdvertizerId'])
  return R.equals(getId(c1), getId(c2))
}

export const getAdvertizerCompaniesAdditional = (state: RootState): AdditionalCompany[] => {
  const comaticCompanies = getAdvertizerCompanies(state)
  const companies = R.values(getCompanies(state))
  const additionalCompanies: AdditionalCompany[] = R.map((comaticCompany: Company) => {
    // Add state to store
    let syncState: AdditionalState = 'Neu'
    let id = 0
    // Update?
    const existing: Company[] = R.filter((company: Company) =>
      compareAdvertizerId(comaticCompany, company)
    )(companies)
    if (existing && existing.length > 0) {
      syncState = 'Update'

      const isIdentical = compareCompany(comaticCompany, existing[0])
      if (isIdentical) {
        syncState = 'Identisch'
      }
      // Set ID
      id = R.propOr(0, 'id')(existing[0])
    }

    return {
      ...comaticCompany,
      id,
      syncState,
    }
  })(comaticCompanies)
  return additionalCompanies
}

export const compareContact = (c1: Contact, c2: Contact): boolean => {
  return R.allPass([
    R.eqProps('firstname'),
    R.eqProps('lastname'),
    R.eqProps('gender'),
    R.eqProps('title'),
    R.eqProps('job_position'),
    R.eqProps('mobile_phone'),
    R.eqProps('birthday'),

    // R.eqProps('work_fax'),
    R.eqProps('work_phone'),
    R.eqProps('work_email'),
    // R.eqProps('organization_id'),

    // R.eqProps('tags'),

    // @ts-ignore
    R.eqBy(R.pipe(R.prop('work_address'), R.replace(/\r\n/g, '\n'))),
    /*
    R.eqBy(
      R.pipe(
        R.prop('home_address'),
        R.replace(/\r\n/g, '\n')
      )
    ),*/
  ])(c1, c2)
}

export const getAdvertizerContactsAdditional = (state: RootState): AdditionalContact[] => {
  const comaticContacts = getAdvertizerContacts(state)
  const contacts = R.values(getContacts(state))
  const additionalContacts: AdditionalContact[] = R.map((comaticContact: Contact) => {
    // Add state to store
    let syncState: AdditionalState = 'Neu'
    let id = 0
    let organizationId
    // Update?
    const existing: Contact[] = R.filter((company: Contact) =>
      compareAdvertizerId(comaticContact, company)
    )(contacts)
    if (existing && existing.length > 0) {
      syncState = 'Update'

      const isIdentical = compareContact(comaticContact, existing[0])
      if (isIdentical) {
        syncState = 'Identisch'
      }
      // Set ID
      id = R.propOr(0, 'id')(existing[0])
    }
    // Get Company from ComaticId
    if (comaticContact.custom_properties.AdvertizerParentId) {
      const company = getCompanyOfAdvertizerId(
        state,
        comaticContact.custom_properties.AdvertizerParentId
      )
      if (company) {
        organizationId = company.id ? company.id : -1
      }
    }

    return {
      ...comaticContact,
      id,
      customer_id: organizationId,
      syncState,
    }
  })(comaticContacts)

  return additionalContacts
}

export default {
  getComaticCompanies: getAdvertizerCompanies,
  getComaticContacts: getAdvertizerContacts,
  getComaticCompaniesAdditional: getAdvertizerCompaniesAdditional,
  getComaticContactAdditional: getAdvertizerContactsAdditional,
}
