import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Cell, Legend, Pie, PieChart } from 'recharts'
import { formatCurrency, formatHour, formatPercentage } from '../../util/format'
import { User } from '../../state/modules/users/types'
import { RootState } from '../../state/store'
import { getData, EmployeeTaskStat } from '../../state/modules/stats/employeeTaskSelectors'
import { TableCell, TableHead, TableRow, Table, Grid } from '@material-ui/core'

// color palette from https://www.color-hex.com/color-palette/67828 -> Neutral Palette Color Palette
const COLORS = ['#896c68', '#ec8e75', '#eac9aa', '#f6dda7', '#82a18f']

function getTaskColor(taskDescription: string, id: number): string {
  // Color palette from https://www.color-hex.com/color-palette/68157 -> cat cafe
  const taskColors = {
    'a_Projektmanagement;': '#d6ee92',
    'b_Strategie, Konzeption, Kreation;': '#f0cf91',
    'c_Redaktion/Text;': '#8d6e5d',
    'd_Design;': '#557d90',
    'e_Produktion;': '#b4d8ee',
  }
  // @ts-ignore
  if (taskColors[taskDescription]) {
    // @ts-ignore
    return taskColors[taskDescription]
  }
  return COLORS[id % COLORS.length]
}

function renderCustomizedLabel(props: any) {
  const {
    x,
    y,
    cx,
    cy,
    payload: { name, value },
  } = props
  return (
    <text
      x={x}
      y={y}
      fill={value <= 1 ? '#D3D3D3' : '#666'}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline={y > cy ? 'hanging' : 'baseline'}
    >
      {`${name} ${value.toFixed(2)}%`}
    </text>
  )
}

interface PublicEmployeeTasksProps {
  user: User
}

interface EmployeeTasksProps {
  user: User
  data: EmployeeTaskStat[]
}

const EmployeeTasks: React.SFC<EmployeeTasksProps> = ({ user, data }) => {
  const pieData = data.map(value => {
    return {
      name: value.description,
      value: value.percent,
    }
  })

  return (
    <React.Fragment>
      <h1>
        Geleistete Stunden in den Leistungsgruppen {user.firstname} {user.lastname}
      </h1>
      <Grid container>
        <Grid item xs={12} md={9} lg={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Leistungsgruppe</TableCell>
                <TableCell numeric>Stunden</TableCell>
                <TableCell numeric>Betrag</TableCell>
                <TableCell numeric>Stunden in Prozent</TableCell>
              </TableRow>
            </TableHead>
            {data.map((task, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{task.description}</TableCell>
                  <TableCell numeric>{formatHour(task.hours, true)}</TableCell>
                  <TableCell numeric>{formatCurrency(task.amount, true)}</TableCell>
                  <TableCell numeric>{formatPercentage(task.percent, true)}</TableCell>
                </TableRow>
              )
            })}
          </Table>
        </Grid>
      </Grid>

      <PieChart width={900} height={400}>
        <Pie
          data={pieData}
          dataKey={'value'}
          cx={450}
          cy={200}
          nameKey={'name'}
          label={renderCustomizedLabel}
          outerRadius={120}
          fill="#8884d8"
        >
          {data.map((entry, index) => (
            <Cell key={entry.description} fill={getTaskColor(entry.description, index)} />
          ))}
          <Legend verticalAlign="middle" height={50} />
        </Pie>
      </PieChart>
    </React.Fragment>
  )
}

const mapStateToProps = (state: RootState, props: PublicEmployeeTasksProps) => ({
  user: props.user,
  data: getData(state, props.user.id),
})

export default compose<EmployeeTasksProps, PublicEmployeeTasksProps>(
  connect(mapStateToProps, null)
)(EmployeeTasks)
