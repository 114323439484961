import * as H from 'history'
import InboxIcon from "@material-ui/icons/Inbox";
import ComputerIcon from "@material-ui/icons/Computer";
import PeopleIcon from '@material-ui/icons/Person';
import CommentIcon from '@material-ui/icons/Comment'
import { Sync } from '@material-ui/icons'
import { SvgIconProps } from "@material-ui/core/SvgIcon";

export type RoutePath = {
  path: H.Path
  redirect: boolean
  exact?: boolean
  icon: React.ComponentType<SvgIconProps>,
  sidebarName: string
  navbarName: string
}

export const routes: RoutePath[] = [{
  path: '/overview',
  exact: true,
  icon: InboxIcon,
  navbarName: '',
  sidebarName: 'Übersicht',
  redirect: false,
},
  {
    path: '/projects',
    exact: true,
    icon: ComputerIcon,
    navbarName: '',
    sidebarName: 'AA-Liste',
    redirect: false,
  }
  ,{
    path: '/employee',
    exact: true,
    icon: PeopleIcon,
    navbarName: '',
    sidebarName: 'Mitarbeitende',
    redirect: false,
  },
  {
    path: '/customer',
    exact: true,
    icon: CommentIcon,
    navbarName: '',
    sidebarName: 'Kunden',
    redirect: false,
  },
  {
    path: '/comaticSync',
    exact: true,
    icon: Sync,
    navbarName: '',
    sidebarName: 'Comatic Synchronisation',
    redirect: false,
  }/*,
  {
    path: '/advertizerSync',
    exact: true,
    icon: Sync,
    navbarName: 'Advertizer Synchronisation',
    sidebarName: 'Advertizer Synchronisation',
    redirect: false,
  }*/
];
