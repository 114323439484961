import { CellObject, read, Sheet2JSONOpts, utils, WorkBook, WorkSheet } from 'xlsx'
import {
  ComaticCompanyImport,
  ComaticContactImport,
  AdvertizerCompanyImport,
  AdvertizerContactImport,
} from '../state/modules/comatic/types'
import * as R from 'ramda'
import * as vorwahl from 'vorwahlen'
import { validate } from 'email-validator'
import { Contact } from '../state/modules/contacts/types'
import { Company } from '../state/modules/companies/types'

type CellInfo = { row?: number; col?: string }

/**
 * Returns the column and row of the cellName
 * @param cellName i. e. A12, B12, B1 (Column+Row)
 */
const getCellInfo = (cellName: string): CellInfo => {
  const regx = /([A-Z]+)([0-9]+)/
  const group = cellName.match(regx)
  if (group) {
    return {
      row: parseInt(group[2], 10),
      col: group[1],
    }
  }
  return {}
}

/**
 * Replace the umlauts in the string
 * @param value to get replacements for
 */
function replaceUmlauts(value: string) {
  value = value.replace(/ä/g, 'ae')
  value = value.replace(/ö/g, 'oe')
  value = value.replace(/ü/g, 'ue')
  return value
}

function isDataCell(arg: any): arg is CellObject {
  return arg.t !== undefined
}
function getHeaders(worksheet: WorkSheet): string[] {
  const headers = Object.keys(worksheet).reduce((accumulator: string[], cellKey) => {
    const cellObject = worksheet[cellKey]
    if (isDataCell(cellObject)) {
      const info = getCellInfo(cellKey)
      if (info.row === 1) {
        if (cellObject.v) {
          return (accumulator = [...accumulator, replaceUmlauts(cellObject.v.toString())])
        }
      }
    }
    return accumulator
  }, [])
  return headers
}

function pickMobilePhone(mobiles: string[]): string {
  // For sure +41 79, +41 78, +41 76
  for (const mobile of mobiles) {
    if (vorwahl(mobile).type === 'mobile') {
      return mobile
    }
  }
  return ''
}

function pickLandPhone(phones: string[]): string {
  // For sure +41 61, +41 62, +41 44
  for (const phone of phones.reverse()) {
    if (vorwahl(phone).type === 'fixed') {
      return phone
    }
  }
  return ''
}

function getData<T>(worksheet: WorkSheet, headers: string[]): T[] {
  // const emptyRow = Array(headers.length).fill('');
  const options: Sheet2JSONOpts = {
    header: headers,
    blankrows: false,
    raw: false,
    range: 'A1:CI5000',
  }
  const data = utils.sheet_to_json<T>(worksheet, options)
  return R.tail(data)
}

export function handleImportFile(
  files: FileList | null,
  entity: 'company' | 'contact',
  callback: (entity: Company[] | Contact[]) => void
): void {
  const rABS = false // true: readAsBinaryString ; false: readAsArrayBuffer

  if (files) {
    const f = files[0]
    const reader = new FileReader()
    reader.onload = (e: any) => {
      if (e && e.target) {
        let data = e.target.result
        if (!rABS) {
          data = new Uint8Array(data)
        }
        const workbook: WorkBook = read(data, { type: rABS ? 'binary' : 'array' })

        /* DO SOMETHING WITH workbook HERE */
        const worksheet: WorkSheet = workbook.Sheets[workbook.SheetNames[0]]

        const headers = getHeaders(worksheet)
        if (entity === 'company') {
          const companyImport = getData<ComaticCompanyImport>(worksheet, headers)
          // Filter active companies (remove inactive and blocked ones)
          const companyFiltered = companyImport.filter(
            company => company.Adress_Status === '0' || (company.Adress_Status as any) === 0
          )
          const companyResult = convertCompany(companyFiltered)
          callback(companyResult)
          // console.log(JSON.stringify(companyResult));
        } else if (entity === 'contact') {
          const contactImport = getData<ComaticContactImport>(worksheet, headers)
          // Filter active contacts (remove inactive and blocked ones)
          const contactFiltered = contactImport.filter(
            contact => contact.Adress_Status === '0' || (contact.Adress_Status as any) === 0
          )
          const contactResult = convertContact(contactFiltered)
          callback(contactResult)
          // console.log(JSON.stringify(contactImport));
        }
      }
    }
    if (rABS) {
      reader.readAsBinaryString(f)
    } else {
      reader.readAsArrayBuffer(f)
    }
  }
}

function convertPhone(phone: string): string {
  if (phone) {
    // international phone
    if (phone.indexOf('00') === 0) {
      return '+' + phone.substr(2)
    }
    // swiss phone
    if (phone.indexOf('0') === 0) {
      return '+41 ' + phone.substr(1)
    }
    // otherwise
    return phone
  }
  return ''
}

function convertUrl(url: string): string {
  if (url) {
    if (url.indexOf('https://') === 0) {
      return url
    }
    if (url.indexOf('http://') === 0) {
      return url
    }
    return 'http://'.concat(url)
  }
  return url
}

const getGender = (salutation: string): 'U' | 'F' | 'M' => {
  let gender: 'U' | 'M' | 'F' = 'U'
  if (salutation) {
    if (salutation.indexOf('Herr') > -1) {
      gender = 'M'
    }
    if (salutation.indexOf('Frau') > -1) {
      gender = 'F'
    }
  }

  return gender
}

const getTitle = (salutation: string): string => {
  let title = ''
  if (salutation) {
    if (salutation.indexOf('Herr') > -1) {
      title = salutation.substr(salutation.indexOf('Herr') + 5)
    }
    if (salutation.indexOf('Frau') > -1) {
      title = salutation.substr(salutation.indexOf('Frau') + 5)
    }
  }
  return title
}

export function convertContact(contacts: ComaticContactImport[]): Contact[] {
  const contactComatic: Contact[] = R.map((contact: ComaticContactImport) => {
    const phones = [
      convertPhone(contact['Telefon 1']),
      convertPhone(contact['Telefon 2']),
      convertPhone(contact['Telefon 3']),
    ]

    const name = contact.Vorname ? R.trim(contact.Vorname) + ' ' + contact.Name : contact.Name
    const firma =
      contact.Adresse1 && typeof contact.Adresse1 === 'string' && contact.Adresse1.length > 0
        ? R.trim(contact.Adresse1)
        : R.trim(contact.Firma)

    const addressParts: string[] = R.reject(R.isNil, [
      firma,
      name,
      contact.Adresse2,
      contact.Adresse3,
      contact.Plz + ' ' + contact.Ort,
    ])

    const result: Contact = {
      id: 0,
      firstname: contact.Vorname ? R.trim(contact.Vorname) : '',
      lastname: contact.Name ? R.trim(contact.Name) : '',
      gender: getGender(contact.Anrede),
      work_address: R.join('\n', addressParts),
      tags: ['Comatic'],
      title: getTitle(contact.Anrede) ? getTitle(contact.Anrede) : '',
      job_position: contact.Adress_Referenz ? contact.Adress_Referenz : '',
      mobile_phone: pickMobilePhone(phones),
      // work_fax: convertPhone(contact.Telefax),
      work_phone: pickLandPhone(phones),
      work_email: validate(contact['E-Mail']) ? contact['E-Mail'] : '',
      custom_properties: {
        ComaticId: contact.Adress_ID,
        ComaticParentId: contact.Parent_ID,
      },
    }
    return result
  })(contacts)

  return contactComatic
}

export function convertCompany(companiesImport: ComaticCompanyImport[]): Company[] {
  const companies = R.map((companyImport: ComaticCompanyImport) => {
    /*
    const name = companyImport.Vorname
      ? companyImport.Vorname + ' ' + companyImport.Name
      : companyImport.Name
    */
    const addressParts: string[] = R.reject(R.isNil, [
      companyImport.Vorname,
      companyImport.Name,
      // name,
      companyImport.Adresse1,
      companyImport.Adresse2,
      companyImport.Adresse3,
      companyImport.Plz + ' ' + companyImport.Ort,
    ])

    const address = R.join('\n', addressParts)

    const company: Company = {
      id: 0,
      type: 'organization',
      // shortName: contact.shortName,
      name: companyImport.Vorname ? R.trim(companyImport.Vorname) : R.trim(companyImport.Name),
      labels: ['Comatic'],
      tags: ['Comatic'],
      address,
      phone: convertPhone(companyImport['Telefon 1']),
      // fax: convertPhone(companyImport.Telefax),
      website: convertUrl(companyImport.InternetURL),
      custom_properties: {
        ComaticId: companyImport.Adress_ID,
      },
      email: null, // contact.email,
      currency: 'CHF',
    }

    return company
  })(companiesImport)

  return R.uniq(companies)
}

/**
 * Temporarily for Advertizer Company and Contact Import
 * // Todo: Remove after import
 */
export function handleAdvertizerImportFile(
  files: FileList | null,
  entity: 'company' | 'contact',
  callback: (entity: Company[] | Contact[]) => void
): void {
  const rABS = true // true: readAsBinaryString ; false: readAsArrayBuffer

  if (files) {
    const f = files[0]
    const reader = new FileReader()
    reader.onload = (e: any) => {
      if (e && e.target) {
        let data = e.target.result
        if (!rABS) {
          data = new Uint8Array(data)
        }
        const workbook: WorkBook = read(data, { type: rABS ? 'binary' : 'array' })

        /* DO SOMETHING WITH workbook HERE */
        const worksheet: WorkSheet = workbook.Sheets[workbook.SheetNames[0]]

        const headers = getHeaders(worksheet)
        if (entity === 'company') {
          const companyImport = getData<AdvertizerCompanyImport>(worksheet, headers)
          const companyResult = convertAdvertizerCompany(companyImport)
          callback(companyResult)
          // console.log(JSON.stringify(companyResult));
        } else if (entity === 'contact') {
          const contactImport = getData<AdvertizerContactImport>(worksheet, headers)
          const contactResult = convertAdvertizerContact(contactImport)
          callback(contactResult)
          // console.log(JSON.stringify(contactImport))
        }
      }
    }
    if (rABS) {
      reader.readAsBinaryString(f)
    } else {
      reader.readAsArrayBuffer(f)
    }
  }
}

export function convertAdvertizerContact(contacts: AdvertizerContactImport[]): Contact[] {
  const contactAdvertizer: Contact[] = R.map((contact: AdvertizerContactImport) => {
    const labels = JSON.parse(contact.labels)

    const result: Contact = {
      id: 0,
      firstname: contact.firstname ? contact.firstname : '',
      lastname: contact.lastname ? contact.lastname : '',
      gender: contact.gender as 'U' | 'M' | 'F',
      work_address: contact.work_address ? contact.work_address : '',
      tags: labels,
      title: contact.title ? contact.title : '',
      job_position: contact.job_position ? contact.job_position : '',
      mobile_phone: contact.mobile_phone ? contact.mobile_phone : '',
      // work_fax: contact.work_fax ? contact.work_fax : '',
      work_phone: contact.work_phone ? contact.work_phone : '',
      work_email: validate(contact.work_email) ? contact.work_email : '',
      birthday: contact.birthday ? contact.birthday : null,
      home_address: contact.home_address,
      custom_properties: {
        AdvertizerId: contact.id,
        AdvertizerParentId: contact.parentId,
        'Telefon (Privat)': contact.phonePrivate,
      },
    }
    return result
  })(contacts)

  return contactAdvertizer
}

export function convertAdvertizerCompany(companiesImport: AdvertizerCompanyImport[]): Company[] {
  const companies = R.map((companyImport: AdvertizerCompanyImport) => {
    const labels = JSON.parse(companyImport.labels)
    const address = R.trim(companyImport.address)

    const company: Company = {
      id: 0,
      type: companyImport.type as 'supplier' | 'customer' | 'organization',
      // shortName: contact.shortName,
      name: companyImport.name ? companyImport.name : '',
      labels: labels,
      tags: labels,
      address: address ? address : '',
      phone: convertPhone(companyImport.phone),
      // fax: convertPhone(companyImport.fax),
      website: convertUrl(companyImport.website),
      custom_properties: {
        AdvertizerId: companyImport.id,
      },
      email: companyImport.email,
      currency: 'CHF',
    }

    return company
  })(companiesImport)

  return companies
}
