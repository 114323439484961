// ##############################
// // // Header styles
// #############################
import { CSSProperties } from "react";
import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor
} from "../../theme/variables";
import { Theme } from '@material-ui/core';
import {StyleSheet} from "../../definitions/Styles";

export type HeaderClasses =
  | 'appBar'
  | 'container'
  | 'flex'
  | 'title'
  | 'appResponsive'

export type HeaderColor = 'primary'
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'


const headerStyle = (theme: Theme): StyleSheet<HeaderClasses & HeaderColor> => ({
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    padding: "5px 0",
    zIndex: 1029,
    color: "#555555",
    border: "0",
    borderRadius: "0px",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block"
  } as CSSProperties,
  container: {
    ...container,
    minHeight: "50px",
  } as CSSProperties,
  flex: {
    flex: 1
  } as CSSProperties,
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "0",
    textTransform: "none",
    color: "inherit",
    "&:hover,&:focus": {
      background: "transparent"
    }
  } as CSSProperties,
  appResponsive: {
    top: "0"
  } as CSSProperties,
  primary: {
    backgroundColor: primaryColor,
    color: "#FFFFFF",
  } as CSSProperties,
  info: {
    backgroundColor: infoColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  } as CSSProperties,
  success: {
    backgroundColor: successColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  } as CSSProperties,
  warning: {
    backgroundColor: warningColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  } as CSSProperties,
  danger: {
    backgroundColor: dangerColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  } as CSSProperties
});

export default headerStyle;
