import { RootState } from '../../store'

export function getToken(state: RootState): string {
  return state.authentication.token
}

export function isAuthenticated(state: RootState): boolean {
  return state.authentication.isAuthenticated
}

export function getAuthenticatedUser(state: RootState): number {
  return state.authentication.userId
}

export default {
  getToken,
  isAuthenticated,
}
