import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Layout from '../../components/Layout/layout'
import { routes } from '../routes'
import { RootState } from '../../state/store'
import { isAuthenticated } from '../../state/modules/authentication/selector'
import CompanySyncPage from '../../components/comatic/CompanySync'
import { getComaticStep } from '../../state/modules/comatic/selectors'
import { ComaticStep } from '../../state/modules/comatic/types'
import SyncButton from '../../components/SyncButton'
import ContactSyncPage from '../../components/comatic/ContactSync'
import SyncStep from '../../components/comatic/elements/SyncStep'
import { Grid, Typography } from '@material-ui/core'

type SyncPageProps = {
  loggedIn: boolean
  step: ComaticStep
}

type ComponentProps = SyncPageProps & RouteComponentProps<any>

const ComaticSyncPage: React.SFC<ComponentProps> = ({ location, match, loggedIn, step }) => {
  if (!loggedIn) {
    return <Redirect to="/" />
  }

  const outOfSyncButton = (
    <Grid alignItems={'center'} container>
      <Grid item xs={4}>
        <SyncButton />
      </Grid>
    </Grid>
  )

  return (
    <Layout routes={routes} location={location}>
      <Typography variant={'display1'}>Comatic Synchronisation</Typography>
      <SyncStep activeStep={step} />
      {step === ComaticStep.outOfSync ? outOfSyncButton : null}
      <CompanySyncPage />
      <ContactSyncPage />
    </Layout>
  )
}

const mapStateToProps = (state: RootState): Partial<SyncPageProps> => ({
  loggedIn: isAuthenticated(state),
  step: getComaticStep(state),
})

const mapDispatchToProps: Partial<SyncPageProps> = {}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
  // @ts-ignore
)(ComaticSyncPage)
