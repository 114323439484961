import * as React from 'react'
import * as R from 'ramda'
import cx from 'classnames'
// import { sum } from 'lodash'
import { AccessorFunction, Aggregator, Column } from 'react-table-6'
import { utils, writeFile } from 'xlsx'
import { default as Button, ButtonProps } from '@material-ui/core/Button'
import { GetApp } from '@material-ui/icons'
import { formatCurrency, formatHour, formatNumber } from './format'

export type FooterType = 'sum' | 'avg'

export enum Format {
  currency = 'currency',
  hours = 'hours',
  percentage = 'percentage',
  mixedNumber = 'mixedNumber',
  name = 'name',
  identifier = 'identifier',
  import = 'import',
}

export type ColumnConfiguration = {
  id: string
  label: string
  accessor?: AccessorFunction
  aggregate?: Aggregator
  aggregated?: (row: { value: any }) => any
  footer?: any
  footerType?: FooterType
  maxWidth?: number
  format: Format
  style?: React.CSSProperties
}

/* Quickfix */
export const BoldAggregated = (row: { value: number }) => {
  return (
    <span className={row.value >= 0 ? 'normal' : 'minus'}>
      <strong>{formatCurrency(row.value)}</strong>
    </span>
  )
}

export const SumAggregator = (values: number[]) => {
  return R.sum(values)
}

export const EmptyAggregator = (values: any[]) => {
  return ''
}

export function avgFooter<T>(data: T[], key: keyof T) {
  return <Footer>{avgOfRows(data, key)}</Footer>
}

export function totalFooter<T>(data: T[], key: keyof T) {
  return <Footer>{totalOfRows(data, key)}</Footer>
}

export const Footer: React.SFC = ({ children }) => {
  return (
    <span>
      <strong>{children}</strong>
    </span>
  )
}

export function totalOfRows<T>(rows: T[], key: keyof T): number {
  return rows.reduce((amount: number, row: T) => {
    const value = row[key]
    if (typeof value === 'number') {
      return amount + value
    }
    return amount
  }, 0)
}

export function avgOfRows<T>(rows: T[], key: keyof T) {
  const result = rows.reduce(
    (total: { amount: number; count: number }, row: T) => {
      const value = row[key]
      if (typeof value === 'number') {
        return {
          amount: total.amount + value,
          count: total.count + 1,
        }
      }
      return total
    },
    { amount: 0, count: 0 }
  )

  return result.amount / result.count
}

export const getAllDefaultExpanded = (max: number) => {
  const counter = Array.from(Array(max).keys())
  return R.reduce((accu: { [key: string]: boolean }, key: number) => {
    return {
      ...accu,
      [key]: true,
    }
  }, {})(counter)
}

export const getColumns = (columnConfigs: ColumnConfiguration[]): Column[] => {
  const getClassNames = (format: Format): string =>
    cx({
      // eslint-disable-next-line
      ['right']:
        format === Format.hours ||
        format === Format.currency ||
        format === Format.percentage ||
        format === Format.mixedNumber,
      // eslint-disable-next-line
      ['center']: format === Format.identifier,
      // eslint-disable-next-line
      ['left']: format === Format.name,
      // eslint-disable-next-line
      ['currency']: format === Format.currency,
      // eslint-disable-next-line
      ['hours']: format === Format.hours,
      // eslint-disable-next-line
      ['percentage']: format === Format.percentage,
      // eslint-disable-next-line
      ['identifier']: format === Format.identifier,
      // eslint-disable-next-line
      ['name']: format === Format.name,
    })

  const currencyCellRender = (row: { value: any }) =>
    isFinite(row.value) && !isNaN(row.value) ? (
      <span className={row.value >= 0 ? 'normal' : 'minus'}>{formatCurrency(row.value)}</span>
    ) : (
      <span />
    )

  const hourCellRenderer = (row: { value: any }) =>
    isFinite(row.value) && !isNaN(row.value) ? (
      <span className={row.value >= 0 ? 'normal' : 'minus'}>{formatHour(row.value)}</span>
    ) : (
      <span />
    )

  const percentageCellRenderer = (row: { value: any }) =>
    isFinite(row.value) && !isNaN(row.value) ? (
      <span className={row.value >= 0 ? 'normal' : 'minus'}>{formatHour(row.value)}</span>
    ) : (
      <span />
    )

  const numberCellRenderer = (row: { value: any }) =>
    isFinite(row.value) && !isNaN(row.value) ? (
      <span className={row.value >= 0 ? 'normal' : 'minus'}>{formatNumber(row.value)}</span>
    ) : (
      <span />
    )

  const standardRenderer = (row: { value: any }) => <span>{row.value}</span>

  const getRenderer = (format: Format) => {
    switch (format) {
      case Format.currency:
        return currencyCellRender
      case Format.hours:
        return hourCellRenderer
      case Format.percentage:
        return percentageCellRenderer
      case Format.mixedNumber:
        return numberCellRenderer
      default:
        return standardRenderer
    }
  }

  const getFootRenderer = (format: Format) => {
    return (total: any) => {
      const value = total ? (total.props ? total.props.children : '') : ''
      return getRenderer(format)({ value })
    }
  }

  const convert = (columnConfig: ColumnConfiguration): Column => {
    const additional = columnConfig.footer
      ? { Footer: getFootRenderer(columnConfig.format)(columnConfig.footer) }
      : {}

    return {
      id: columnConfig.id,
      Header: columnConfig.label,
      accessor: columnConfig.accessor ? columnConfig.accessor : props => props[columnConfig.id],
      aggregate: columnConfig.aggregate,
      Aggregated: columnConfig.aggregated,
      maxWidth: columnConfig.maxWidth,
      className: getClassNames(columnConfig.format),
      style: columnConfig.style,
      getHeaderProps: () => ({
        className: getClassNames(columnConfig.format),
      }),
      Cell: getRenderer(columnConfig.format),
      ...additional,
    }
  }

  return R.map(convert)(columnConfigs)
}

type ExcelDataSet = {
  columns: string[]
  data: any
}

export function getExcelColumns(conf: ColumnConfiguration[], data: any): ExcelDataSet[] {
  // @ts-ignore
  const columns: string[] = R.map(R.prop('label'))(conf)

  return [
    {
      columns,
      data,
    },
  ]
}

const mapConfig = (conf: ColumnConfiguration[]) => (data: any) => {
  /*const labels: string[] = R.map(
    R.prop('label')
    )(conf);

  return R.zipObj(
    labels,
    R.values(data)
    )
  */
  const result = {}

  R.map((config: ColumnConfiguration) => {
    // @ts-ignore
    return (result[config.label] = data[config.id])
  })(conf)
  return result
}

export const renameColumns = (columns: ColumnConfiguration[], mapper: object) => {
  return columns.map(column => {
    // @ts-ignore
    if (mapper[column.id]) {
      return {
        ...column,
        // @ts-ignore
        label: mapper[column.id],
      }
    }
    return column
  })
}

export const getExport = (name: string, conf: ColumnConfiguration[], data: any[]) => () => {
  const validData = R.map((row: object) => {
    return R.map((value: any) => {
      if (typeof value === 'number') {
        if (isNaN(value) || !isFinite(value)) {
          return ''
        }
        return Math.round(value * 100) / 100
      }
      return value
    })(row)
  })(data)

  const result = R.map(mapConfig(conf))(validData)

  /* make the worksheet */
  const ws = utils.json_to_sheet(result)

  /* add to workbook */
  const wb = utils.book_new()
  utils.book_append_sheet(wb, ws, name)

  /* generate an XLSX file */
  writeFile(wb, `${name}.xlsx`)
}

export const ExcelButton: React.SFC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <Button {...rest}>
      <GetApp />
      {children}
    </Button>
  )
}
