// import * as _ from 'lodash';
import { RootState } from '../../store'
// import { Schedule } from './index'
import { Schedule, ScheduleList, ScheduleType } from './types'
import { Moment } from 'moment'
import moment from 'moment'
import * as R from 'ramda'

export const getSchedules = (state: RootState): ScheduleList => {
  return state.schedules.detailsById
}

export const getSchedulesOfUser = (schedules: Schedule[], userId: number): Schedule[] => {
  return schedules.filter((schedule: Schedule) => schedule.user.id === userId)
}

export const getSchedulesTotalHour = (schedules: Schedule[], type: ScheduleType): number => {
  return schedules.reduce((hours: number, schedule: Schedule) => {
    let hour = 0
    if (type === schedule.assignment.name) {
      if (schedule.am) {
        hour = hour + 4
      }
      if (schedule.pm) {
        hour = hour + 4
      }
    }
    return hours + hour
  }, 0)
}

export const getSchedulesBetweenDates = (
  startDate: Moment,
  endDate: Moment,
  schedules: Schedule[]
): Schedule[] => {
  return R.filter((schedule: Schedule) => {
    const scheduleDate = moment(schedule.date)
    return scheduleDate.isBetween(startDate, endDate, 'day', '[]')
  })(schedules)
}

export default {
  getSchedules,
}
