import moment from 'moment'
import { all, put } from 'redux-saga/effects'
import { getConfig } from './api/config'
import { TypeKeys as generalTypes } from '../modules/general/constants'
import { SyncAction } from './saga'

function formatDate(date: Date): string {
  return moment(date).format('YYYY-MM-DD')
}

function getDates(startDate: Date, endDate: Date, days: number) {
  const dates: Array<{ from: string; to: string }> = []
  let currentDate = startDate
  while (currentDate <= endDate) {
    const futureDate = new Date(currentDate)
    futureDate.setDate(futureDate.getDate() + 30)
    dates.push({ from: formatDate(currentDate), to: formatDate(futureDate) })
    currentDate = new Date(futureDate)
  }
  return dates
}

export function* fetchActivities(action: SyncAction) {
  const { payload } = action

  const config = getConfig()
  const fromDate = payload.from ? new Date(payload.from) : new Date(config.fromDate)
  const toDate = new Date()
  const ranges = getDates(fromDate, toDate, 30)

  // Add to request queue
  yield all(
    ranges.map(range =>
      put({
        type: generalTypes.REQUEST_DATA,
        payload: {
          resource: 'activities',
          params: range,
        },
      })
    )
  )
}
