import * as R from 'ramda'
import moment from 'moment'
import { Moment } from 'moment'
import { RootState } from '../../store'
import { ProjectList, Project } from './types'
import { Invoice } from '../invoices/types'
import { Activity } from '../activities/types'
import { Company } from '../companies/types'
import { Expense } from '../expenses/types'
import { CompanyList } from '../companies/types'
import { getInvoicesOfProject } from '../invoices/selectors'
import { getExpensesOfProject } from '../expenses/selectors'
import { getActivitiesOfProject } from '../activities/selectors'
import { ascendComparatorString } from '../../../util/ramdaUtil'
import { Offer } from '../offers/types'
import { getOfferByIdentifier } from '../offers/selectors'

export type ExtendedProject = Project & {
  company: Company
  expenses: Expense[]
  invoices: Invoice[]
  activities: Activity[]
  offers: Offer[]
}

export const getProjects = (state: RootState): ProjectList => {
  return state.projects.detailsById
}

/* Returns the correction of the year */
export const getCorrection =
  (year: string) =>
  (project: Project): number => {
    if (project.custom_properties.Bewertungskorrektur) {
      const correction = project.custom_properties.Bewertungskorrektur[year]

      return correction ? correction : 0
    }
    return 0
  }

export const isReducedProject = (project: Project) => {
  return project.labels.indexOf('Reduziert') > -1 || project.labels.indexOf('reduziert') > -1
}

export const isActive = (project: Project | ExtendedProject): boolean => {
  return project.active
}

export const isArchived = (project: Project | ExtendedProject): boolean => {
  return R.complement(isActive)(project)
}

const isAcquisition = (project: Project | ExtendedProject): boolean => {
  return !project.billable
}

const isNotAcquisition = (project: Project | ExtendedProject): boolean => {
  return R.complement(isAcquisition)(project)
}

export const getActiveProjects = (state: RootState): Project[] => {
  const projects = getProjects(state)
  return R.filter(R.allPass([isActive, isNotAcquisition]))(R.values(projects))
}

export const getActiveProjectsAcquisition = (state: RootState): Project[] => {
  const projects = getProjects(state)
  return R.filter(R.allPass([isAcquisition, isActive]))(R.values(projects))
}

export const getArchivedProjects = (state: RootState): Project[] => {
  const projects = getProjects(state)
  return R.filter(R.allPass([isArchived, isNotAcquisition]))(R.values(projects))
}

export const getArchivedAcquisitionProjects = (state: RootState): Project[] => {
  const projects = getProjects(state)
  return R.filter(R.allPass([isArchived, isAcquisition]))(R.values(projects))
}

export const mapToExtendedProjects = (
  projects: Project[],
  companies: CompanyList,
  activities: Activity[],
  expenses: Expense[],
  invoices: Invoice[],
  offers: Offer[]
): ExtendedProject[] => {
  const groupByProjectId = R.groupBy(R.prop('project_id'))

  const expenseByProjectId = groupByProjectId(expenses)
  const invoiceByProjectId = groupByProjectId(invoices)
  const activitiesByProjectId = R.groupBy<Activity>((activity) => activity.project.id)(activities)

  const extendedProjects: ExtendedProject[] = projects.map((project: Project) => {
    const pExpenses = expenseByProjectId[project.id] ? expenseByProjectId[project.id] : []
    const pActivities = activitiesByProjectId[project.id] ? activitiesByProjectId[project.id] : []
    const pInvoice = invoiceByProjectId[project.id] ? invoiceByProjectId[project.id] : []

    const pOffer = project.custom_properties.AngebotsReferenz
      ? getOfferByIdentifier(offers, project.custom_properties.AngebotsReferenz)
      : []
    const company = companies[project.customer.id]

    return {
      ...project,
      company,
      expenses: pExpenses,
      invoices: pInvoice,
      activities: pActivities,
      offers: pOffer,
    }
  })

  return extendedProjects
}

export const mapToExtendedProjectsOld = (
  projects: Project[],
  companies: CompanyList,
  activities: Activity[],
  expenses: Expense[],
  invoices: Invoice[],
  offers: Offer[]
): ExtendedProject[] => {
  const extendedProjects: ExtendedProject[] = projects.map((project: Project) => {
    const pExpenses = getExpensesOfProject(expenses, project.id)
    const pActivities = getActivitiesOfProject(activities, project.id)
    const pInvoice = getInvoicesOfProject(invoices, project.id)
    const pOffer = project.custom_properties.AngebotsReferenz
      ? getOfferByIdentifier(offers, project.custom_properties.AngebotsReferenz)
      : []
    const company = companies[project.customer.id]

    return {
      ...project,
      company,
      expenses: pExpenses,
      invoices: pInvoice,
      activities: pActivities,
      offers: pOffer,
    }
  })

  return extendedProjects
}

export const getProjectLabels = (state: RootState): string[] => {
  const projects = R.values(getProjects(state))

  const labels = R.reduce((labelResult: string[], project: Project) => {
    return R.concat(labelResult, project.labels)
  }, [])(projects)
  return R.sort(ascendComparatorString)(R.uniq(labels))
}

export const hasLabels =
  (labels: string[]) =>
  (project: Project): boolean => {
    const hasLabel = (label: string): boolean => label === '' || project.labels.indexOf(label) > -1

    return R.all(hasLabel)(labels)
  }

export const isInvoiceBetweenDates =
  (startDate: Moment, endDate: Moment) => (project: ExtendedProject) => {
    const latestInvoiceDate = R.reduce((date: Moment, invoice: Invoice) => {
      const invoiceDate = moment(invoice.date)
      if (invoiceDate.isAfter(date)) {
        return invoiceDate
      }
      return date
    }, moment('2012-02-02'))(project.invoices)

    return latestInvoiceDate.isBetween(startDate, endDate, undefined, '[]')
  }

/**
 * @deprectated not used anymore, because // Neu: Spesen in Zusatzleistungen
 * Returns the fixed percentage amount of fee(Honorar)
 * 5% < 20000, 4% 20k - 100k, 3% >= 100k
 * @param amount
 */
export const getSpesen = (amount: number): number => {
  let percentage = 5
  if (amount >= 20000) {
    percentage = 4
  }
  if (amount >= 100000) {
    percentage = 3
  }

  return (amount / 100) * percentage
}

export default {
  getProjects,
  getActiveProjects,
}
