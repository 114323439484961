import * as React from 'react'
import {routes} from "./routes";
import Layout from "../components/Layout/layout";
import {RouteComponentProps} from "react-router";
import {Typography} from "@material-ui/core";


type FourOFourPageProps = {

} & RouteComponentProps<any>;


const FourOFourPage: React.StatelessComponent<FourOFourPageProps> = ({ location }) => {
  return (
    <Layout routes={routes} location={location}>
      <Typography variant={"display1"}>Diese Seite existiert nicht</Typography>
      <p>
        Bitte überprüft die eingegebene URL.
        Bitte fehlerhaften Link melden, falls diese Seite durch Aufruf eines Links/Button aufgetaucht ist.
      </p>
    </Layout>
  );
}

export default FourOFourPage;
