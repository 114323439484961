// import { TypeKeys as types } from './constants';
import * as _ from 'lodash'
import * as R from 'ramda'
import { TypeKeys as generalTypes } from '../general/constants'
import { UserAction } from './actions'
import {
  CustomerUserPropsResult,
  CustomUserProps,
  User,
  UserList,
  UserState,
  YearNumber,
} from './types'

const initState = {
  detailsById: {},
}

export function mapToYearNumber(value: string | null | undefined): YearNumber {
  if (value) {
    const years = R.split('\n')(value)
    if (years.length > 0) {
      const init: YearNumber = {}
      return R.reduce((result: YearNumber, yearEntry: string) => {
        const yearValue = R.split('=')(yearEntry)
        if (yearValue[0] && yearValue[1]) {
          return {
            ...result,
            [yearValue[0]]: parseFloat(yearValue[1]),
          }
        }
        return result
      }, init)(years)
    }
  }
  return {}
}

const parseCustomProperties = (customProps: CustomerUserPropsResult): CustomUserProps => {
  const { ZugangStatistikTool, ...statProps } = customProps

  // Handle authorization "0"=No, "1"=Yes
  let isAllowed = false
  if (ZugangStatistikTool) {
    if (ZugangStatistikTool === '1') {
      isAllowed = true
    }
  }

  const props = _.mapValues(statProps, mapToYearNumber)

  return {
    AbsenzenSoll: props.AbsenzenSoll,
    Abschlag: props.Abschlag,
    FerienSoll: props.FerienSoll,
    Beschaeftigungsgrad: props.Beschaeftigungsgrad,
    InternesSoll: props.InternesSoll,
    JahresSollStd40: props.JahresSollStd40,
    KundenpflegeSoll: props.KundenpflegeSoll,
    KundenSoll: props.KundenSoll,
    MarketingSoll: props.MarketingSoll,
    NewBizzSoll: props.NewBizzSoll,
    StundenansatzNormal: props.StundenansatzNormal,
    StundenansatzReduziert: props.StundenansatzReduziert,
    StundenanzahlNormal: props.StundenanzahlNormal,
    StundenanzahlReduziert: props.StundenanzahlReduziert,

    Austrittsdatum: customProps.Austrittsdatum ? customProps.Austrittsdatum : undefined,
    Eintrittsdatum: customProps.Eintrittsdatum ? customProps.Eintrittsdatum : undefined,

    // user rights/authentication
    isAllowed,
  }
}

const users = (
  state: UserState = {
    detailsById: {},
    ...initState,
  },
  action: UserAction
): UserState => {
  switch (action.type) {
    case generalTypes.ADD_DATA: {
      if (action.payload.resource === 'users') {
        // Map customProps to numbers
        const mapInternal: UserList = _.mapValues(action.payload.data, (value, key) => {
          const customProps = parseCustomProperties(
            value.custom_properties as CustomerUserPropsResult
          )
          const mappedUser: User = {
            ...value,
            custom_properties: {
              ...customProps,
            },
          }
          return mappedUser
        })
        return {
          ...state,
          detailsById: {
            ...state.detailsById,
            ...mapInternal,
          },
        }
      } else {
        return state
      }
    }

    case generalTypes.CLEAR_ALL: {
      return {
        ...state,
        detailsById: {},
      }
    }
    /* todo: implement again
    case types.REPLACE_ALL: {
      return { ...state, detailsById: action.payload, currentEvent: Object.keys(action.payload)[0] };
    }*/

    default:
      return state
  }
}

export default users
