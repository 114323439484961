import * as React from 'react'
import * as R from 'ramda'
import CustomReactTable from './CustomTable'
import { connect } from 'react-redux'
import { getData, ProjectOverview } from '../state/modules/stats/aaListSelector'
import { RootState } from '../state/store'
import {
  BoldAggregated,
  ColumnConfiguration,
  EmptyAggregator,
  ExcelButton,
  Footer,
  Format,
  getAllDefaultExpanded,
  getColumns,
  getExport,
  SumAggregator,
  totalFooter,
} from '../util/table'
import { Typography } from '@material-ui/core'
import AllFilter from './filter/AllFilter'
import { StatisticNames } from '../state/modules/general/types'

interface AAProps {
  data: ProjectOverview[]
  dataAcquisition: ProjectOverview[]
}

const AAList: React.SFC<AAProps> = ({ data, dataAcquisition }) => {
  const getConfig = (myData: ProjectOverview[]): ColumnConfiguration[] => {
    return [
      {
        id: 'customer',
        label: 'Kunde',
        aggregate: EmptyAggregator,
        format: Format.name,
        maxWidth: 200,
      },
      {
        id: 'identifier',
        label: 'Projekt-Nr.',
        aggregate: EmptyAggregator,
        format: Format.identifier,
        maxWidth: 90,
      },
      {
        id: 'name',
        label: 'Projekt',
        aggregate: EmptyAggregator,
        footer: <Footer>Total:</Footer>,
        format: Format.name,
      },
      {
        id: 'budget',
        label: 'Budget',
        aggregate: SumAggregator,
        aggregated: BoldAggregated,
        footer: totalFooter(myData, 'budget'),
        format: Format.currency,
        maxWidth: 100,
      },
      {
        id: 'amountCHF',
        label: 'Honorar',
        aggregate: SumAggregator,
        aggregated: BoldAggregated,
        footer: totalFooter(myData, 'amountCHF'),
        format: Format.currency,
        maxWidth: 100,
      },
      /* {
        id: 'spesen',
        label: 'Spesen',
        aggregate: SumAggregator,
        aggregated: BoldAggregated,
        footer: totalFooter(myData, 'spesen'),
        format: Format.currency,
        maxWidth: 90,
      },*/
      {
        id: 'expenses',
        label: 'Zusatzleistungen',
        aggregate: SumAggregator,
        aggregated: BoldAggregated,
        footer: totalFooter(myData, 'expenses'),
        format: Format.currency,
        maxWidth: 90,
      },
      {
        id: 'subtotal',
        label: 'Total Agentur',
        aggregate: SumAggregator,
        aggregated: BoldAggregated,
        footer: totalFooter(myData, 'subtotal'),
        format: Format.currency,
        maxWidth: 100,
      },
      {
        id: 'correction',
        label: 'Korrektur',
        aggregate: SumAggregator,
        aggregated: BoldAggregated,
        footer: totalFooter(myData, 'correction'),
        format: Format.currency,
        maxWidth: 100,
      },
      {
        id: 'total',
        label: 'Total',
        aggregate: SumAggregator,
        aggregated: BoldAggregated,
        footer: totalFooter(myData, 'total'),
        format: Format.currency,
        maxWidth: 100,
      },
      {
        id: 'invoice',
        label: 'Akonto',
        aggregate: SumAggregator,
        aggregated: BoldAggregated,
        footer: totalFooter(myData, 'invoice'),
        format: Format.currency,
        maxWidth: 100,
      },
      {
        id: 'remainBudget',
        label: 'Restbudget',
        aggregate: SumAggregator,
        aggregated: BoldAggregated,
        footer: totalFooter(myData, 'remainBudget'),
        format: Format.currency,
        maxWidth: 100,
      },
    ]
  }

  const columns = getColumns(getConfig(data))

  const columnsWithoutCustomer = getColumns(R.tail(getConfig(dataAcquisition)))

  return (
    <React.Fragment>
      <AllFilter statistic={StatisticNames.aaList} />

      <ExcelButton onClick={getExport('AA-Liste', getConfig(data), data)} />
      <CustomReactTable
        data={data}
        columns={columns}
        defaultExpanded={getAllDefaultExpanded(20)}
        pivotBy={['customer']}
      />

      <Typography variant={'display1'}>Akquisition / Interne Projekte</Typography>
      <ExcelButton
        onClick={getExport(
          'Akquisitions-Liste',
          R.tail(getConfig(dataAcquisition)),
          dataAcquisition
        )}
      />
      <CustomReactTable
        data={dataAcquisition}
        columns={columnsWithoutCustomer}
        defaultExpanded={getAllDefaultExpanded(20)}
        pivotBy={[]}
      />
    </React.Fragment>
  )
}

const mapStateToProps = (state: RootState): AAProps => ({
  data: getData(state),
  dataAcquisition: getData(state, true),
})

export default connect(mapStateToProps)(AAList)
