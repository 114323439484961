import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Subtract } from 'utility-types'
import { withStyles, WithStyles } from '@material-ui/core'
import { withRouter, RouteProps } from 'react-router'
import { ComponentProps, Sidebar } from './sidebar'
import { Color, SidebarClasses, sidebarStyle } from './sidebarStyle'
import { withToggle, WithToggleProps } from './withToggle'
import { logout } from '../../state/modules/authentication/actions'
import { RootState } from '../../state/store'
import { isAuthenticated } from '../../state/modules/authentication/selector'

type StateProps = {
  isAuthenticated: boolean
}

type DispatchProps = {
  logout: () => void
}

type InjectedProps = WithStyles<SidebarClasses | Color> &
  WithToggleProps &
  RouteProps &
  StateProps &
  DispatchProps

const mapStateToProps = (state: RootState): StateProps => ({
  isAuthenticated: isAuthenticated(state),
})

const mapDispatchToProps: DispatchProps = {
  logout,
}

type PublicProps = Subtract<ComponentProps, InjectedProps>

export default compose<ComponentProps, PublicProps>(
  withRouter,
  withToggle,
  connect<StateProps, DispatchProps>(
    // @ts-ignore
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(sidebarStyle)
)(Sidebar)
