import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Button } from '@material-ui/core'
import moment from 'moment'
import { RootState } from '../../state/store'
import { syncAction, reSyncAction } from '../../state/sagas/saga'
import { getConfig } from '../../state/sagas/api/config'

export type SyncButtonProps = {}

type InjectedProps = {
  lastSyncData: string
  lastSyncTime: string
  lastReSyncData: string
  lastReSyncTime: string
  enabledReSync: boolean
  syncData: (fromDate: string) => void
  reSyncData: (fromDate: string) => void
}

type ComponentProps = SyncButtonProps & InjectedProps

const SyncButton: React.SFC<ComponentProps> = ({
  lastSyncData,
  lastSyncTime,
  lastReSyncData,
  lastReSyncTime,
  syncData,
  reSyncData,
  enabledReSync,
}) => {
  const config = getConfig()
  const date = moment().subtract(config.resyncDays, 'days')

  return (
    <React.Fragment>
      <p>
        Letzte Synchronisierung: {lastSyncData} {lastSyncTime} <br />
        <Button variant={'contained'} color={'primary'} onClick={() => syncData(config.fromDate)}>
          Jetzt synchronisieren
        </Button>
        <br />
        <br />
        <br />
        Letzte Nachsynchronisierung: {lastReSyncData} {lastReSyncTime} <br />
        <Button
          disabled={!enabledReSync}
          variant={'contained'}
          color={'primary'}
          onClick={() => reSyncData(date.format('YYYY-MM-DD'))}
        >
          Jetzt nachsynchronisieren
        </Button>
      </p>
    </React.Fragment>
  )
}

const mapStateToProps = (state: RootState) => ({
  lastSyncData: state.general.lastSyncData,
  lastSyncTime: state.general.lastSyncTime,
  lastReSyncData: state.general.lastReSyncData,
  lastReSyncTime: state.general.lastReSyncTime,
  // initialized with "Noch nicht synchronisiert", date is 01.01.2018
  enabledReSync:
    state.general.lastSyncData && state.general.lastSyncData.length <= 10 ? true : false,
})

const mapDispatchToProps = {
  // Todo: Check params
  syncData: (fromDate: string) => syncAction({ from: fromDate }),
  reSyncData: (fromDate: string) => reSyncAction({ from: fromDate }),
}

export default compose<ComponentProps, SyncButtonProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SyncButton)
