import * as React from 'react'
import {Step, StepLabel, Stepper, Theme, withStyles, WithStyles} from "@material-ui/core";
import {compose} from "recompose";
import {ComaticStep} from "../../../state/modules/comatic/types";

type PublicProps = {
  activeStep: ComaticStep
}

const styles = (theme: Theme) => ({

})

type StyleProps = WithStyles<''>;

type ComponentProps = StyleProps & PublicProps;

const getSteps = (): string[] => {
  return [
    'Abgleich bestehende Daten',
    'Firmen synchronisieren',
    'Kontakte synchronisieren',
    'Abgeschlossen'
  ];
}

const getActiveStepIdx = (active: ComaticStep): number => {
  switch(active) {
    case ComaticStep.outOfSync:
      return 0;
    case ComaticStep.companyFileSelection:
    case ComaticStep.companyOverview:
    case ComaticStep.syncCompanies:
      return 1;
    case ComaticStep.contactFileSelection:
    case ComaticStep.contactOverview:
    case ComaticStep.syncContacts:
      return 2;
    case ComaticStep.finished:
      return 3;
    default:
      return 0;
  }
}


const SyncStep: React.StatelessComponent<ComponentProps> = ({classes, activeStep}) => {
  const steps = getSteps();
  const stepIdx = getActiveStepIdx(activeStep);

  return (
    <React.Fragment>
      <Stepper activeStep={stepIdx} alternativeLabel>
        {steps.map(label => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </React.Fragment>
  );
}

export default compose<ComponentProps, PublicProps>(
  withStyles(styles)
)(SyncStep);
