import { put } from 'redux-saga/effects'
import { TypeKeys as generalTypes } from '../modules/general/constants'

export function* fetchOffers() {
  // Add to request queue
  yield put({
    type: generalTypes.REQUEST_DATA,
    payload: {
      resource: 'offers',
      params: {},
    },
  })
}
