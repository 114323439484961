// import { TypeKeys as types } from './constants';
import * as R from "ramda";
import { TypeKeys as generalTypes } from '../general/constants'
import { CompanyAction } from './actions'
import {Company, CompanyList, CompanyState, CustomerCompanyProps, CustomerCompanyPropsResult, Prediction} from './types'
import * as _ from "lodash";

const initState = {
  detailsById: {},
}

const parseCustomProperties = (customProps: CustomerCompanyPropsResult): CustomerCompanyProps => {
  const mapToPrediction = (value: string | null | undefined): Prediction[] => {
    if (value) {
      const entries = R.split('\n')(value);
      if(entries.length>0) {
        const init: Prediction[] = [];
        return R.reduce(
          (result, entry: string) => {
            const predictionValue = R.split('=')(entry);
            if(predictionValue[0] && predictionValue[1]) {
              const num = parseInt(predictionValue[1], 10);
              if (value && !isNaN(num)) {
                return [
                  ...result,
                  {
                    description: predictionValue[0],
                    value: num,
                  }
                ]
              }
            }
            return result;
          }, init
        )(entries);
      }
    }
    return [];
  }

  const erwartet = mapToPrediction(customProps.Erwartet);
  const zusatz = mapToPrediction(customProps.Zusaetzlich);

  return {
    ...customProps,
    Erwartet: erwartet,
    Zusaetzlich: zusatz
  };
}

const companies = (
  state: CompanyState = {
    detailsById: {},
    ...initState,
  },
  action: CompanyAction
): CompanyState => {
  switch (action.type) {
    case generalTypes.ADD_DATA: {
      if (action.payload.resource.indexOf('companies') >= 0) {
        // Map customProps to numbers
        const mapInternal: CompanyList = _.mapValues(action.payload.data, (value, key) => {
          const customProps = parseCustomProperties(value.custom_properties as CustomerCompanyPropsResult);
          const mappedCompany: Company = {
            ...value,
            custom_properties: {
              ...customProps
            }
          }
          return mappedCompany;
        })

        return {
          ...state,
          detailsById: {
            ...state.detailsById,
            ...mapInternal,
          },
        }
      } else {
        return state
      }
    }

    case generalTypes.CLEAR_ALL: {
      return {
        ...state,
        detailsById: {},
      }
    }
    /* todo: implement again
    case types.REPLACE_ALL: {
      return { ...state, detailsById: action.payload, currentEvent: Object.keys(action.payload)[0] };
    }*/

    default:
      return state
  }
}

export default companies
