import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Layout from '../../components/Layout/layout'
import { routes } from '../routes'
import { RootState } from '../../state/store'
import { Company } from '../../state/modules/companies/types'
import { companySelector } from '../../state/modules/companies/index'
import { authenticationSelector } from '../../state/modules/authentication'
import CustomerSelection, { CustomerStats } from './CustomerSelection'
import { Typography } from '@material-ui/core'

type OverviewCustomerProps = {
  customers: Company[]
  loggedIn: boolean
}

type ComponentProps = OverviewCustomerProps & RouteComponentProps<any>

const CustomerPage: React.SFC<ComponentProps> = ({ location, match, customers, loggedIn }) => {
  if (!loggedIn) {
    return <Redirect to="/" />
  }

  return (
    <Layout routes={routes} location={location}>
      <Typography variant={'display1'}>Kunden</Typography>
      <CustomerSelection activeCustomerStat={CustomerStats.nothingSelected} customers={customers} />
    </Layout>
  )
}

const mapStateToProps = (state: RootState): OverviewCustomerProps => ({
  loggedIn: authenticationSelector.isAuthenticated(state),
  customers: companySelector.getCustomers(state, true),
})
// @ts-ignore
export default compose(connect(mapStateToProps))(CustomerPage)
