import { getInvoices } from './../invoices/selectors'
import * as R from 'ramda'
import moment from 'moment'
import { RootState } from '../../store'
import { getActivities } from '../activities/selectors'
import { ExtendedProject, getProjects, mapToExtendedProjects } from '../projects/selectors'
import { getCustomers } from '../companies/selectors'
import { Company } from '../companies/types'
import { getExpenses } from '../expenses/selectors'
import { getFilter } from '../general/selectors'
import { StatisticNames } from '../general/types'
import { DataFilter, getFilteredState, ProjectTypeFilter } from './statFilter'
import { getOffers } from '../offers/selectors'
import { getProjectBBE, IsolatedBbe } from './helper/bbe'

export type Bbe = {
  customerName: string
  billedAmount: number
  bbe: number
  feeCurrent: number
  feeDifferent: number

  agreedAmount: number
  expectedAmount: number
  additionalAmount: number
}

const getBbe = (projects: ExtendedProject[], filter: DataFilter) => (customer: Company): Bbe => {
  const sameCustomer: (project: ExtendedProject) => boolean = R.pathEq(
    ['customer', 'id'],
    customer.id
  )

  const relProjects: ExtendedProject[] = R.filter(sameCustomer)(projects)

  // Handle active and archived projects differently
  const bbeInfo = R.map(R.curry(getProjectBBE)(filter))(relProjects)

  const sumBBE: IsolatedBbe = R.reduce(
    (result: IsolatedBbe, info: IsolatedBbe) => {
      return {
        aaIsTotal: result.aaIsTotal + info.aaIsTotal,
        aaBefore: result.aaBefore + info.aaBefore,
        invoiceTotal: result.invoiceTotal + info.invoiceTotal,
        bbe: result.bbe + info.bbe,
      }
    },
    {
      aaIsTotal: 0,
      aaBefore: 0,
      invoiceTotal: 0,
      bbe: 0,
    }
  )(bbeInfo)
  /*
  // Billed amount
  const allInvoices = getInvoicesOfExtendedProjectList(archivedRelProjects);
  const billedAmount = getInvoicesTotal(allInvoices);

  // All fee activities of projects
  const allRelActivities = getActivitiesOfExtendedProjectList(activeRelProjects);

  // Fees from selected period
  const startDate = filter.startDate ? filter.startDate : moment('2000-01-01');
  const endDate = filter.endDate ? filter.endDate : moment('2099-01-01');

  const getFeeCurrentActivities = getFeeCurrent(startDate, endDate);
  const feeCurrent = getActivitiesTotal(getFeeCurrentActivities(allRelActivities));

  // Fees from other period
  const getFeeDifferentActivities = getFeeDifferentPeriod(startDate, endDate);
  const feeDifferent = getActivitiesTotal(getFeeDifferentActivities(allRelActivities));

  // Bewertungskorrektur
  type ProjectCorrection = {
    correctionCurrent: number,
    correctionDifferent: number
  }
  const allCorrections = R.reduce(
    (result: ProjectCorrection, project: ExtendedProject) => {
      const correction = project.custom_properties.Bewertungskorrektur ? project.custom_properties.Bewertungskorrektur : 0;
      const feeC = getActivitiesTotal(getFeeCurrentActivities(project.activities));
      const feeD = getActivitiesTotal(getFeeDifferentActivities(project.activities));
      const total = feeC + feeD;
      if (isNaN(total) || total === 0) {
        return result;
      }
      return {
        correctionCurrent: result.correctionCurrent + (feeC/total) * correction,
        correctionDifferent: result.correctionDifferent + (feeD/total) * correction,
      }
    }, {correctionCurrent: 0, correctionDifferent: 0}
  )(activeRelProjects)

  // BBE
  const bbe = billedAmount + feeCurrent - allCorrections.correctionCurrent;

  // Agreed
  const relOffers = R.reduce(
    (offers: Offer[], project: ExtendedProject) => {
      const projectOffers = project.offers;
      if (projectOffers) {
        return [...offers, ...projectOffers];
      }
      return offers;
    },[]
  )(relProjects)

  const agreedAmount = getAmountOf(relOffers, [isOfferSent, isOfferCreated]);

  const expectedAmount = getPredictionTotal(customer.custom_properties.Erwartet)  // getAmountOf(relOffers, [isOfferSent]);
  const additionalAmount = getPredictionTotal(customer.custom_properties.Zusaetzlich);
  */

  return {
    customerName: customer.name,
    billedAmount: sumBBE.invoiceTotal,
    feeCurrent: sumBBE.aaIsTotal,
    feeDifferent: sumBBE.aaBefore,
    bbe: sumBBE.bbe,
    agreedAmount: 0,
    expectedAmount: 0,
    additionalAmount: 0,
  }
}

export const getData = (rootState: RootState): Bbe[] => {
  const filter = getFilter(rootState, StatisticNames.customer)

  // Take whole period of until selected date
  const allStates = getFilteredState(rootState, {
    ...filter,
    startDate: moment([2010, 0, 1]), // take the whole time
    projectType: ProjectTypeFilter.all,
  })
  const state = allStates.filteredByLabelState

  const projects = getProjects(state)
  const customers = getCustomers(state, true)

  // We need all activities to distinguish between activities in period and outside
  const activities = getActivities(state)
  const expenses = getExpenses(state)
  const invoices = getInvoices(state)
  const offers = getOffers(state)

  const extendedProjects = mapToExtendedProjects(
    R.values(projects),
    customers,
    R.values(activities),
    R.values(expenses),
    R.values(invoices),
    R.values(offers)
  )

  // Do not prefilter, use filter in function to split relevant and irrelevant years
  const getBbeOfCustomer = getBbe(extendedProjects, filter)

  return R.map(getBbeOfCustomer)(customers)
}
