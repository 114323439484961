import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Party from '@material-ui/icons/Mood'
import { RootState } from '../../state/store'
import { Contact } from '../../state/modules/contacts/types'
import {
  getComaticContactsAdditional,
  getComaticContactsDeleted,
  getComaticStep,
  getImportInfoContacts,
  getRequestCount,
  ImportInfo,
} from '../../state/modules/comatic/selectors'
import { isAuthenticated } from '../../state/modules/authentication/selector'
import { handleImportFile } from '../../util/import'
import {
  addContacts as addContactsToStore,
  removeContacts as removeContactsFromStore,
  reset as resetComaticImport,
  skipComaticContacts,
  syncComaticContacts,
} from '../../state/modules/comatic/actions'
import CustomReactTable from '../CustomTable'
import { ColumnConfiguration, Format, getColumns } from '../../util/table'
import {
  Badge,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import UploadButton from './elements/UploadButton'
import SyncPreview from './elements/SyncPreview'
import { ComaticStep } from '../../state/modules/comatic/types'
import SyncState from './elements/SyncState'
// import FileUpload from "@material-ui/icons/FileUpload";

type PublicProps = {}

type InjectedProps = {
  contacts: Contact[]
  nonExistantContacts: Contact[]
  info: ImportInfo
  loggedIn: boolean
  requests: number
  step: ComaticStep
  reset: () => void
  addContacts: (contacts: Contact[]) => void
  removeContacts: () => void
  syncContacts: (info: ImportInfo) => void
  skipContactImport: () => void
}

type ComponentProps = InjectedProps & WithStyles<'upload' | 'button' | 'rightIcon'>

const getHandleFunction = (
  entity: 'company' | 'contact',
  callback: (companyOrContact: any) => void
) => (e: React.ChangeEvent<HTMLInputElement>) => {
  const files = e.target.files

  handleImportFile(files, entity, callback)
}

const styles = (theme: Theme) => ({
  hide: {
    display: 'none',
  },
  button: {
    margin: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
})

const CompanySyncPage: React.SFC<ComponentProps> = ({
  classes,
  reset,
  step,
  requests,
  info,
  contacts,
  nonExistantContacts,
  addContacts,
  skipContactImport,
  syncContacts,
  removeContacts,
}) => {
  const columnConfigContact: ColumnConfiguration[] = [
    { id: 'syncState', label: 'Sync', format: Format.import },
    {
      id: 'custom_properties',
      label: 'ComaticId',
      accessor: (data: Contact) => data.custom_properties.ComaticId,
      format: Format.import,
    },
    { id: 'firstname', label: 'Vorname', format: Format.import },
    { id: 'lastname', label: 'Nachname', format: Format.import },
    { id: 'gender', label: 'Geschlecht', format: Format.import },
    {
      id: 'customer_id',
      label: 'FirmenId',
      accessor: (data: Contact) => (data.company ? data.company.id : ''),
      format: Format.import,
    },
    // { id: "organization_id",  label: "FirmenNummer", format: Format.import },// oder organization_id – 123 (ID eines Kunden/einer Firma)
    { id: 'title', label: 'Titel', format: Format.import },
    { id: 'job_position', label: 'Job Position', format: Format.import },
    { id: 'mobile_phone', label: 'Mobile', format: Format.import },
    // { id: "work_fax", label: "Fax", format: Format.import },
    { id: 'work_phone', label: 'Telefon', format: Format.import },
    { id: 'work_email', label: 'E-Mail', format: Format.import },
    { id: 'work_address', label: 'Adresse', format: Format.import },
    { id: 'home_email', label: 'E-Mail Home', format: Format.import },
    { id: 'home_address', label: 'Adresse Home', format: Format.import },
    { id: 'birthday', label: 'Geburtstag', format: Format.import },
    { id: 'info', label: 'Info', format: Format.import },
    { id: 'tags', label: 'Labels', format: Format.import },
  ]
  const columnsContact = getColumns(columnConfigContact)

  const fileSelection = (
    <React.Fragment>
      <Typography variant={'headline'}>Auswahl Comatic Kontakte</Typography>
      <Grid container>
        <Grid item xs={12}>
          <UploadButton onChange={getHandleFunction('contact', addContacts)}>Kontakte</UploadButton>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 200 }}>
          <Button variant={'contained'} color={'default'} onClick={skipContactImport}>
            Zurück zum Firmen-Import
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )

  const sync = () => syncContacts(info)

  const dataOverview = (
    <React.Fragment>
      <Typography variant={'headline'}>Import-Vorschau Kontakte</Typography>
      <CustomReactTable data={contacts} columns={columnsContact} />
      <Grid container>
        <Grid item xs={12}>
          <SyncPreview info={info} />
        </Grid>
        <Grid item xs={1}>
          <Button variant={'contained'} color={'default'} onClick={removeContacts}>
            Ablehnen
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button variant={'contained'} color={'primary'} onClick={sync}>
            Annehmen
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        style={{ display: nonExistantContacts.length > 0 ? 'block' : 'none', paddingTop: '100px' }}
      >
        <Grid item xs={6}>
          <Badge color="error" max={9999} badgeContent={nonExistantContacts.length}>
            <Typography variant={'headline'}>
              Nicht in Import vorhanden - Manuell bearbeiten
            </Typography>
          </Badge>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name des Kontakt</TableCell>
                <TableCell numeric>ComaticId</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nonExistantContacts.map((contact, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      {contact.firstname} {contact.lastname}
                    </TableCell>
                    <TableCell numeric>{contact.custom_properties.ComaticId}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </React.Fragment>
  )

  const progress = (
    <React.Fragment>
      <Typography variant={'headline'}>Änderungen werden durchgeführt</Typography>
      <SyncPreview info={info} />
      <SyncState info={info} requests={requests} />
    </React.Fragment>
  )

  const finished = (
    <React.Fragment>
      <Typography variant={'headline'}>
        Synchronisation abgeschlossen <Party />
      </Typography>
      <Button variant={'contained'} color={'primary'} onClick={reset}>
        Abschliessen
      </Button>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      {step === ComaticStep.contactFileSelection
        ? fileSelection
        : step === ComaticStep.contactOverview
        ? dataOverview
        : step === ComaticStep.syncContacts
        ? progress
        : step === ComaticStep.finished
        ? finished
        : null}
    </React.Fragment>
  )
}

const mapStateToProps = (state: RootState) => ({
  contacts: getComaticContactsAdditional(state),
  info: getImportInfoContacts(state),
  requests: getRequestCount(state),
  step: getComaticStep(state),
  loggedIn: isAuthenticated(state),

  nonExistantContacts: getComaticContactsDeleted(state),
})

const mapDispatchToProps = {
  addContacts: addContactsToStore,
  removeContacts: removeContactsFromStore,
  reset: resetComaticImport,
  syncContacts: syncComaticContacts,
  skipContactImport: skipComaticContacts,
}

export default compose<ComponentProps, PublicProps>(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CompanySyncPage)
