import { Action } from 'redux'
import { Buffer, buffers } from 'redux-saga'
import { actionChannel, call, put, select, take, delay } from 'redux-saga/effects'
// import { SagaActionConstants } from '../constants';
import { getConfig } from './config'
import { TypeKeys as generalTypes } from '../../modules/general/constants'
// import { TypeKeys as authTypes } from '../../modules/authentication/constants'

import { getData } from './api'
import { getToken } from '../../modules/authentication/selector'

// export const delay = (ms: number) => new Promise(res => setTimeout(res, ms))

// Request Queued with a delay of 1s (see config)
export function* watchRequests() {
  const config = getConfig()
  // 1- Create a channel for request actions
  const buffer: Buffer<Action<{}>> = buffers.expanding(1500)
  const requestChan = yield actionChannel(generalTypes.REQUEST_DATA, buffer)
  while (true) {
    // 2- take from the channel
    const { payload } = yield take(requestChan)

    // measure time
    const startTime = new Date().getTime()

    // We're using a blocking call to call the request
    const token = yield select(getToken)
    try {
      const response = yield call(getData, payload, token)
      const { data, headers } = response

      // Request eventually more data
      const xTotal = Number.parseInt(headers['x-total'], 10)
      const xPage = Number.parseInt(headers['x-page'], 10)
      const xPerPage = Number.parseInt(headers['x-per-page'], 10)
      if (xTotal > 100 && xPage === 1) {
        const pageCount = Math.ceil(xTotal / xPerPage)

        for (let page = 2; page <= pageCount; page++) {
          yield put({
            type: generalTypes.REQUEST_DATA,
            payload: {
              requestType: 'paramsOnly',
              resource: payload.resource,
              params: {
                ...payload.params,
                page,
              },
            },
          })
        }
      }

      yield put({
        type: generalTypes.ANSWER_DATA,
        payload: {
          resource: payload.resource,
          data,
        },
      })
    } catch (error) {
      yield put({
        type: generalTypes.ANSWER_DATA,
        payload: {
          resource: 'error',
          data: {},
        },
      })

      yield put({
        type: generalTypes.SYNC_ERROR,
        payload: {
          error: error.response,
        },
      })
    }

    // Wait for rest time
    const endTime = new Date().getTime()
    const diffTime = Math.ceil((endTime - startTime) / 2)

    yield delay(config.throttle - diffTime)
  }
}
