import * as _ from 'lodash'
import { actionChannel, put, take } from 'redux-saga/effects'
// import { SagaActionConstants } from '../constants';
import { TypeKeys as generalTypes } from '../../modules/general/constants'

// Request Queued
export function* watchAnswers() {
  // 1- Create a channel for request actions
  const requestChan = yield actionChannel(generalTypes.ANSWER_DATA)
  while (true) {
    // 2- take from the channel
    const { payload } = yield take(requestChan)

    const { data, resource } = payload

    // Wait until all Data is available (without any error)
    // yield take(SagaActionConstants.SYNC_FINISHED);

    // convert data to list format
    const mappedData = _.reduce(
      data,
      (map, entity) => {
        return {
          ...map,
          [entity.id]: entity,
        }
      },
      {}
    )

    yield put({
      type: generalTypes.ADD_DATA,
      payload: {
        data: mappedData,
        resource,
      },
    })

    /*
    put({
      type: SagaActionConstants.REPLACE,
      payload: {
        resource,
        data
      }
    })*/
  }
}
