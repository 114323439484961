import moment from 'moment'
import { RootState } from '../../store'
import { DataFilter } from '../stats/statFilter'
import { StatisticNames } from './types'

export const stubSelector = (state: RootState) => {
  return 'stubSelector'
}

export const getGeneral = (state: RootState) => {
  return state.general
}

export const isLoading = (state: RootState): boolean => {
  return getGeneral(state).pendingRequests > 0
}

export const getSyncError = (state: RootState): string => {
  return getGeneral(state).error
}

export const getCounter = (state: RootState): number => {
  return getGeneral(state).pendingRequests
}

export const getMaxCounter = (state: RootState): number => {
  return getGeneral(state).maxRequests
}

export const getFilter = (state: RootState, statistic: StatisticNames): DataFilter => {
  const general = getGeneral(state)
  const filter = general.statFilters[statistic]
  return {
    ...filter,
    startDate: filter.startDate ? moment(filter.startDate, 'YYYY-MM-DD') : null,
    endDate: filter.endDate ? moment(filter.endDate, 'YYYY-MM-DD') : null,
  }
}

export default {
  isLoading,
  getCounter,
  getMaxCounter,
}
