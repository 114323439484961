import * as R from 'ramda'
import { RootState } from '../../store'
import { Offer } from './types'

export const getOffers = (state: RootState): { [key: number]: Offer } => {
  return state.offers.detailsById
}

export const getOfferByIdentifier = (offers: Offer[], identifier: string): Offer[] => {
  return R.filter((offer: Offer) => offer.identifier === identifier, offers)
}

export const isOfferAccepted = (offer: Offer) => R.propEq('status', 'accepted')(offer)
export const isOfferSent = (offer: Offer) => R.propEq('status', 'sent')(offer)
export const isOfferCreated = (offer: Offer) => R.propEq('status', 'created')(offer)
export const isOfferBilled = (offer: Offer) => R.propEq('status', 'billed')(offer)
export const isOfferArchived = (offer: Offer) => R.propEq('status', 'archived')(offer)

type checkOffer = (offer: Offer) => boolean

export const getAmountOf = (offers: Offer[], checkFunctions: checkOffer[]) => {
  return R.reduce((amount: number, offer: Offer) => {
    if (R.anyPass(checkFunctions)(offer)) {
      return amount + offer.net_total
    }
    return amount
  }, 0)(offers)
}

export default {
  getOffers,
}
