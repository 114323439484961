import * as React from 'react'
import { Badge, Typography, withStyles, WithStyles, Theme } from '@material-ui/core'
import { compose } from 'recompose'

import { ImportInfo } from '../../../state/modules/comatic/selectors'

type PublicProps = {
  info: ImportInfo
}

const styles = (theme: Theme) => ({
  typo: {
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
  listElement: {
    padding: `${theme.spacing.unit}px 0`,
  },
})

type StyleProps = WithStyles<'typo' | 'listElement'>

type ComponentProps = StyleProps & PublicProps

const SyncPreview: React.StatelessComponent<ComponentProps> = ({ classes, info }) => {
  return (
    <React.Fragment>
      <ul style={{ listStyleType: 'none' }}>
        <li className={classes.listElement}>
          <Badge max={9999} color="primary" badgeContent={info.add}>
            <Typography className={classes.typo}>Hinzufügen</Typography>
          </Badge>
        </li>
        <li className={classes.listElement}>
          <Badge max={9999} color="primary" badgeContent={info.update}>
            <Typography className={classes.typo}>Update</Typography>
          </Badge>
        </li>
        <li className={classes.listElement}>
          <Badge max={9999} color="primary" badgeContent={info.same}>
            <Typography className={classes.typo}>Identisch</Typography>
          </Badge>
        </li>
      </ul>
    </React.Fragment>
  )
}

export default compose<ComponentProps, PublicProps>(withStyles(styles))(SyncPreview)
