// ##############################
// // // App styles
// #############################
import { CSSProperties } from 'react';
import { drawerWidth, transition } from '../../theme/variables';
import { Theme } from '@material-ui/core';
import { StyleSheet } from '../../definitions/Styles';

export type AppClasses = 'wrapper' | 'mainPanel' | 'content';

export const appStyle = (theme: Theme): StyleSheet<AppClasses & {}> => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh'
  } as CSSProperties,
  mainPanel: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch'
  } as CSSProperties,
  content: {
    marginTop: '70px',
    padding: '5px 15px',
    minHeight: 'calc(100% - 123px)'
  } as CSSProperties
});
