// import { TypeKeys as types } from './constants';
import * as _ from 'lodash'
import { TypeKeys as generalTypes } from '../general/constants'
import { ExpenseAction } from './actions'
import { ExpenseState } from './types'

const initState = {
  detailsById: {},
}

const expenses = (
  state: ExpenseState = {
    detailsById: {},
    ...initState,
  },
  action: ExpenseAction
): ExpenseState => {
  switch (action.type) {
    case generalTypes.ADD_DATA: {
      const resource = action.payload.resource
      if (resource.indexOf('/expenses') >= 0) {
        const idStart = resource.indexOf('/') + 1
        const idEnd = resource.indexOf('/', idStart)
        const projectId = parseInt(resource.slice(idStart, idEnd), 10)
        const expenseData: any = _.reduce(
          action.payload.data,
          (result, expense) => {
            return {
              ...result,
              [expense.id]: {
                ...expense,
                project_id: projectId,
              },
            }
          },
          {}
        )
        return {
          ...state,
          detailsById: {
            ...state.detailsById,
            ...expenseData,
          },
        }
      } else {
        return state
      }
    }

    case generalTypes.CLEAR_ALL: {
      return {
        ...state,
        detailsById: {},
      }
    }
    /* todo: implement again
    case types.REPLACE_ALL: {
      return { ...state, detailsById: action.payload, currentEvent: Object.keys(action.payload)[0] };
    }*/

    default:
      return state
  }
}

export default expenses
