import axios from 'axios'
import qs from'qs';
import { getConfig } from './config'

type RequestParams = {
  from: string
  to: string
  include_archived: boolean // For projects
  page: number
}

type UrlRequest = {
  requestType: 'urlOnly'
  url: string
}

type ParamsRequest = {
  requestType: 'paramsOnly'
  resource: string
  params: Partial<RequestParams>
}

type PostPutRequest = {
  requestType: 'POST' | 'PUT',
  resource: string
  data: object
}

type OwnRequest = UrlRequest | ParamsRequest

export function getData(requestData: OwnRequest, token: string) {
  const config = getConfig();
  const headers = {
    Authorization: 'Token token=' + token,
  }

  if (requestData.requestType === 'urlOnly') {
    return axios.get(requestData.url, {
      headers,
    })
  } else {
    const { resource, params } = requestData
    const url = config.url + resource
    return axios.get(url, {
      headers,
      params,
    })
  }
}

export function postPutData(requestData: PostPutRequest, token: string) {
  const config = getConfig();
  const headers = {
    'Authorization': 'Token token=' + token,
    'Content-Type': 'application/x-www-form-urlencoded'
  }
  const url = config.url + requestData.resource;
  const { data } = requestData;
  const stringifyOptions = { arrayFormat: 'brackets' };

  if (requestData.requestType === 'POST') {
    return axios.post(
      url,
      qs.stringify(data, stringifyOptions),
      {
        headers
      }
    )
  } else if (requestData.requestType === 'PUT') {
    return axios.put(
      url,
      qs.stringify(data, stringifyOptions),
      {
        headers
      }
    )
  }
  return null;
}
