import { TypeKeys as types } from './constants'
import { TypeKeys as generalTypes } from '../general/constants'
import { ComaticAction } from './actions'
import { ComaticState, ComaticStep } from './types'
import { sharedConfig } from '../../sagas/api/config'

const initState = {
  contacts: [],
  companies: [],
  comaticStep: 'companyFileSelection' as ComaticStep,
  comaticRequests: 0,
}

const comatic = (
  state: ComaticState = {
    ...initState,
  },
  action: ComaticAction
): ComaticState => {
  switch (action.type) {
    case types.ADD_CONTACTS: {
      return {
        ...state,
        ...action.payload,
        comaticStep: ComaticStep.contactOverview,
        comaticRequests: 0,
      }
    }

    case types.ADD_COMPANIES: {
      return {
        ...state,
        ...action.payload,
        comaticStep: ComaticStep.companyOverview,
        comaticRequests: 0,
      }
    }

    case generalTypes.POST_PUT_DATA: {
      return {
        ...state,
        comaticRequests: state.comaticRequests + 1,
      }
    }

    case generalTypes.ANSWER_DATA: {
      if (state.comaticRequests > sharedConfig.comaticSyncSteps!) {
        return {
          ...state,
          comaticRequests: state.comaticRequests - sharedConfig.comaticSyncSteps!,
        }
      }
      if (
        state.comaticRequests <= sharedConfig.comaticSyncSteps! &&
        state.comaticStep === ComaticStep.syncCompanies
      ) {
        return {
          ...state,
          comaticRequests: 0,
          comaticStep: ComaticStep.contactFileSelection,
        }
      }
      if (
        state.comaticRequests <= sharedConfig.comaticSyncSteps! &&
        state.comaticStep === ComaticStep.syncContacts
      ) {
        return {
          ...state,
          comaticRequests: 0,
          comaticStep: ComaticStep.finished,
        }
      }
      return state
    }

    case types.RESET: {
      return {
        companies: [],
        contacts: [],
        comaticRequests: 0,
        comaticStep: ComaticStep.companyFileSelection,
      }
    }

    case types.SYNC_COMPANIES: {
      const info = action.payload.info
      const isSync: boolean = info.add + info.update > 0
      if (isSync) {
        return {
          ...state,
          comaticStep: ComaticStep.syncCompanies,
        }
      } else {
        // nothing to sync, go to next step
        return {
          ...state,
          comaticStep: ComaticStep.contactFileSelection,
        }
      }
    }

    case types.REMOVE_COMPANIES: {
      return {
        ...state,
        companies: [],
        comaticStep: ComaticStep.companyFileSelection,
      }
    }

    case types.SYNC_CONTACTS: {
      const info = action.payload.info
      const isSync: boolean = info.add + info.update > 0
      if (isSync) {
        return {
          ...state,
          comaticStep: ComaticStep.syncContacts,
        }
      } else {
        // beginning step
        return {
          ...state,
          comaticStep: ComaticStep.finished,
        }
      }
    }

    case types.SKIP_COMPANY_IMPORT: {
      return {
        ...state,
        comaticStep: ComaticStep.contactFileSelection,
      }
    }

    case types.SKIP_CONTACT_IMPORT: {
      return {
        ...state,
        comaticStep: ComaticStep.companyFileSelection,
      }
    }

    case types.REMOVE_CONTACTS: {
      return {
        ...state,
        contacts: [],
        comaticStep: ComaticStep.contactFileSelection,
      }
    }

    case generalTypes.CLEAR_ALL: {
      return {
        ...state,
        contacts: [],
        companies: [],
        comaticStep: ComaticStep.companyFileSelection,
      }
    }

    default:
      return state
  }
}

export default comatic
