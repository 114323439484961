import * as R from 'ramda'
import { take, put, select, all, Effect } from 'redux-saga/effects'
import { TypeKeys } from '../modules/comatic/constants'
import { getComaticCompaniesAdditional } from '../modules/comatic/selectors'
import { TypeKeys as generalTypes } from '../modules/general/constants'
import { AdditionalCompany } from '../modules/comatic/types'
import { getAdvertizerCompaniesAdditional } from '../modules/comatic/selectorsAdvertizer'
import { stubSelector } from '../modules/general/selectors'

export function* fetchCompanies() {
  yield put({
    type: generalTypes.REQUEST_DATA,
    payload: {
      resource: 'companies',
      params: {
        // type: customer/supplier/organization
      },
    },
  })
}

export function* syncComaticCompanies() {
  while (true) {
    // Wait for a sync company action
    yield take(TypeKeys.SYNC_COMPANIES)

    const comaticCompanies: AdditionalCompany[] = yield select(getComaticCompaniesAdditional)

    const effects: Effect[] = R.map((company: AdditionalCompany) => {
      // Default value for mandatory field
      const name = R.propOr('A.', 'name')(company)

      if (company.syncState === 'Neu') {
        return put({
          type: generalTypes.POST_PUT_DATA,
          payload: {
            requestType: 'POST',
            resource: 'companies',
            data: {
              ...R.omit(['id', 'syncState'], company),
              name,
            },
          },
        })
      }

      if (company.syncState === 'Update') {
        return put({
          type: generalTypes.POST_PUT_DATA,
          payload: {
            requestType: 'PUT',
            resource: `companies/${company.id}`,
            data: {
              ...R.omit(['id', 'syncState'])(company),
              name,
            },
          },
        })
      }

      // Empty select
      return select(stubSelector)
      /*
        return put({
          type: generalTypes.NOTHING_TO_CHANGE_DATA,
          payload: {
            resource: 'company',
            params: [],
            data: {
              ...company
            }
          }
        });*/
    })(comaticCompanies)

    yield all(effects)
  }
}

export function* syncAdvertizerCompanies() {
  while (true) {
    // Wait for a sync company action
    yield take(TypeKeys.SYNC_ADVERTIZER_COMPANIES)

    const advertizerCompanies: AdditionalCompany[] = yield select(getAdvertizerCompaniesAdditional)

    const effects: Effect[] = R.map((company: AdditionalCompany) => {
      if (company.syncState === 'Neu') {
        return put({
          type: generalTypes.POST_PUT_DATA,
          payload: {
            requestType: 'POST',
            resource: 'companies',
            data: {
              ...R.omit(['id', 'syncState'], company),
            },
          },
        })
      }

      if (company.syncState === 'Update') {
        return put({
          type: generalTypes.POST_PUT_DATA,
          payload: {
            requestType: 'PUT',
            resource: `companies/${company.id}`,
            data: {
              ...R.omit(['id', 'syncState', 'type'])(company),
            },
          },
        })
      }

      return select(stubSelector)
      /*
        return put({
          type: generalTypes.NOTHING_TO_CHANGE_DATA,
          payload: {
            resource: 'company',
            params: [],
            data: {
              ...company
            }
          }
        });
        */
    })(advertizerCompanies)

    yield all(effects)
  }
}
