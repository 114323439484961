import * as React from 'react'
import { compose } from 'recompose'
import CustomReactTable from '../CustomTable'
import { connect } from 'react-redux'
import { getData, AllEmployeeOverview } from '../../state/modules/stats/allEmployeeSelectors'
import { RootState } from '../../state/store'
import {
  ColumnConfiguration,
  ExcelButton,
  Format,
  getColumns,
  getExport,
  renameColumns,
} from '../../util/table'
import AllFilter from '../filter/AllFilter'
import { StatisticNames } from '../../state/modules/general/types'

type AllEmployeeStatsPropsPublic = {}

interface AllEmployeeStatsProps {
  data: AllEmployeeOverview[]
}

const AllEmployeeStats: React.SFC<AllEmployeeStatsProps> = ({ data }) => {
  const columnConfig: ColumnConfiguration[] = [
    { id: 'user', label: 'Mitarbeitende', format: Format.name },
    {
      id: 'projectTotal',
      label: 'Total',
      format: Format.hours,
      maxWidth: 100,
      style: { fontWeight: 'bold' },
    },
    {
      id: 'projectNormal',
      label: 'Normal',
      format: Format.hours,
      maxWidth: 80,
    },
    {
      id: 'projectReduced',
      label: 'Reduziert',
      format: Format.hours,
      maxWidth: 80,
    },

    {
      id: 'invoiceTotalHour',
      label: 'Stunden',
      format: Format.hours,
      maxWidth: 80,
    },
    {
      id: 'invoiceTotalAmount',
      label: 'Betrag',
      format: Format.currency,
      maxWidth: 100,
      style: { fontWeight: 'bold' },
    },
    {
      id: 'invoiceTotalAvg',
      label: 'Ansatz',
      format: Format.currency,
      maxWidth: 80,
    },
    {
      id: 'billedNormalHour',
      label: 'Stunden',
      format: Format.hours,
      maxWidth: 80,
    },
    {
      id: 'billedNormalAmount',
      label: 'Betrag',
      format: Format.currency,
      maxWidth: 100,
    },
    {
      id: 'billedNormalAvg',
      label: 'Ansatz',
      format: Format.currency,
      maxWidth: 80,
    },
    {
      id: 'billedReducedHour',
      label: 'Stunden',
      format: Format.hours,
      maxWidth: 80,
    },
    {
      id: 'billedReducedAmount',
      label: 'Betrag',
      format: Format.currency,
      maxWidth: 100,
    },
    {
      id: 'billedReducedAvg',
      label: 'Ansatz',
      format: Format.currency,
      maxWidth: 80,
    },
    {
      id: 'newBusinessHour',
      label: 'New Bizz',
      format: Format.hours,
      maxWidth: 80,
    },
    {
      id: 'customerRelationHour',
      label: 'Kd-Pfl.',
      format: Format.hours,
      maxWidth: 80,
    },
    {
      id: 'marketingHour',
      label: 'Marketing',
      format: Format.hours,
      maxWidth: 80,
    },
    {
      id: 'internalHour',
      label: 'Intern',
      format: Format.hours,
      maxWidth: 80,
    },
    {
      id: 'absenceHour',
      label: 'Abwesenheit',
      format: Format.hours,
      maxWidth: 80,
    },
    {
      id: 'holidayHour',
      label: 'Ferien',
      format: Format.hours,
      maxWidth: 80,
    },
    {
      id: 'sickHour',
      label: 'Krankheit',
      format: Format.hours,
      maxWidth: 80,
    },
    {
      id: 'percentageJahresSoll',
      label: 'Erreichtes Soll %',
      format: Format.percentage,
      maxWidth: 100,
    },
  ]

  const columns = getColumns(columnConfig)

  const mapperForExport = {
    invoiceTotalHour: 'Fakturiert Total Stunden',
    invoiceTotalAmount: 'Fakturiert Total Betrag',
    invoiceTotalAvg: 'Fakturiert Total Stundenansatz',
    billedNormalHour: 'Fakturiert Normal Stunden',
    billedNormalAmount: 'Fakturiert Normal Betrag',
    billedNormalAvg: 'Fakturiert Normal Stundenansatz',
    billedReducedHour: 'Fakturiert Reduziert Stunden',
    billedReducedAmount: 'Fakturiert Reduziert Betrag',
    billedReducedAvg: 'Fakturiert Reduziert Stundenansatz',
  }

  const exportColumnConfig = renameColumns(columnConfig, mapperForExport)

  const projectColumns = [columns[1], columns[2], columns[3]]
  const billedColumns = [
    {
      Header: 'Fakturiert Total',
      columns: [columns[4], columns[5], columns[6]],
    },
    {
      Header: 'Fakturiert Normal',
      columns: [columns[7], columns[8], columns[9]],
    },
    {
      Header: 'Fakturiert Reduziert',
      columns: [columns[10], columns[11], columns[12]],
    },
  ]
  const internalColums = [
    columns[13],
    columns[14],
    columns[15],
    columns[16],
    columns[17],
    columns[18],
    columns[19],
    columns[20]
  ]

  const finalColumns = [
    {
      Header: '',
      columns: [columns[0]],
    },
    {
      Header: 'Projektstunden',
      columns: projectColumns,
    },
    ...billedColumns,
    {
      Header: '',
      columns: internalColums,
    },
  ]

  return (
    <React.Fragment>
      <AllFilter statistic={StatisticNames.employee} />
      <ExcelButton onClick={getExport('Mitarbeitende-Alle', exportColumnConfig, data)} />
      <CustomReactTable
        data={data}
        columns={finalColumns}
        pivotBy={[]}
        sortable={false}
        defaultPageSize={50}
      />
    </React.Fragment>
  )
}

const mapStateToProps = (
  state: RootState,
  props: AllEmployeeStatsPropsPublic
): AllEmployeeStatsProps => ({
  data: getData(state),
})

export default compose<AllEmployeeStatsProps, AllEmployeeStatsPropsPublic>(
  connect(mapStateToProps)
)(AllEmployeeStats)
