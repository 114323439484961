import { connect } from 'react-redux';
import { RootState } from '../../state/store';
import { generalActions } from '../../state/modules/general';

export type WithToggleProps = {
  toggle: () => void
  open: boolean
}

const mapStateToProps = (state: RootState) => ({
  open: state.general.openSidebar,
});

const mapDispatchToProps = ({
    toggle: generalActions.toggle
});

export const withToggle = connect(mapStateToProps, mapDispatchToProps);
