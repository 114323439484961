import * as R from 'ramda'
import moment from 'moment'
import { RootState } from '../../store'
import {
  getActivities,
  getActivitiesOfUser,
  getActivitiesTotalHour,
  getCategorizedActivities,
} from '../activities/selectors'
import { getSchedules, getSchedulesOfUser, getSchedulesTotalHour } from '../schedules/selectors'
import { ExtendedProject, getProjects, isReducedProject } from '../projects/selectors'
import { Activity } from '../activities/types'
import { Schedule } from '../schedules/types'
import { getFilterYear, getGoals, getUserById } from '../users/selectors'
import { getCurrentYear, getDaysOfEmployeeYear } from '../../../util/date'
import { getInvoices, getInvoiceShare } from '../invoices/selectors'
import { Invoice } from '../invoices/types'
import { getFilter } from '../general/selectors'
import { StatisticNames } from '../general/types'
import { DataFilter, FilteredState, getFilteredState, ProjectTypeFilter } from './statFilter'
import { UserGoal, User } from '../users/types'
import { getSumTotal, UndefinedNumber } from '../../../util/ramdaUtil'
import { getExpenses } from '../expenses/selectors'

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>

export type EmployeeOverview = {
  month: string
  projectNormal: UndefinedNumber
  projectReduced: UndefinedNumber
  projectTotal: UndefinedNumber // = projectNormal + projectReduced
  invoiceTotalHour: UndefinedNumber // = billedNormalHour + billedReducedHour
  invoiceTotalAmount: UndefinedNumber // = billedNormalAmount + billedReducedAmount
  billedNormalHour: UndefinedNumber
  billedNormalAmount: UndefinedNumber
  billedReducedHour: UndefinedNumber
  billedReducedAmount: UndefinedNumber

  newBusinessHour: UndefinedNumber
  customerRelationHour: UndefinedNumber
  marketingHour: UndefinedNumber
  internalHour: UndefinedNumber
  absenceHour: UndefinedNumber

  holidayHour: UndefinedNumber
  sickHour: UndefinedNumber

  nonBillableReduced: UndefinedNumber
  nonBillableNormal: UndefinedNumber
  // total: number
}

export type NetOverview = Omit<EmployeeOverview, 'month'>

const months = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
]

const getMonth = (date: string): string => {
  // dateFormat shhould be YYYY-MM-DD
  const dateArray = date.split('-')
  const month: number = dateArray[1] ? Number.parseInt(dateArray[1], 10) : 0
  return months[month - 1]
}

export const getTargetHoursPerToday = <T>(
  targetTotal: T,
  filter: DataFilter,
  employee: User
): T => {
  let beginEmployeeDate = employee.custom_properties.Eintrittsdatum
    ? moment(employee.custom_properties.Eintrittsdatum, 'DD.MM.YYYY')
    : filter.startDate
  let endEmployeeDate = employee.custom_properties.Austrittsdatum
    ? moment(employee.custom_properties.Austrittsdatum, 'DD.MM.YYYY')
    : filter.endDate

  const from =
    filter.startDate && beginEmployeeDate
      ? beginEmployeeDate.isAfter(filter.startDate)
        ? beginEmployeeDate
        : filter.startDate
      : getCurrentYear()

  const to =
    filter.endDate && endEmployeeDate
      ? endEmployeeDate.isBefore(filter.endDate)
        ? endEmployeeDate
        : filter.endDate
      : moment()

  // const from = filter.startDate ? filter.startDate : getCurrentYear() // moment([getCurrentYear()])
  // const to = filter.endDate ? filter.endDate : moment()
  const daysBetween = to.diff(from, 'days')
  const daysThisYear = getDaysOfEmployeeYear(to, employee)

  const getPartlyDays = (num: number): number => {
    return (num * daysBetween) / daysThisYear
  }

  // @ts-ignore
  const todayTotal: T = R.map((value: any) => {
    if (typeof value === 'number') {
      return getPartlyDays(value)
    }
    return value
  })(targetTotal)
  return todayTotal
}

export const getEmployeeTargetDiff = <T extends object>(targetOverview: T, isOverview: T): T => {
  const diffOverview = R.reduce(R.mergeDeepWith(R.subtract), {}, [isOverview, targetOverview])
  return diffOverview as T
}

export const getEmployeePercentageDiff = <T extends object>(
  targetOverview: T,
  diffOverview: T
): T => {
  const diffPercentageOverview = R.reduce(
    R.mergeDeepWith(
      R.pipe(
        R.divide,
        R.multiply(100),
        R.ifElse(
          isFinite,
          R.defaultTo(0), // Default to 0, if it's NaN, otherwise normal number
          R.always(0) // infinity
        )
      )
    ),
    {},
    [diffOverview, targetOverview]
  )

  return diffPercentageOverview as T
}

const getEmptyRow = (): EmployeeOverview => {
  return {
    month: '',
    projectNormal: undefined,
    projectTotal: undefined,
    invoiceTotalHour: undefined,
    invoiceTotalAmount: undefined,
    internalHour: undefined,
    holidayHour: undefined,
    customerRelationHour: undefined,
    marketingHour: undefined,
    newBusinessHour: undefined,
    absenceHour: undefined,
    sickHour: undefined,
    billedReducedHour: undefined,
    billedNormalHour: undefined,
    billedReducedAmount: undefined,
    billedNormalAmount: undefined,
    projectReduced: undefined,
    nonBillableReduced: undefined,
    nonBillableNormal: undefined,
  }
}

const getZeroRow = (): EmployeeOverview => {
  return {
    month: '',
    projectNormal: 0,
    projectTotal: 0,
    invoiceTotalHour: 0,
    invoiceTotalAmount: 0,
    internalHour: 0,
    holidayHour: 0,
    customerRelationHour: 0,
    marketingHour: 0,
    newBusinessHour: 0,
    absenceHour: 0,
    sickHour: 0,
    billedReducedHour: 0,
    billedNormalHour: 0,
    billedReducedAmount: 0,
    billedNormalAmount: 0,
    projectReduced: 0,
    nonBillableReduced: 0,
    nonBillableNormal: 0,
  }
}

const getHoursData = (goal: UserGoal, netOverview: NetOverview): EmployeeOverview[] => {
  // Stundenansatz Soll
  const targetHourlyRate = {
    ...getEmptyRow(),
    month: 'Stundenansatz SOLL',
    invoiceTotalAmount:
      (goal.StundenansatzNormal * goal.StundenanzahlNormal +
        goal.StundenansatzReduziert * goal.StundenanzahlReduziert) /
      (goal.StundenanzahlNormal + goal.StundenanzahlReduziert),
    billedNormalAmount: goal.StundenansatzNormal,
    billedReducedAmount: goal.StundenansatzReduziert,
  }

  // Stundenansatz IST
  const isHourlyRateNormal =
    netOverview.billedNormalAmount &&
    netOverview.billedNormalHour &&
    isFinite(netOverview.billedNormalAmount / netOverview.billedNormalHour)
      ? netOverview.billedNormalAmount / netOverview.billedNormalHour
      : undefined
  const isHourlyRateReduced =
    netOverview.billedReducedAmount &&
    netOverview.billedReducedHour &&
    isFinite(netOverview.billedReducedAmount / netOverview.billedReducedHour)
      ? netOverview.billedReducedAmount / netOverview.billedReducedHour
      : undefined

  const getIsHourlyRateTotal = (o: NetOverview): UndefinedNumber => {
    // Everything is defined
    if (isHourlyRateNormal && o.billedNormalHour && isHourlyRateReduced && o.billedReducedHour) {
      let result =
        (isHourlyRateNormal * o.billedNormalHour + isHourlyRateReduced * o.billedReducedHour) /
        (o.billedNormalHour + o.billedReducedHour)
      return isFinite(result) ? result : undefined
    }
    // only normal hours
    if (o.billedNormalHour) {
      return isHourlyRateNormal
    }
    // only reduced hours
    if (o.billedReducedHour) {
      return isHourlyRateReduced
    }

    return undefined
  }

  const isHourlyRate = {
    ...getEmptyRow(),
    month: 'Stundenansatz IST',
    invoiceTotalAmount: getIsHourlyRateTotal(netOverview),
    billedNormalAmount: isHourlyRateNormal,
    billedReducedAmount: isHourlyRateReduced,
  }

  const hourlyRatePercentage = {
    ...getEmptyRow(),
    month: 'Verrechneter Stundenansatz in %',
    billedNormalAmount: isHourlyRateNormal
      ? (isHourlyRateNormal / goal.StundenansatzNormal) * 100
      : undefined,
    billedReducedAmount: isHourlyRateReduced
      ? (isHourlyRateReduced / goal.StundenansatzReduziert) * 100
      : undefined,
  }

  const totalEfficiency = getSumTotal(netOverview.billedReducedHour, netOverview.billedNormalHour)
  const efficiencyRow = {
    ...getEmptyRow(),
    month: 'Verhältnis Normal/Reduziert in %',
    billedNormalHour: netOverview.billedNormalHour
      ? (netOverview.billedNormalHour / totalEfficiency) * 100
      : undefined,
    billedReducedHour: netOverview.billedReducedHour
      ? (netOverview.billedReducedHour / totalEfficiency) * 100
      : undefined,
  }

  return [targetHourlyRate, isHourlyRate, hourlyRatePercentage, efficiencyRow]
}

export const getTotal = R.reduce((result: EmployeeOverview, overview: EmployeeOverview) => {
  const keys = R.keys(overview)
  return R.reduce((sum: EmployeeOverview, key: string) => {
    return {
      ...sum,
      // @ts-ignore
      [key]: getSumTotal(result[key] + overview[key]),
    }
  }, getZeroRow())(keys)
}, getZeroRow())

const getAdditionalEmployeeData = (
  employeeOverviews: EmployeeOverview[],
  allStates: FilteredState,
  userId: number,
  goal: UserGoal,
  filter: DataFilter
): EmployeeOverview[] => {
  const additionalData: EmployeeOverview[] = []
  const user = getUserById(allStates.unfiltered, userId)

  // Total netto
  const total = getTotal(employeeOverviews)
  additionalData.push({
    ...total,
    month: 'Total pro MA (Netto)',
  })

  // Total non billable activities
  const nonBillableRow = {
    ...getEmptyRow(),
    month: 'Total nicht verrechenbar',
    projectReduced: total.nonBillableReduced,
    projectNormal: total.nonBillableNormal,
    projectTotal: getSumTotal(total.nonBillableReduced, total.nonBillableNormal),
  }
  additionalData.push(nonBillableRow)

  // Brutto total
  additionalData.push({
    ...getEmptyRow(),
    month: 'Brutto Total',
    projectReduced: getSumTotal(total.nonBillableReduced, total.projectReduced),
    projectNormal: getSumTotal(total.nonBillableNormal, total.projectNormal),
    projectTotal: getSumTotal(
      total.nonBillableReduced,
      total.projectReduced,
      total.nonBillableNormal,
      total.projectNormal
    ),
  })

  additionalData.push(getEmptyRow())

  // Jahres-Soll
  const sollTotal: EmployeeOverview = {
    month: 'Jahres-Soll',

    projectNormal: goal.StundenanzahlNormal,
    projectReduced: goal.StundenanzahlReduziert,

    projectTotal: goal.StundenanzahlNormal + goal.StundenanzahlReduziert,
    invoiceTotalHour: undefined,
    invoiceTotalAmount:
      goal.StundenanzahlNormal * goal.StundenansatzNormal +
      goal.StundenanzahlReduziert * goal.StundenansatzReduziert,

    billedNormalHour: undefined,
    billedReducedHour: undefined,
    billedNormalAmount: goal.StundenanzahlNormal * goal.StundenansatzNormal,
    billedReducedAmount: goal.StundenanzahlReduziert * goal.StundenansatzReduziert,
    absenceHour: undefined, // goal.AbwesenheitenSoll,
    sickHour: undefined,

    newBusinessHour: goal.NewBizzSoll,
    marketingHour: goal.MarketingSoll,
    customerRelationHour: goal.KundenpflegeSoll,
    holidayHour: goal.FerienSoll,
    internalHour: goal.InternesSoll, // undefined, // Not defined -> goal.InternesSoll,
    nonBillableReduced: undefined,
    nonBillableNormal: undefined,
  }
  additionalData.push(sollTotal)

  // Soll per Heute
  const todayTotal = getTargetHoursPerToday(sollTotal, filter, user)

  additionalData.push({
    ...todayTotal,
    month: 'Soll per heute',
  })

  // Abweichung soll per heute
  const diffTodayTotal = getEmployeeTargetDiff(todayTotal, total)
  additionalData.push({
    ...diffTodayTotal,
    month: 'Abweichung',
  })

  const diffTodayPercentage = getEmployeePercentageDiff(todayTotal, diffTodayTotal)
  additionalData.push({
    ...diffTodayPercentage,
    month: 'Abweichung %',
  })

  additionalData.push(getEmptyRow())

  const hourData = getHoursData(goal, total)

  return [...additionalData, ...hourData]
}

const getInvoiceMonthInfo = (state: FilteredState, userId: number, month: string) => {
  const allActivities = R.values(getActivities(state.unfiltered))
  const allInvoices = R.values(getInvoices(state.unfiltered))
  const allExpenses = R.values(getExpenses(state.unfiltered))

  // Todo: Refactor
  const getInvoiceShareOf = (relActivities: Activity[], project: ExtendedProject) => {
    return getInvoiceShare(allInvoices, allActivities, relActivities, allExpenses, project)
  }

  // Activities of user by InvoiceDate
  const initial = {
    billedNormalHour: 0,
    billedNormalAmount: 0,
    billedReducedHour: 0,
    billedReducedAmount: 0,
    invoiceTotalHour: 0,
    invoiceTotalAmount: 0,
  }

  const filteredByInvoiceMonth = R.filter((project: ExtendedProject) => {
    const allInvoiceDates = R.map((invoice: Invoice) => moment(invoice.date))(project.invoices)
    const maxInvoiceDate = moment.max(allInvoiceDates)

    return months[maxInvoiceDate.month()] === month
  })(state.filteredByInvoiceDate)

  const billedResult = R.reduce((result: typeof initial, project: ExtendedProject) => {
    const activitiesOfUser = getActivitiesOfUser(project.activities, userId)

    if (isReducedProject(project)) {
      return {
        ...result,
        billedReducedHour: result.billedReducedHour + getActivitiesTotalHour(activitiesOfUser),
        billedReducedAmount:
          result.billedReducedAmount + getInvoiceShareOf(activitiesOfUser, project),
      }
    }
    return {
      ...result,
      billedNormalHour: result.billedNormalHour + getActivitiesTotalHour(activitiesOfUser),
      billedNormalAmount: result.billedNormalAmount + getInvoiceShareOf(activitiesOfUser, project),
    }
  }, initial)(filteredByInvoiceMonth)

  // Add total from reduced and normal
  return {
    ...billedResult,
    invoiceTotalHour: billedResult.billedNormalHour + billedResult.billedReducedHour,
    invoiceTotalAmount: billedResult.billedNormalAmount + billedResult.billedReducedAmount,
  }
}

const getMonthInfo = (state: FilteredState, userId: number, month: string) => {
  // Get necessary information from state
  const activities = getActivitiesOfUser(R.values(getActivities(state.filteredState)), userId)
  const projects = getProjects(state.filteredState)
  // const user = getUserById(state.filteredState, userId);
  const schedules = getSchedulesOfUser(R.values(getSchedules(state.filteredState)), userId)

  const relActivities = R.filter((activity: Activity) => getMonth(activity.date) === month)(
    activities
  )
  const relSchedules = R.filter((schedule: Schedule) => getMonth(schedule.date) === month)(
    schedules
  )

  const catActivities = getCategorizedActivities(relActivities, projects)
  const projectNormal = getActivitiesTotalHour(catActivities.normalActivities)
  const projectReduced = getActivitiesTotalHour(catActivities.reducedActivities)

  return {
    month,
    projectNormal,
    projectReduced,
    projectTotal: projectNormal + projectReduced,

    newBusinessHour: getActivitiesTotalHour(catActivities.newBusinessActivities),
    customerRelationHour: getActivitiesTotalHour(catActivities.customerRelationActivities),
    marketingHour: getActivitiesTotalHour(catActivities.marketingActivities),
    internalHour: getActivitiesTotalHour(catActivities.internalActivities),
    absenceHour: getActivitiesTotalHour(catActivities.absenceActivities),

    holidayHour: getSchedulesTotalHour(relSchedules, 'Urlaub'),
    sickHour: getSchedulesTotalHour(relSchedules, 'Krankheit'),

    nonBillableReduced: getActivitiesTotalHour(catActivities.nonBillableReduced),
    nonBillableNormal: getActivitiesTotalHour(catActivities.nonBillableNormal),
  }
}

export const getData = (rootState: RootState, userId: number): EmployeeOverview[] => {
  const filter = getFilter(rootState, StatisticNames.employee)

  const allStates = getFilteredState(rootState, {
    ...filter,
    projectType: ProjectTypeFilter.archived,
  })

  const user = getUserById(rootState, userId)
  const goal = getGoals(getFilterYear(filter), user)
  /*const state = allStates.filteredState;

  const activities = getActivitiesOfUser(R.values(getActivities(state)), userId);
  const projects = getProjects(state);
  const invoices = R.values(getInvoices(state));
  const user = getUserById(state, userId);

  const schedules = getSchedulesOfUser(R.values(getSchedules(state)), userId);*/
  const employeeMonth: EmployeeOverview[] = months.map((month: string) => {
    const info = getMonthInfo(allStates, userId, month)
    const invoiceInfo = getInvoiceMonthInfo(allStates, userId, month)

    return {
      month,
      ...info,
      ...invoiceInfo,
    }
  })

  const additionalInformation = getAdditionalEmployeeData(
    employeeMonth,
    allStates,
    userId,
    goal,
    filter
  )

  return [...employeeMonth, ...additionalInformation]
}
