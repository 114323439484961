// import { TypeKeys as types } from './constants';
import * as R from 'ramda'
import { TypeKeys as generalTypes } from '../general/constants'
import { ActivityAction } from './actions'
import { ActivityState, ActivityServerResponse } from './types'

const initState = {
  detailsById: {},
}

const activities = (
  state: ActivityState = {
    ...initState,
  },
  action: ActivityAction
): ActivityState => {
  switch (action.type) {
    case generalTypes.ADD_DATA: {
      if (action.payload.resource === 'activities') {
        // Minimize server response to save localstorage quota
        const reducedActivities = R.map((activity: ActivityServerResponse) => {
          return {
            id: activity.id,
            date: activity.date,
            hours: activity.hours,
            billed: activity.billed,
            billable: activity.billable,
            project: {
              id: activity.project.id,
            },
            task: activity.task,
            customer: {
              id: activity.customer.id,
            },
            user: {
              id: activity.user.id,
            },
            hourly_rate: activity.hourly_rate,
          }
        })(action.payload.data)
        return {
          ...state,
          detailsById: {
            ...state.detailsById,
            ...reducedActivities,
          },
        }
      } else {
        return state
      }
    }

    case generalTypes.CLEAR_ALL: {
      return {
        ...state,
        detailsById: {},
      }
    }
    /* todo: implement again
    case types.REPLACE_ALL: {
      return { ...state, detailsById: action.payload, currentEvent: Object.keys(action.payload)[0] };
    }*/

    default:
      return state
  }
}

export default activities
