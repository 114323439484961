// import * as _ from 'lodash';
import * as R from 'ramda'
import moment from 'moment'
import { RootState } from '../../store'
import { Expense, ExpenseList } from './types'
import { ExtendedProject } from '../projects/selectors'
import { Moment } from 'moment'

export const getExpenses = (state: RootState): ExpenseList => {
  return state.expenses.detailsById
}

/*const isBillable = (expense: Expense) => {
  return expense.billable;
}*/

const isBudgetRelevant = (expense: Expense) => {
  return expense.budget_relevant
}

export const getExpensesBetweenDates = (
  startDate: Moment | null,
  endDate: Moment | null,
  expenses: Expense[]
): Expense[] => {
  const start = startDate ? startDate : moment('2010-01-01')
  const end = endDate ? endDate : moment('2099-12-31')

  return R.filter((expense: Expense) => {
    const activityDate = moment(expense.date)
    return activityDate.isBetween(start, end, 'day', '[]')
  })(expenses)
}

export const getBudgetRelevantExpenses = (state: RootState): ExpenseList => {
  return R.filter(isBudgetRelevant)(getExpenses(state) as any)
}

export const getExpensesOfProject = (expenses: Expense[], projectId: number): Expense[] => {
  return R.filter((expense: Expense) => {
    return expense.project_id === projectId
  })(expenses)
}

/**
 * Returns the total amount of budget relevant stats, used for "AA-Liste" and BBE - laufende Projekte
 * @param expenses
 */
export const getExpensesTotal = (expenses: Expense[]): number => {
  return expenses.reduce((amount: number, expense: Expense) => {
    if (expense.budget_relevant) {
      if (expense.cost > 0) {
        return amount + expense.cost
      }
      return amount + expense.price
    }
    return amount
  }, 0)
}

/**
 * Returns the total amount of all expenses, irrelevant if it is in the budget or not (used for employee stats)
 * @param expenses
 */
export const getAllExpensesTotal = (expenses: Expense[]): number => {
  return expenses.reduce((amount: number, expense: Expense) => {
    if (expense.cost > 0) {
      return amount + expense.cost
    }
    return amount + expense.price
  }, 0)
}

/**
 * Returns the total amount of expenses that are budget irrelevant. Used for expenses that are "Durchlaufumsatz"
 * @param expenses
 */
export const getBudgetIrrelevantExpensesTotal = (expenses: Expense[]): number => {
  return expenses.reduce((amount: number, expense: Expense) => {
    if (!expense.budget_relevant) {
      if (expense.cost > 0) {
        return amount + expense.cost
      }
      return amount + expense.price
    }
    return amount
  }, 0)
}

export const getExpensesOfExtendedProjectList: (
  projects: ExtendedProject[]
) => Expense[] = R.reduce((expenses: Expense[], project: ExtendedProject) => {
  return [...expenses, ...project.expenses]
}, [])

export default {
  getExpenses,
}
