import * as React from 'react'
import ReactTable, { TableProps } from 'react-table-6'
import 'react-table-6/react-table.css'
import { compose } from 'recompose'
import './custom-table.css'

type PublicProps = {}

type ComponentProps = PublicProps & Partial<TableProps>

const CustomTable: React.StatelessComponent<ComponentProps> = ({ ...props }) => {
  return (
    <ReactTable
      // minRows={3}
      defaultPageSize={10}
      previousText="Vorherige"
      nextText="Nächste"
      loadingText="Laden..."
      noDataText="Keine Daten gefunden"
      pageText="Seite"
      ofText="von"
      rowsText="Zeilen"
      /*getTdProps={(state, rowInfo, column, instance) => {
        return {
          classes: {
            number:
          }
        };
      }}*/

      {...props}
    />
  )
}

export default compose<PublicProps, ComponentProps>()(CustomTable)
