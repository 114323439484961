export enum TypeKeys {
  ADD_CONTACTS = 'comatic/ADD_CONTACTS_DATA',
  REMOVE_CONTACTS = 'comatic/REMOVE_CONTACTS_DATA',
  ADD_COMPANIES = 'comatic/ADD_COMPANIES_DATA',
  REMOVE_COMPANIES = 'comatic/REMOVE_COMPANIES_DATA',
  SYNC_COMPANIES = 'comatic/SYNC_COMATIC_COMPANIES',
  SYNC_CONTACTS = 'comatic/SYNC_COMATIC_CONTACTS',
  SYNC_ADVERTIZER_COMPANIES = 'comatic/SYNC_ADVERTIZER_COMPANIES',
  SYNC_ADVERTIZER_CONTACTS = 'comatic/SYNC_ADVERTIZER_CONTACTS',

  SKIP_COMPANY_IMPORT = 'comatic/SKIP_COMPANY_IMPORT',
  SKIP_CONTACT_IMPORT = 'comatic/SKIP_CONTACT_IMPORT',

  RESET = 'comatic/RESET_COMATIC_SYNC'
}
