import axios, {AxiosPromise} from 'axios'
import { getConfig } from './config'

export const authenticate = (email: string, password: string): AxiosPromise<{
  token?: string
  user_id?: string
  message?: string
}> => {
  const params = {
    email,
    password,
  }
  return axios.post(getConfig().url+'session', params)
}
