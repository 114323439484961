import * as React from 'react'
import CustomReactTable from '../../CustomTable'
import { CustomerProfitability } from '../../../state/modules/stats/profitabilitySelector'
import { Company } from '../../../state/modules/companies/types'
import {
  avgFooter,
  ColumnConfiguration,
  ExcelButton,
  Footer,
  Format,
  getColumns,
  getExport,
  SumAggregator,
  totalFooter,
  renameColumns,
} from '../../../util/table'
import { Column } from 'react-table-6'

interface ProfitabilityStatsProps {
  data: CustomerProfitability[]
  customer: Company
}

const ProfitablityStatsAll: React.SFC<ProfitabilityStatsProps> = ({ customer, data }) => {
  // Projekt	Fakturiert	Honorar	 Zusatzleistung	Total Agentur

  // BEWERTUNGSKORRIGIERT
  // Korrektur	Total AA aktuell	AA Vorjahr

  // BBE aktuell	Total Agentur Brutto	Total Agentur Netto	Korrektur	Korrektur %

  const columnConfig: ColumnConfiguration[] = [
    {
      id: 'projectName',
      label: 'Projekt',
      accessor: (props: CustomerProfitability) => `${props.projectName}`,
      footer: <Footer>Total:</Footer>,
      format: Format.name,
    },
    {
      id: 'billedCurrent',
      label: 'Fakturiert',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'billedCurrent'),
      format: Format.currency,
      maxWidth: 100,
    },
    {
      id: 'feeCurrent',
      label: 'Honorar',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'feeCurrent'),
      format: Format.currency,
      maxWidth: 100,
    },
    /*{
      id: 'spesen',
      label: 'Spesen',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'spesen'),
      format: Format.currency,
      maxWidth: 100,
    },*/
    {
      id: 'expenses',
      label: 'Zusatzleistungen',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'expenses'),
      format: Format.currency,
      maxWidth: 100,
    },
    {
      id: 'subTotal',
      label: 'Total Agentur',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'subTotal'),
      format: Format.currency,
      maxWidth: 100,
    },

    // BEWERTUNGSKORRIGIERT
    {
      id: 'correction',
      label: 'Korrektur',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'correction'),
      format: Format.currency,
      maxWidth: 100,
    },
    {
      id: 'total',
      label: 'Total AA aktuell',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'total'),
      format: Format.currency,
      maxWidth: 100,
    },
    {
      id: 'feeDifferent',
      label: 'AA Vorjahr',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'feeDifferent'),
      format: Format.currency,
      maxWidth: 100,
    },

    // Additional columns
    {
      id: 'bbeCurrent',
      label: 'BBE aktuell',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'bbeCurrent'),
      format: Format.currency,
      maxWidth: 100,
    },
    {
      id: 'totalBrutto',
      label: 'Total Agentur Brutto',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'totalBrutto'),
      format: Format.currency,
      maxWidth: 100,
    },
    {
      id: 'totalNetto',
      label: 'Total Agentur Netto',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'totalNetto'),
      format: Format.currency,
      maxWidth: 100,
    },
    {
      id: 'correctionNegative',
      label: 'Korrektur',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'correctionNegative'),
      format: Format.currency,
      maxWidth: 80,
    },
    {
      id: 'correctionPercentage',
      label: 'Korrektur %',
      aggregate: SumAggregator,
      footer: avgFooter(data, 'correctionPercentage'),
      format: Format.currency,
      maxWidth: 50,
    },
  ]

  const tempColumns = getColumns(columnConfig)

  const mapperForExport = {
    correction: 'Bewertungskorrigiert Korrektur',
    correctionNegative: 'Korrektur',
  }

  const exportColumnConfig = renameColumns(columnConfig, mapperForExport)

  const columns: Column[] = [
    {
      Header: '',
      columns: [
        tempColumns[0], // projektname
        tempColumns[1], // Fakturiert
        tempColumns[2], // Honorar
        // tempColumns[3], Spesen
        tempColumns[3], // Zusatzleistungen
        tempColumns[4], // Total Agentur
      ],
    },
    {
      Header: 'Bewertungskorrigiert',
      columns: [
        tempColumns[5], // Korrektur
        tempColumns[6], // Total AA aktuell
        tempColumns[7], // AA Vorjahr
      ],
    },
    {
      Header: '',
      columns: [
        tempColumns[8], // BBE aktuell
        tempColumns[9], // Total Agentur Brutto
        tempColumns[10], // Total Agentur Netto
        tempColumns[11], // Korrektur
        tempColumns[12], // Korrektur %
      ],
    },
  ]

  return (
    <React.Fragment>
      <ExcelButton onClick={getExport('Kunden-Rentabilität', exportColumnConfig, data)} />
      <CustomReactTable data={data} columns={columns} pivotBy={[]} />
    </React.Fragment>
  )
}

export default ProfitablityStatsAll
