import * as React from 'react'
import * as R from 'ramda'
import { Table, TableBody, TableHead, TableCell, TableRow, Grid } from '@material-ui/core'
import { formatCurrency, formatHour, formatPercentage } from '../../util/format'
import { User } from '../../state/modules/users/types'
import { compose } from 'recompose'
import { UserGoal } from '../../state/modules/users/types'
import { RootState } from '../../state/store'
import { getFilterYear, getGoals } from '../../state/modules/users/selectors'
import { connect } from 'react-redux'
import { getFilter } from '../../state/modules/general/selectors'
import { StatisticNames } from '../../state/modules/general/types'

type EmployeeGoalsProps = {
  user: User
}

type InternalProp = {
  goal: UserGoal
  year: string
}

type ComponentProps = InternalProp & EmployeeGoalsProps

const EmployeeGoals: React.SFC<ComponentProps> = ({ user, goal, year }) => {
  const {
    Abschlag,
    JahresSollStd40,
    Beschaeftigungsgrad,
    FerienSoll,
    KundenpflegeSoll,
    InternesSoll,
    KundenSoll,
    NewBizzSoll,
    MarketingSoll,
    StundenansatzNormal,
    StundenansatzReduziert,
    StundenanzahlNormal,
    StundenanzahlReduziert,
  } = goal

  const sollStunden = Math.round((JahresSollStd40 / 100) * Beschaeftigungsgrad * 100) / 100 // Math.round((JahresSollStd40 / 100 * Beschaeftigungsgrad) * 100) / 100;
  const total = sollStunden - FerienSoll

  const control = R.sum([
    KundenpflegeSoll,
    KundenSoll,
    NewBizzSoll,
    InternesSoll,
    MarketingSoll,
    FerienSoll,
  ])
  // const total = JahresSoll - KundenpflegeSoll - KundenSoll - NewBizzSoll - MarketingSoll - AbsenzenSoll;
  const potentialHonorar =
    StundenanzahlNormal * StundenansatzNormal + StundenanzahlReduziert * StundenansatzReduziert

  return (
    <React.Fragment>
      <h1>
        Zielvorgaben {user.firstname} {user.lastname} {year}
      </h1>
      <Grid container>
        <Grid item xs={12} md={9} lg={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Beschreibung</TableCell>
                <TableCell numeric>Vorgabe</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>Sollstunden 40 Std.</TableCell>
                <TableCell numeric>{formatHour(JahresSollStd40, true)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Beschäftigungsgrad</TableCell>
                <TableCell numeric>{formatPercentage(Beschaeftigungsgrad, true)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ferienvorgabe</TableCell>
                <TableCell numeric>{formatHour(FerienSoll, true)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell numeric>
                  <b>{formatHour(total, true)}</b>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Kunden</TableCell>
                <TableCell numeric>{formatHour(KundenSoll, true)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>New Bizz</TableCell>
                <TableCell numeric>{formatHour(NewBizzSoll, true)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Kundenpflege</TableCell>
                <TableCell numeric>
                  <b>{formatHour(KundenpflegeSoll, true)}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Marketing</TableCell>
                <TableCell numeric>{formatHour(MarketingSoll, true)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Interne Projekte</TableCell>
                <TableCell numeric>{formatHour(InternesSoll, true)}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Kontrolle</TableCell>
                <TableCell numeric>
                  <b>{formatHour(control, true)}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Differenz</TableCell>
                <TableCell numeric>
                  {formatHour(sollStunden - control, true)} für Internes
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell />
              </TableRow>

              <TableRow>
                <TableCell>Normal</TableCell>
                <TableCell numeric>
                  {formatHour(StundenanzahlNormal, true)} à{' '}
                  {formatCurrency(StundenansatzNormal, true)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Reduziert</TableCell>
                <TableCell numeric>
                  {formatHour(StundenanzahlReduziert, true)} à{' '}
                  {formatCurrency(StundenansatzReduziert, true)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>Mögliches Honorar</TableCell>
                <TableCell numeric>{formatCurrency(potentialHonorar, true)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Abschlag</TableCell>
                <TableCell numeric>{formatPercentage(Abschlag, true)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Fakturierter Umsatz</TableCell>
                <TableCell numeric>
                  {formatCurrency((potentialHonorar * (100 - Abschlag)) / 100, true)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      {/*
      <h3>Vorgaben</h3>
      <ul>
        <li>Sollstunden 40 Std.: {formatHour(JahresSollStd40)}</li>
        <li>Beschäftigungsgrad: {formatPercentage(Beschaeftigungsgrad)}</li>
        <li><b>Sollstunden: {sollStunden}</b></li>
        <li>Feriensaldo: {FerienSoll}</li>
        <li><b>Total: {total}</b></li>
        <li>Kunden: {formatHour(KundenSoll)}</li>
        <li>New Bizz: {formatHour(NewBizzSoll)}</li>
        <li>Kundenpflege: {formatHour(KundenpflegeSoll)}</li>
        <li>Marketing: {formatHour(MarketingSoll)}</li>
        <li>Absenzen: {formatHour(AbsenzenSoll)}</li>
      </ul>
      <b>Kontrolle: {formatHour(control)}</b><br/>
      <b>Differenz: {formatHour(sollStunden-control)} für Internes</b>

      <ul>
        <li>Normal: {formatHour(StundenanzahlNormal)} à {formatCurrency(StundenansatzNormal)}</li>
        <li>Reduziert: {formatHour(StundenanzahlReduziert)} à {formatCurrency(StundenansatzReduziert)}</li>
      </ul>
      <p>Mögliches Honorar: {formatCurrency(potentialHonorar)}<br/>
      Abschlag {formatPercentage(Abschlag, true)}<br/>
      Fakturierter Umsatz: {formatCurrency(potentialHonorar*(100-Abschlag)/100)}</p>
    */}
    </React.Fragment>
  )
}

const mapStateToProps = (state: RootState, props: EmployeeGoalsProps): ComponentProps => ({
  goal: getGoals(getFilterYear(getFilter(state, StatisticNames.employee)), props.user),
  user: props.user,
  year: getFilterYear(getFilter(state, StatisticNames.employee)),
})

export default compose<ComponentProps, EmployeeGoalsProps>(connect(mapStateToProps))(EmployeeGoals)
