import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Layout from '../../components/Layout/layout'
import { routes } from '../routes'
import { RootState } from '../../state/store'
import { User } from '../../state/modules/users/types'
import { userSelector } from '../../state/modules/users/index'
import AllEmployeeStats from '../../components/employee/AllEmployeeStats'
import SyncButton from '../../components/SyncButton'
import { authenticationSelector } from '../../state/modules/authentication'
import EmployeeSelection from './EmployeeSelection'

type PeopleProps = {
  users: User[]
  loggedIn: boolean
}

type ComponentProps = PeopleProps & RouteComponentProps<{ id: string }>

const PeoplePage: React.SFC<ComponentProps> = ({ location, users, loggedIn }) => {
  if (!loggedIn) {
    return <Redirect to="/" />
  }

  if (users.length < 1) {
    return (
      <Layout routes={routes} location={location}>
        <p>Keine Mitarbeitende gefunden. Synchronisiert?</p>
        <SyncButton />
      </Layout>
    )
  }
  return (
    <Layout routes={routes} location={location}>
      <h1>Alle Mitarbeitende</h1>

      <AllEmployeeStats />
      <EmployeeSelection activeUserId={1} users={users} />
    </Layout>
  )
}

const mapStateToProps = (state: RootState): PeopleProps => ({
  users: userSelector.getVivaUsers(state),
  loggedIn: authenticationSelector.isAuthenticated(state),
})
// @ts-ignore
export default compose(connect(mapStateToProps))(PeoplePage)
