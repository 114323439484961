import { ProjectTypeFilter } from '../stats/statFilter'

export type StatFilter = {
  labels: string[]
  startDate: string | null
  endDate: string | null
  projectType: ProjectTypeFilter
}

export enum StatisticNames {
  aaList = 'aaList',
  employee = 'employee',
  customer = 'customer',
}

export enum SyncContext {
  empty = '',
  complete = 'complete',
  partial = 'partial',
}

export interface GeneralState {
  readonly pendingRequests: number
  readonly maxRequests: number
  readonly openSidebar: boolean
  readonly persistStorage: boolean
  readonly lastSyncData: string
  readonly lastSyncTime: string
  readonly lastReSyncData: string
  readonly lastReSyncTime: string
  readonly syncContext: SyncContext
  readonly error: string
  readonly statFilters: { [key in StatisticNames]: StatFilter }
}
