import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Layout from '../../components/Layout/layout'
import { routes } from '../routes'
import { RootState } from '../../state/store'
import BbeStats from '../../components/customer/BbeStats'
import { authenticationSelector } from '../../state/modules/authentication'
import { CustomerStats } from './CustomerSelection'
import CustomerSelection from './CustomerSelection'
import { getCustomers } from '../../state/modules/companies/selectors'
import { Company } from '../../state/modules/companies/types'
import { Typography } from '@material-ui/core'

type OverviewCustomerProps = {
  loggedIn: boolean
  customers: Company[]
}

type ComponentProps = OverviewCustomerProps & RouteComponentProps<any>

/**
 * Page for Brutto-Betriebseinnahmen
 */
const BbePage: React.SFC<ComponentProps> = ({ customers, location, match, loggedIn }) => {
  if (!loggedIn) {
    return <Redirect to="/" />
  }

  return (
    <Layout routes={routes} location={location}>
      <Typography variant={'display1'}>Kunden-Bruttobetriebseinnahmen</Typography>
      <BbeStats />
      <CustomerSelection activeCustomerStat={CustomerStats.bbe} customers={customers} />
    </Layout>
  )
}

const mapStateToProps = (state: RootState): OverviewCustomerProps => ({
  loggedIn: authenticationSelector.isAuthenticated(state),
  customers: getCustomers(state, true),
})
// @ts-ignore
export default compose(connect(mapStateToProps))(BbePage)
