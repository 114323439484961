import moment from 'moment'
import { ProjectOverview } from './../aaListSelector'
import {
  getActivitiesTotal,
  getActivitiesTotalAll,
  getActivitiesBetweenDates,
} from './../../activities/selectors'
import { getInvoicesTotal, getInvoicesBetweenDates } from './../../invoices/selectors'
import { getExpensesTotal, getExpensesBetweenDates } from './../../expenses/selectors'
import { ExtendedProject, getCorrection } from './../../projects/selectors' // Neu: Spesen in Zusatzleistungen getspesen
import { Moment } from 'moment'

/**
 * Represents information for aa-list
 * @param project to get AAInfo (Information for "AA-Liste")
 * @param acquisitionList indicated if its an aquisition project
 */
export const getAAInfo = (
  project: ExtendedProject,
  acquisitionList = false,
  dueDate: Moment | null
): ProjectOverview => {
  // Filter (again) everything related on AA-List with dueDate
  const startDate = moment([2010, 0, 1])
  const endDate = dueDate ? moment(dueDate) : moment([2050, 11, 31])
  const expenses = getExpensesBetweenDates(startDate, endDate, project.expenses)
  const activities = getActivitiesBetweenDates(startDate, endDate, project.activities)
  const invoices = getInvoicesBetweenDates(startDate, endDate, project.invoices)

  const amountCHF = acquisitionList
    ? getActivitiesTotalAll(activities)
    : getActivitiesTotal(activities)

  const expensesCHF = getExpensesTotal(expenses)

  // const spesen = getSpesen(amountCHF) // Neu: Spesen in Zusatzleistungen

  const subtotal = amountCHF + expensesCHF // Neu: Spesen in Zusatzleistungen + spesen

  const invoiceCHF = getInvoicesTotal(invoices)

  const correction = dueDate ? getCorrection(dueDate.year().toString())(project) : 0

  const total = subtotal - correction

  // If fixed price, budget must be known. Originally requirement was
  // budget == 0, then take current amount, otherwise take budget
  // New solution (p.fixed_price && p.budget) ?
  const remainBudget = project.fixed_price && project.budget ? project.budget - total : 0

  return {
    customer: project.customer.name,
    identifier: project.identifier,
    name: project.name,
    budget: project.fixed_price && project.budget ? project.budget : amountCHF,
    amountCHF,
    // spesen, // Neu: Spesen in Zusatzleistungen
    expenses: expensesCHF,
    correction,
    subtotal,
    invoice: invoiceCHF,
    total,
    remainBudget,
  }
}
