import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Layout from '../../components/Layout/layout'
import { routes } from '../routes'
import { RootState } from '../../state/store'
import { Company } from '../../state/modules/companies/types'
import { companySelector } from '../../state/modules/companies/index'
import ProfitabilityStats from '../../components/customer/ProfitabilityStats'
import { authenticationSelector } from '../../state/modules/authentication'
import { Typography } from '@material-ui/core'
import CustomerSelection from './CustomerSelection'

type ProfitabilityCustomerProps = {
  customer: Company
  customers: Company[]
  loggedIn: boolean
}

type ComponentProps = ProfitabilityCustomerProps & RouteComponentProps<{ id: string }>

/**
 * Page for Brutto-Betriebseinnahmen
 */
const ProfitablityPage: React.SFC<ComponentProps> = ({
  customers,
  location,
  match,
  customer,
  loggedIn,
}) => {
  if (!loggedIn) {
    return <Redirect to="/" />
  }

  return (
    <Layout routes={routes} location={location}>
      <Typography variant={'display1'}>Kunden-Rentabilität von {customer.name}</Typography>
      <ProfitabilityStats id={parseInt(match.params.id, 10)} customer={customer} />
      <CustomerSelection activeCustomerStat={customer.id} customers={customers} />
    </Layout>
  )
}

const mapStateToProps = (
  state: RootState,
  prop: RouteComponentProps<{ id: string }>
): ProfitabilityCustomerProps => ({
  customer: companySelector.getCompany(state, parseInt(prop.match.params.id, 10)),
  customers: companySelector.getCustomers(state, true),
  loggedIn: authenticationSelector.isAuthenticated(state),
})
// @ts-ignore
export default compose(connect(mapStateToProps))(ProfitablityPage)
