import * as React from 'react'
import { withStyles, WithStyles, Theme, LinearProgress } from '@material-ui/core'
import { compose } from 'recompose'

import { ImportInfo } from '../../../state/modules/comatic/selectors'
import { sharedConfig } from '../../../state/sagas/api/config'

type PublicProps = {
  info: ImportInfo
  requests: number
}

const styles = (theme: Theme) => ({})

type StyleProps = WithStyles<''>

type ComponentProps = StyleProps & PublicProps

const SyncPreview: React.StatelessComponent<ComponentProps> = ({ classes, info, requests }) => {
  return (
    <React.Fragment>
      <p>
        Verbleibende Anfragen: {requests} <br />
        Wird nach {sharedConfig.comaticSyncSteps} Anfragen aktualisiert
      </p>
      <LinearProgress variant={'indeterminate'} color={'primary'} />
    </React.Fragment>
  )
}

export default compose<ComponentProps, PublicProps>(withStyles(styles))(SyncPreview)
