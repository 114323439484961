import * as R from 'ramda'
import { RootState } from '../../store'
import {
  getActivities,
  getActivitiesOfUser,
  getActivitiesTotalHour,
  getCategorizedActivities,
} from '../activities/selectors'
import { getSchedules, getSchedulesTotalHour } from '../schedules/selectors'
import { ExtendedProject, getProjects, isReducedProject } from '../projects/selectors'
import { getFilterYear, getGoals, getVivaUsers } from '../users/selectors'
import { User } from '../users/types'
import { getFilter } from '../general/selectors'
import { StatisticNames } from '../general/types'
import { FilteredState, getFilteredState, ProjectTypeFilter, DataFilter } from './statFilter'
import { getInvoices, getInvoiceShare } from '../invoices/selectors'
import { Activity } from '../activities/types'
import { Schedule } from '../schedules/types'
import { UserGoal } from '../users/types'
import { getExpenses } from '../expenses/selectors'
import { UndefinedNumber, getSumTotal } from '../../../util/ramdaUtil'
import {
  getTargetHoursPerToday,
  getEmployeeTargetDiff,
  getEmployeePercentageDiff,
} from './employeeSelectors'

export type AllEmployeeOverview = {
  user: string
  sortParam: string
  percentageJahresSoll: UndefinedNumber
  projectNormal: UndefinedNumber
  projectReduced: UndefinedNumber

  projectTotal: UndefinedNumber

  invoiceTotalHour: UndefinedNumber
  invoiceTotalAmount: UndefinedNumber
  invoiceTotalAvg: UndefinedNumber

  billedNormalHour: UndefinedNumber
  billedNormalAmount: UndefinedNumber
  billedNormalAvg: UndefinedNumber

  billedReducedHour: UndefinedNumber
  billedReducedAmount: UndefinedNumber
  billedReducedAvg: UndefinedNumber

  newBusinessHour: UndefinedNumber
  customerRelationHour: UndefinedNumber
  marketingHour: UndefinedNumber
  internalHour: UndefinedNumber
  absenceHour: UndefinedNumber

  holidayHour: UndefinedNumber
  sickHour: UndefinedNumber

  nonBillableReduced: UndefinedNumber
  nonBillableNormal: UndefinedNumber
  // total: number
}

const filterSchedule = (schedules: Schedule[]) => (user: User) =>
  R.filter((schedule: Schedule) => schedule.user.id === user.id)(schedules)

const getUserInvoiceInfo = (state: FilteredState, user: User) => {
  const allActivities = R.values(getActivities(state.unfiltered))
  const allInvoices = R.values(getInvoices(state.unfiltered))
  const allExpenses = R.values(getExpenses(state.unfiltered))

  // Todo: Refactor
  const getInvoiceShareOf = (relActivities: Activity[], project: ExtendedProject) => {
    return getInvoiceShare(allInvoices, allActivities, relActivities, allExpenses, project)
  }

  // Activities of User by InvoiceDate
  const initial = {
    billedNormalHour: 0,
    billedNormalAmount: 0,
    billedReducedHour: 0,
    billedReducedAmount: 0,
  }

  return R.reduce((result: typeof initial, project: ExtendedProject) => {
    const activitiesOfUser = getActivitiesOfUser(project.activities, user.id)
    if (isReducedProject(project)) {
      return {
        ...result,
        billedReducedHour: result.billedReducedHour + getActivitiesTotalHour(activitiesOfUser),
        billedReducedAmount:
          result.billedReducedAmount + getInvoiceShareOf(activitiesOfUser, project),
      }
    }
    return {
      ...result,
      billedNormalHour: result.billedNormalHour + getActivitiesTotalHour(activitiesOfUser),
      billedNormalAmount: result.billedNormalAmount + getInvoiceShareOf(activitiesOfUser, project),
    }
  }, initial)(state.filteredByInvoiceDate)
}

const getUserName = (user: User): string => {
  return user.firstname + ' ' + user.lastname
}

const getUserInfo = (state: FilteredState, user: User, goal: UserGoal) => {
  // Get necessary information from state
  const activities = R.values(getActivities(state.filteredState))
  const projects = getProjects(state.filteredState)
  const schedules = R.values(getSchedules(state.filteredState))

  // Match with user
  const activitiesOfUser = getActivitiesOfUser(activities, user.id)
  const userSchedules = filterSchedule(schedules)(user)
  const catActivities = getCategorizedActivities(activitiesOfUser, projects)

  // Get totals
  const projectNormal = getActivitiesTotalHour(catActivities.normalActivities)
  const projectReduced = getActivitiesTotalHour(catActivities.reducedActivities)
  return {
    user: getUserName(user),
    sortParam: user.lastname,
    percentageJahresSoll:
      ((projectNormal + projectReduced) /
        (goal.StundenanzahlNormal + goal.StundenanzahlReduziert)) *
      100,
    projectNormal: getActivitiesTotalHour(catActivities.normalActivities),
    projectReduced: getActivitiesTotalHour(catActivities.reducedActivities),
    projectTotal: projectNormal + projectReduced,

    newBusinessHour: getActivitiesTotalHour(catActivities.newBusinessActivities),
    customerRelationHour: getActivitiesTotalHour(catActivities.customerRelationActivities),
    marketingHour: getActivitiesTotalHour(catActivities.marketingActivities),
    internalHour: getActivitiesTotalHour(catActivities.internalActivities),
    absenceHour: getActivitiesTotalHour(catActivities.absenceActivities),

    holidayHour: getSchedulesTotalHour(userSchedules, 'Urlaub'),
    sickHour: getSchedulesTotalHour(userSchedules, 'Krankheit'),

    nonBillableReduced: getActivitiesTotalHour(catActivities.nonBillableNormal),
    nonBillableNormal: getActivitiesTotalHour(catActivities.nonBillableReduced),
  }
}

const getZeroRow = (): AllEmployeeOverview => {
  return {
    user: '',
    sortParam: '',
    percentageJahresSoll: 0,
    projectNormal: 0,
    projectReduced: 0,
    projectTotal: 0,
    invoiceTotalHour: 0,
    invoiceTotalAmount: 0,
    invoiceTotalAvg: 0,
    billedNormalHour: 0,
    billedNormalAmount: 0,
    billedNormalAvg: 0,
    billedReducedHour: 0,
    billedReducedAmount: 0,
    billedReducedAvg: 0,

    newBusinessHour: 0,
    customerRelationHour: 0,
    marketingHour: 0,
    internalHour: 0,
    absenceHour: 0,

    holidayHour: 0,
    sickHour: 0,

    nonBillableReduced: 0,
    nonBillableNormal: 0,
  }
}

const getEmptyRow = (): AllEmployeeOverview => {
  return {
    user: '',
    sortParam: '',
    percentageJahresSoll: undefined,
    projectTotal: undefined,
    projectNormal: undefined,
    projectReduced: undefined,
    invoiceTotalHour: undefined,
    invoiceTotalAmount: undefined,
    invoiceTotalAvg: undefined,
    billedNormalHour: undefined,
    billedNormalAmount: undefined,
    billedNormalAvg: undefined,
    billedReducedHour: undefined,
    billedReducedAmount: undefined,
    billedReducedAvg: undefined,

    newBusinessHour: undefined,
    customerRelationHour: undefined,
    marketingHour: undefined,
    internalHour: undefined,
    absenceHour: undefined,

    holidayHour: undefined,
    sickHour: undefined,

    nonBillableReduced: undefined,
    nonBillableNormal: undefined,
  }
}

const getUndefinedValues = () => {
  return {
    invoiceTotalHour: undefined,
    billedNormalHour: undefined,
    billedReducedHour: undefined,
    nonBillableReduced: undefined,
    nonBillableNormal: undefined,

    percentageJahresSoll: undefined,
  }
}

const transformGoalToUserGoal = (goal: UserGoal): AllEmployeeOverview => {
  return {
    user: 'Jahres-Soll',
    sortParam: 'ZZZZ',

    projectNormal: goal.StundenanzahlNormal,
    projectReduced: goal.StundenanzahlReduziert,

    projectTotal: goal.StundenanzahlNormal + goal.StundenanzahlReduziert,
    invoiceTotalHour: undefined,
    invoiceTotalAmount:
      goal.StundenanzahlNormal * goal.StundenansatzNormal +
      goal.StundenanzahlReduziert * goal.StundenansatzReduziert,

    invoiceTotalAvg: undefined,
    billedNormalHour: undefined,
    billedReducedHour: undefined,
    billedNormalAvg: undefined,
    billedReducedAvg: undefined,
    billedNormalAmount: goal.StundenanzahlNormal * goal.StundenansatzNormal,
    billedReducedAmount: goal.StundenanzahlReduziert * goal.StundenansatzReduziert,

    newBusinessHour: goal.NewBizzSoll,
    marketingHour: goal.MarketingSoll,
    customerRelationHour: goal.KundenpflegeSoll,
    internalHour: goal.InternesSoll, // undefined, // Not defined -> goal.InternesSoll,
    absenceHour: undefined, // goal.AbwesenheitenSoll,

    holidayHour: goal.FerienSoll,
    sickHour: undefined, // goal.AbsenzenSoll,

    nonBillableReduced: undefined,
    nonBillableNormal: undefined,

    percentageJahresSoll: undefined,
  }
}

const getAdditionalInfo = (
  state: FilteredState,
  overview: AllEmployeeOverview[],
  users: User[],
  filter: DataFilter
): AllEmployeeOverview[] => {
  // Returns the total per column
  const getTotal = R.reduce((result: AllEmployeeOverview, overview: AllEmployeeOverview) => {
    const keys = R.keys(overview)
    return R.reduce((sum: AllEmployeeOverview, key: string) => {
      return {
        ...sum,
        // @ts-ignore
        [key]: getSumTotal(result[key] + overview[key]),
      }
    }, getZeroRow())(keys)
  }, getZeroRow())

  // Total of Soll-per-heute
  const additionalInfos = users.map((user) => {
    // Total per user
    const totalOfUser = overview.reduce<AllEmployeeOverview>((result, o) => {
      if (o.user === getUserName(user)) {
        return o
      }
      return result
    }, getEmptyRow())

    const goal = getGoals(getFilterYear(filter), user)

    // Transform to AllEmployeeOverview
    const userGoal: AllEmployeeOverview = transformGoalToUserGoal(goal)

    // Soll per heute
    const todayTotal = getTargetHoursPerToday(userGoal, filter, user)

    // Abweichung
    const diffTodayTotal = getEmployeeTargetDiff(todayTotal, totalOfUser)

    // Abweichung %
    const diffTodayPercentage = getEmployeePercentageDiff(todayTotal, diffTodayTotal)

    return {
      totalOfUser, // Netto per user
      userGoal, // Jahres-Soll
      todayTotal, // Soll-per-heute
      diffTodayTotal, // Abweichung
      diffTodayPercentage, // Abweichung %
    }
  })

  // Total of
  const tmpTotalOfUsers = getTotal(additionalInfos.map((a) => a.totalOfUser))
  const totalOfUsers: AllEmployeeOverview = {
    ...tmpTotalOfUsers,
    invoiceTotalAvg: tmpTotalOfUsers.invoiceTotalAmount! / tmpTotalOfUsers.invoiceTotalHour!,
    billedNormalAvg: tmpTotalOfUsers.billedNormalAmount! / tmpTotalOfUsers.billedNormalHour!,
    billedReducedAvg: tmpTotalOfUsers.billedReducedAmount! / tmpTotalOfUsers.billedReducedHour!,
    user: 'Total',
    sortParam: 'ZZZ',
    percentageJahresSoll: undefined,
  }

  const totals = getTotal(additionalInfos.map((a) => a.userGoal))
  const totalOfUserGoals: AllEmployeeOverview = {
    ...totals,
    invoiceTotalAvg: totals.invoiceTotalAmount! / totals.projectTotal!,
    billedNormalAvg: totals.billedNormalAmount! / totals.projectNormal!,
    billedReducedAvg: totals.billedReducedAmount! / totals.projectReduced!,
    user: 'Jahres-Soll',
    sortParam: 'ZZZZ',
    ...getUndefinedValues(),
  }

  const totalOftodayTotal: AllEmployeeOverview = {
    ...getTotal(additionalInfos.map((a) => a.todayTotal)),
    // Soll per heute === Jahres-Soll for AVG case
    invoiceTotalAvg: totals.invoiceTotalAmount! / totals.projectTotal!,
    billedNormalAvg: totals.billedNormalAmount! / totals.projectNormal!,
    billedReducedAvg: totals.billedReducedAmount! / totals.projectReduced!,
    user: 'Soll per heute',
    sortParam: 'ZZZZZ',
    ...getUndefinedValues(),
  }

  const tmpTotalDiffTodayTotal = getTotal(additionalInfos.map((a) => a.diffTodayTotal))
  const totalDiffTodayTotal: AllEmployeeOverview = {
    ...tmpTotalDiffTodayTotal,
    invoiceTotalAvg: totalOfUsers.invoiceTotalAvg! - totalOftodayTotal.invoiceTotalAvg!,
    billedNormalAvg: totalOfUsers.billedNormalAvg! - totalOftodayTotal.billedNormalAvg!,
    billedReducedAvg: totalOfUsers.billedReducedAvg! - totalOftodayTotal.billedReducedAvg!,
    user: 'Abweichung',
    sortParam: 'ZZZZZZ',
    ...getUndefinedValues(),
  }

  const totalDiffTodayPercentage = {
    ...getEmployeePercentageDiff(totalOftodayTotal, totalDiffTodayTotal),
    user: 'Abweichung %',
    sortParm: 'ZZZZZZZ',
    ...getUndefinedValues(),
  }

  return [
    getEmptyRow(),
    // total,
    totalOfUsers,
    getEmptyRow(),
    totalOfUserGoals,
    totalOftodayTotal,
    totalDiffTodayTotal,
    totalDiffTodayPercentage,
  ]
}

export const getData = (rootState: RootState): AllEmployeeOverview[] => {
  const filter = getFilter(rootState, StatisticNames.employee)

  const allStates = getFilteredState(rootState, {
    ...filter,
    projectType: ProjectTypeFilter.archived,
  })
  const state = allStates.filteredState

  const users = getVivaUsers(state)

  const allEmployeeOverviews: AllEmployeeOverview[] = users.map((user: User, key: number) => {
    const goal = getGoals(getFilterYear(filter), user)
    // Activities of User in business year
    const info = getUserInfo(allStates, user, goal)
    const invoiceInfo = getUserInvoiceInfo(allStates, user)

    return {
      ...info,
      ...invoiceInfo,
      invoiceTotalHour: invoiceInfo.billedNormalHour + invoiceInfo.billedReducedHour,
      invoiceTotalAmount: invoiceInfo.billedNormalAmount + invoiceInfo.billedReducedAmount,
      invoiceTotalAvg:
        (invoiceInfo.billedNormalAmount + invoiceInfo.billedReducedAmount) /
        (invoiceInfo.billedNormalHour + invoiceInfo.billedReducedHour),
      billedNormalAvg: invoiceInfo.billedNormalAmount / invoiceInfo.billedNormalHour,
      billedReducedAvg: invoiceInfo.billedReducedAmount / invoiceInfo.billedReducedHour,
    }
  })

  // @ts-ignore
  const bySortParam = R.ascend(R.prop('sortParam'))

  const additionalInfo = getAdditionalInfo(allStates, allEmployeeOverviews, users, filter)

  return [...R.sort<AllEmployeeOverview>(bySortParam)(allEmployeeOverviews), ...additionalInfo]
}
