import { Action } from 'redux'
import { Buffer, buffers } from 'redux-saga'
import { actionChannel, call, put, select, take, delay } from 'redux-saga/effects'
// import { SagaActionConstants } from '../constants';
import { getConfig } from './config'
import { TypeKeys as generalTypes } from '../../modules/general/constants'
import { postPutData } from './api'
import { getToken } from '../../modules/authentication/selector'
// import {delay} from "./requestQueue";

// Request Queued with a delay of 1s (see config)
export function* watchImport() {
  const config = getConfig()
  const answerData: any[] = []

  // 1- Create a channel for request actions
  const buffer: Buffer<Action<{}>> = buffers.expanding(1500)
  const postPutChannel = yield actionChannel(generalTypes.POST_PUT_DATA, buffer)
  while (true) {
    // 2- take from the channel
    const { payload } = yield take(postPutChannel)

    // We're using a blocking call to call the request
    const token = yield select(getToken)

    const result = yield call(postPutData, payload, token)

    // Add data for future update
    answerData.push(result.data)

    // Every 20 requests or end of buffer add Data
    // Reason: Performance optimization
    if (answerData.length === config.comaticSyncSteps || buffer.isEmpty()) {
      // Update store (extensive operation)
      yield put({
        type: generalTypes.ANSWER_DATA,
        payload: {
          data: answerData,
          resource: payload.resource,
        },
      })

      // Empty array
      answerData.length = 0
    }

    // delay
    yield delay(config.throttle)
  }
}
