// import * as R from 'ramda';
import { RootState } from '../../store'
// import { Contact } from './index'
// import { getProjects } from '../projects/selectors'
// import {Project} from "../projects";
import {ContactList} from "./types";


export const getContacts = (state: RootState): ContactList => {
  return state.contacts.detailsById
}
/*
const getCompaniesOfProject = (companies: ContactList) => (project: Project): Contact => companies[project.customer.id];

const uniqCompany: (companies: Contact[]) => Contact[] = R.uniqWith(
  (c: Contact, b: Contact) => c.id === b.id,
);

const sortCompany: (companies: Contact[]) => Contact[] = R.sortBy(
  R.prop('name')
);

export const getCustomers = (state: RootState): Contact[] => {
  const companies = getCompanies(state);
  const projects = getProjects(state);

  const getCompanyOfProject = getCompaniesOfProject(companies);

  const customers: ContactList = R.map(
    getCompanyOfProject
  )(projects);

  return R.pipe(
    uniqCompany,
    sortCompany
  )(R.values(customers));
}

export const getCompany = (state: RootState, id: number): Contact => {
  return getCompanies(state)[id];
}
*/
export default {
  getContacts
}
