import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Layout from '../../components/Layout/layout'
import { routes } from '../routes'
import { RootState } from '../../state/store'
import { Company } from '../../state/modules/companies/types'
import { Contact } from '../../state/modules/contacts/types'
import {
  getAdvertizerCompaniesAdditional,
  getAdvertizerContactsAdditional,
} from '../../state/modules/comatic/selectorsAdvertizer'
import { isAuthenticated } from '../../state/modules/authentication/selector'
import { handleAdvertizerImportFile } from '../../util/import'
import {
  addCompanies as addCompaniesToStore,
  addContacts as addContactsToStore,
  removeCompanies as removeCompaniesFromStore,
  removeContacts as removeContactsFromStore,
  syncAdvertizerCompanies,
  syncAdvertizerContacts,
} from '../../state/modules/comatic/actions'
import ReactTable from 'react-table-6'
import { ColumnConfiguration, Format, getColumns } from '../../util/table'
import { Typography } from '@material-ui/core'

type SyncPageProps = {
  companies: Company[]
  contacts: Contact[]
  loggedIn: boolean
  addCompanies: (companies: Company[]) => void
  addContacts: (contacts: Contact[]) => void
  removeCompanies: () => void
  removeContacts: () => void
  syncCompanies: () => void
  syncContacts: () => void
}

type ComponentProps = SyncPageProps & RouteComponentProps<any>

const getHandleFunction = (
  entity: 'company' | 'contact',
  callback: (companyOrContact: Company[] | Contact[]) => void
) => (e: React.ChangeEvent<HTMLInputElement>) => {
  const files = e.target.files

  handleAdvertizerImportFile(files, entity, callback)
}

const CustomerPage: React.SFC<ComponentProps> = ({
  location,
  match,
  loggedIn,
  addCompanies,
  addContacts,
  syncCompanies,
  syncContacts,
  removeCompanies,
  removeContacts,
  companies,
  contacts,
}) => {
  if (!loggedIn) {
    return <Redirect to="/" />
  }

  const columnConfigCompany: ColumnConfiguration[] = [
    { id: 'syncState', label: 'Sync', format: Format.import },
    {
      id: 'custom_properties',
      label: 'AdvertizerId',
      accessor: (data: Company) => data.custom_properties.AdvertizerId,
      format: Format.import,
    },
    { id: 'id', label: 'id', format: Format.import },
    { id: 'type', label: 'Typ', format: Format.import },
    { id: 'name', label: 'Name', format: Format.import },
    { id: 'website', label: 'Ŵebseite', format: Format.import },
    { id: 'phone', label: 'Telefon', format: Format.import },
    // { id: 'fax', label: 'Fax', format: Format.import },
    { id: 'address', label: 'Adresse', format: Format.import },
    { id: 'labels', label: 'Labels', format: Format.import },
    { id: 'info', label: 'Info', format: Format.import },
    // for customers
    { id: 'currency', label: 'Währung', format: Format.import },
  ]
  const columnsCompany = getColumns(columnConfigCompany)

  const columnConfigContact: ColumnConfiguration[] = [
    { id: 'syncState', label: 'Sync', format: Format.import },
    {
      id: 'custom_properties',
      label: 'AdvertizerId',
      accessor: (data: Contact) => data.custom_properties.AdvertizerId,
      format: Format.import,
    },
    {
      id: 'advertizerParentId',
      label: 'AdvertizerParentId',
      accessor: (data: Contact) => data.custom_properties.AdvertizerParentId,
      format: Format.import,
    },

    { id: 'firstname', label: 'Vorname', format: Format.import },
    { id: 'lastname', label: 'Nachname', format: Format.import },
    { id: 'gender', label: 'Geschlecht', format: Format.import },
    { id: 'customer_id', label: 'KundenNummer', format: Format.import },
    // { id: "organization_id",  label: "FirmenNummer" }, oder organization_id – 123 (ID eines Kunden/einer Firma)
    { id: 'title', label: 'Titel', format: Format.import },
    { id: 'job_position', label: 'Job Position', format: Format.import },
    { id: 'mobile_phone', label: 'Mobile', format: Format.import },
    // { id: "work_fax", label: "Fax", format: Format.import },
    { id: 'work_phone', label: 'Telefon', format: Format.import },
    { id: 'work_email', label: 'E-Mail', format: Format.import },
    { id: 'work_address', label: 'Adresse', format: Format.import },
    { id: 'home_email', label: 'E-Mail Home', format: Format.import },
    { id: 'home_address', label: 'Adresse Home', format: Format.import },
    { id: 'birthday', label: 'Geburtstag', format: Format.import },
    { id: 'info', label: 'Info', format: Format.import },
    { id: 'tags', label: 'Labels', format: Format.import },
  ]
  const columnsContact = getColumns(columnConfigContact)

  return (
    <Layout routes={routes} location={location}>
      <Typography variant={'display1'}>Synchronisation Advertizer</Typography>
      <label htmlFor={'companyFile'}>Advertizer Firmen</label>
      <input
        name="companyFile"
        type="file"
        onChange={
          // @ts-ignore
          getHandleFunction('company', addCompanies)
        }
      />
      <label htmlFor={'contactFile'}>Advertizer Kontakte</label>
      <input
        name="contactFile"
        type="file"
        onChange={
          // @ts-ignore
          getHandleFunction('contact', addContacts)
        }
      />

      <h2>Firmen</h2>
      <ReactTable data={companies} columns={columnsCompany} />
      <button onClick={removeCompanies}>Ablehnen</button>
      <br />
      <button onClick={syncCompanies}>Annehmen</button>
      <br />
      <h2>Kontakte</h2>
      <ReactTable data={contacts} columns={columnsContact} />
      <button onClick={removeContacts}>Ablehnen</button>
      <br />
      <button onClick={syncContacts}>Annehmen</button>
    </Layout>
  )
}

const mapStateToProps = (state: RootState): Partial<SyncPageProps> => ({
  companies: getAdvertizerCompaniesAdditional(state),
  contacts: getAdvertizerContactsAdditional(state),
  loggedIn: isAuthenticated(state),
})

const mapDispatchToProps: Partial<SyncPageProps> = {
  addContacts: addContactsToStore,
  addCompanies: addCompaniesToStore,
  removeContacts: removeContactsFromStore,
  removeCompanies: removeCompaniesFromStore,
  syncCompanies: syncAdvertizerCompanies,
  syncContacts: syncAdvertizerContacts,
}
// @ts-ignore
export default compose(connect(mapStateToProps, mapDispatchToProps))(CustomerPage)
