import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { RootState } from '../../state/store'
import { Company } from '../../state/modules/companies/types'
import {
  getComaticCompaniesAdditional,
  getComaticCompaniesDeleted,
  getComaticStep,
  getImportInfo,
  getRequestCount,
  ImportInfo,
} from '../../state/modules/comatic/selectors'
import { isAuthenticated } from '../../state/modules/authentication/selector'
import { handleImportFile } from '../../util/import'
import {
  addCompanies as addCompaniesToStore,
  removeCompanies as removeCompaniesFromStore,
  skipComaticCompanies,
  syncComaticCompanies,
} from '../../state/modules/comatic/actions'
import CustomReactTable from '../CustomTable'
import { ColumnConfiguration, Format, getColumns } from '../../util/table'
import {
  Badge,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import UploadButton from './elements/UploadButton'
import SyncPreview from './elements/SyncPreview'
import { ComaticStep } from '../../state/modules/comatic/types'
import SyncState from './elements/SyncState'
// import FileUpload from "@material-ui/icons/FileUpload";

type PublicProps = {}

type InjectedProps = {
  companies: Company[]
  nonExistantCompanies: Company[]
  info: ImportInfo
  loggedIn: boolean
  requests: number
  step: ComaticStep
  addCompanies: (companies: Company[]) => void
  removeCompanies: () => void
  syncCompanies: (info: ImportInfo) => void
  skipCompanyImport: () => void
}

type ComponentProps = InjectedProps &
  RouteComponentProps<any> &
  WithStyles<'upload' | 'button' | 'rightIcon'>

const getHandleFunction = (
  entity: 'company' | 'contact',
  callback: (companyOrContact: any) => void
) => (e: React.ChangeEvent<HTMLInputElement>) => {
  const files = e.target.files

  handleImportFile(files, entity, callback)
}

const styles = (theme: Theme) => ({
  hide: {
    display: 'none',
  },
  button: {
    margin: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
})

const CompanySync: React.SFC<ComponentProps> = ({
  classes,
  step,
  requests,
  info,
  location,
  match,
  loggedIn,
  nonExistantCompanies,
  addCompanies,
  skipCompanyImport,
  syncCompanies,
  removeCompanies,
  companies,
}) => {
  const columnConfigCompany: ColumnConfiguration[] = [
    { id: 'syncState', label: 'Sync', format: Format.import },
    {
      id: 'custom_properties',
      label: 'Comatic-Id',
      accessor: (data: Company) => data.custom_properties.ComaticId,
      format: Format.import,
    },
    { id: 'id', label: 'Moco-Id', format: Format.import },
    { id: 'type', label: 'Typ', format: Format.import },
    { id: 'name', label: 'Name', format: Format.import },
    { id: 'website', label: 'Webseite', format: Format.import },
    { id: 'phone', label: 'Telefon', format: Format.import },
    // { id: 'fax', label: 'Fax', format: Format.import },
    { id: 'address', label: 'Adresse', format: Format.import },
    { id: 'tags', label: 'Labels', format: Format.import },
    { id: 'info', label: 'Info', format: Format.import },
    // for customers
    { id: 'currency', label: 'Währung', format: Format.import },
  ]
  const columnsCompany = getColumns(columnConfigCompany)

  const fileSelection = (
    <React.Fragment>
      <Typography variant={'headline'}>Auswahl Comatic Firmen</Typography>

      <Grid container>
        <Grid item xs={12}>
          <UploadButton onChange={getHandleFunction('company', addCompanies)}>Firmen</UploadButton>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 200 }}>
          <Button variant={'contained'} color={'default'} onClick={skipCompanyImport}>
            Firmen-Import Überspringen
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )

  const sync = () => syncCompanies(info)

  const dataOverview = (
    <React.Fragment>
      <Typography variant={'headline'}>Import-Vorschau Firmen</Typography>
      <CustomReactTable data={companies} columns={columnsCompany} />
      <Grid container>
        <Grid item xs={12}>
          <SyncPreview info={info} />
        </Grid>
        <Grid item xs={1}>
          <Button variant={'contained'} color={'default'} onClick={removeCompanies}>
            Ablehnen
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button variant={'contained'} color={'primary'} onClick={sync}>
            Annehmen
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        style={{ display: nonExistantCompanies.length > 0 ? 'block' : 'none', paddingTop: '100px' }}
      >
        <Grid item xs={6}>
          <Badge color="error" max={9999} badgeContent={nonExistantCompanies.length}>
            <Typography variant={'headline'}>
              Nicht in Import vorhanden - Manuell bearbeiten
            </Typography>
          </Badge>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Firmen Name</TableCell>
                <TableCell numeric>ComaticId</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nonExistantCompanies.map((company, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{company.name}</TableCell>
                    <TableCell numeric>{company.custom_properties.ComaticId}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </React.Fragment>
  )

  const progress = (
    <React.Fragment>
      <Typography variant={'headline'}>Änderungen werden durchgeführt</Typography>
      <SyncPreview info={info} />
      <SyncState info={info} requests={requests} />
    </React.Fragment>
  )

  return (
    <React.Fragment>
      {step === ComaticStep.companyFileSelection
        ? fileSelection
        : step === ComaticStep.companyOverview
        ? dataOverview
        : step === ComaticStep.syncCompanies
        ? progress
        : null}
    </React.Fragment>
  )
}

const mapStateToProps = (state: RootState) => ({
  companies: getComaticCompaniesAdditional(state),
  info: getImportInfo(state),
  step: getComaticStep(state),
  requests: getRequestCount(state),
  loggedIn: isAuthenticated(state),
  nonExistantCompanies: getComaticCompaniesDeleted(state),
})

const mapDispatchToProps = {
  addCompanies: addCompaniesToStore,
  removeCompanies: removeCompaniesFromStore,
  syncCompanies: syncComaticCompanies,
  skipCompanyImport: skipComaticCompanies,
}

export default compose<ComponentProps, PublicProps>(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CompanySync)
