import * as R from 'ramda'
import moment from 'moment'
import { RootState } from '../../store'
import {
  getProjects,
  hasLabels,
  mapToExtendedProjects,
  ExtendedProject,
  isInvoiceBetweenDates,
  isArchived,
  isActive,
} from '../projects/selectors'
import { Moment } from 'moment'
import { getActivities, getActivitiesBetweenDates } from '../activities/selectors'
import {
  getInvoiceMaxDate,
  getInvoices,
  getInvoicesTotal,
  getMostProgressedStatus,
} from '../invoices/selectors'
import { getCompanies } from '../companies/selectors'
import { getExpenses } from '../expenses/selectors'
import { Activity } from '../activities/types'
import { getOffers } from '../offers/selectors'
import { getSchedules, getSchedulesBetweenDates } from '../schedules/selectors'

/* Performance optimization, do not use reduce for performance reason */
const convertIdList = <T extends { id: number }>(
  entities: T[]
): { detailsById: { [key: number]: T } } => {
  const result = { detailsById: {} }
  entities.forEach((entity: T) => {
    // @ts-ignore
    result.detailsById[entity.id] = entity
  })
  return result

  /* return {
    detailsById: R.reduce((result, entity: T) => {
      return {
        ...result,
        [entity.id]: entity,
      }
    }, {})(entities),
  } */
}

export enum ProjectTypeFilter {
  archived = 'archived',
  active = 'active',
  all = 'all',
}

export type FilteredState = {
  filteredState: RootState
  filteredByLabelState: RootState
  filteredByInvoiceDate: ExtendedProject[]
  unfiltered: RootState
}

export type DataFilter = {
  labels: string[]
  startDate: Moment | null
  endDate: Moment | null
  projectType: ProjectTypeFilter
}

export const filterByInvoiceDate = (startDate: Moment, endDate: Moment) => (
  projects: ExtendedProject[]
): ExtendedProject[] => {
  return R.filter((project: ExtendedProject) => {
    return R.allPass([isInvoiceBetweenDates(startDate, endDate)])(project)
  })(projects)
}

const filterProjectsByLabels = (labels: string[]) => (
  projects: ExtendedProject[]
): ExtendedProject[] => {
  return R.filter((project: ExtendedProject) => {
    return R.allPass([hasLabels(labels)])(project)
  })(projects)
}

const filterByProjectType = (projectType: ProjectTypeFilter) => (
  projects: ExtendedProject[]
): ExtendedProject[] => {
  switch (projectType) {
    case 'archived': {
      return R.filter<ExtendedProject>(isArchived)(projects)
    }
    case 'active': {
      return R.filter<ExtendedProject>(isActive)(projects)
    }
    case 'all': {
      return projects
    }
    default: {
      return projects
    }
  }
}

const groupInvoicesToMaxDate = (projects: ExtendedProject[]): ExtendedProject[] => {
  return R.reduce((projectList: ExtendedProject[], project: ExtendedProject) => {
    if (project.invoices && project.invoices.length > 0) {
      const total = getInvoicesTotal(project.invoices)
      const date = getInvoiceMaxDate(project.invoices)
      const status = getMostProgressedStatus(project.invoices)
      return [
        ...projectList,
        {
          ...project,
          // Map invoices to one total invoice for easier filtering
          invoices: [
            {
              ...project.invoices[0],
              status,
              date: date.format('YYYY-MM-DD'),
              net_total: total,
            },
          ],
        },
      ]
    }
    return [...projectList, project]
  }, [])(projects)
}

export const getFilteredState = (state: RootState, filter: DataFilter): FilteredState => {
  // Variables, helpers
  const projects = getProjects(state)
  const activities = getActivities(state)
  const companies = getCompanies(state)
  const invoices = getInvoices(state)
  const expenses = getExpenses(state)
  const offers = getOffers(state)
  const schedules = getSchedules(state)

  const extendedProjects = mapToExtendedProjects(
    R.values(projects),
    companies,
    R.values(activities),
    R.values(expenses),
    R.values(invoices),
    R.values(offers)
  )

  const startDate = filter.startDate ? filter.startDate : moment('2000-01-01')
  const endDate = filter.endDate ? filter.endDate : moment('2099-01-01')
  const labels = filter.labels
  const projectType = filter.projectType

  const filteredByInvoiceDate = R.compose(
    filterProjectsByLabels(labels),
    filterByProjectType(projectType), // only archived projects
    filterByInvoiceDate(startDate, endDate),
    groupInvoicesToMaxDate
  )(extendedProjects)

  const filteredProjectsByLabel = R.compose(
    filterProjectsByLabels(labels)
    // All projects, maybe add additional filter state
    // filterByProjectType(projectType),
  )(extendedProjects)

  const filteredActivitiesByLabel = R.reduce(
    (filteredActivities: Activity[], project: ExtendedProject) => {
      return R.concat(filteredActivities, project.activities)
    },
    []
  )(filteredProjectsByLabel)

  const filteredActivitiesByLabelAndDate = R.compose(
    R.curry(getActivitiesBetweenDates)(startDate, endDate)
  )(filteredActivitiesByLabel)

  const filteredSchedules = getSchedulesBetweenDates(startDate, endDate, R.values(schedules))

  const filteredState: RootState = {
    ...state,
    // Activities filtered by date
    activities: convertIdList(filteredActivitiesByLabelAndDate),
    // Projects filtered by label and projectType (archived, active etc.) in filtered state
    projects: convertIdList(filteredProjectsByLabel),

    // schedules filtered by date
    schedules: convertIdList(filteredSchedules),
    // Todo: Check what needs to be filtered for all stats
    // expenses not filtered?
    // invoices not filtered
    // companies not filtered
    //
  }

  const filteredByLabelState: RootState = {
    ...state,
    activities: convertIdList(filteredActivitiesByLabel),
    projects: convertIdList(filteredProjectsByLabel),
  }

  // Remap activities
  return {
    filteredState,
    filteredByLabelState,
    filteredByInvoiceDate,
    unfiltered: state,
  }
}
