import * as React from 'react'
import { Button, Theme, withStyles, WithStyles } from '@material-ui/core'
import { CloudUpload } from '@material-ui/icons'
import { compose } from 'recompose'

const styles = (theme: Theme) => ({
  button: {
    margin: 0,
  },
  icon: {
    margin: 0,
  },
})

type PublicProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

type ComponentProps = PublicProps & WithStyles<'icon' | 'button'>

const UploadButton: React.StatelessComponent<ComponentProps> = ({
  classes,
  children,
  onChange,
}) => {
  return (
    <React.Fragment>
      <input
        accept=".xls, .xlsx, .csv"
        id="file"
        type="file"
        style={{
          display: 'none',
        }}
        onChange={onChange}
      />
      <label htmlFor="file">
        <Button variant="contained" component="span" className={classes.button}>
          {children}
          <CloudUpload className={classes.icon} />
        </Button>
      </label>
    </React.Fragment>
  )
}

export default compose<ComponentProps, PublicProps>(withStyles(styles))(UploadButton)
