import * as R from "ramda";

export const ascendComparatorString = R.comparator((a: string, b: string) => a < b);
export const ascendComparatorNum = R.comparator((a: number, b: number) => a < b);

export type UndefinedNumber = number | undefined;

export const getSumTotal = (firstValue: UndefinedNumber, ...values: UndefinedNumber[]): number => {
  const initial = firstValue ? firstValue : 0;
  return R.reduce(
    (total: number, value: UndefinedNumber) => {
      if (value) {
        return total+value;
      }
      return total;
    }, initial)(values);
}
