import * as R from 'ramda'
import moment from 'moment'
import { RootState } from '../../store'
import { Contact } from '../contacts/types'
import { Company } from '../companies/types'
import { getCompanies, getCompanyOfComaticId } from '../companies/selectors'
import { getContacts } from '../contacts/selectors'
import { AdditionalCompany, AdditionalContact, AdditionalState, ComaticStep } from './types'
import { SyncContext } from '../general/types'

export const getComaticCompanies = (state: RootState): Company[] => {
  return state.comatic.companies
}

export const getComaticContacts = (state: RootState): Contact[] => {
  return state.comatic.contacts
}

export const compareComaticCompany = (c1: Company, c2: Company): boolean => {
  if (c1.tags.length > 1) {
    let test = 1
    console.log(test)
  }

  return R.allPass([
    R.eqProps('name'),
    R.eqBy(
      R.pipe(
        // @ts-ignore
        R.prop('website'),
        R.defaultTo('')
      )
    ),
    R.eqProps('phone'),
    // Removed, no fax needed
    // R.eqProps('fax'),

    // Tags/Labels are merged together
    R.eqProps('tags'),
    // R.eqProps('labels'),
    R.eqBy(
      R.pipe(
        // @ts-ignore
        R.prop('address'),
        R.replace(/\r\n/g, '\n')
      )
    ),
    // R.eqProps('address'),
  ])(c1, c2)
}

export const getRequestCount = (state: RootState): number => {
  return state.comatic.comaticRequests
}

export type ImportInfo = {
  add: number
  update: number
  same: number
}

export const getImportInfo = (state: RootState): ImportInfo => {
  const companies = getComaticCompaniesAdditional(state)
  return R.reduce(
    (info: ImportInfo, company: AdditionalCompany) => {
      switch (company.syncState) {
        case 'Neu': {
          return {
            ...info,
            add: info.add + 1,
          }
        }
        case 'Identisch': {
          return {
            ...info,
            same: info.same + 1,
          }
        }
        case 'Update': {
          return {
            ...info,
            update: info.update + 1,
          }
        }
        default: {
          return info
        }
      }
    },
    {
      add: 0,
      update: 0,
      same: 0,
    }
  )(companies)
}

export const getImportInfoContacts = (state: RootState): ImportInfo => {
  const contacts = getComaticContactsAdditional(state)
  return R.reduce(
    (info: ImportInfo, contact: AdditionalContact) => {
      switch (contact.syncState) {
        case 'Neu': {
          return {
            ...info,
            add: info.add + 1,
          }
        }
        case 'Identisch': {
          return {
            ...info,
            same: info.same + 1,
          }
        }
        case 'Update': {
          return {
            ...info,
            update: info.update + 1,
          }
        }
        default: {
          return info
        }
      }
    },
    {
      add: 0,
      update: 0,
      same: 0,
    }
  )(contacts)
}

export const compareComaticId = <T>(c1: T, c2: T): boolean => {
  const getId = R.path(['custom_properties', 'ComaticId'])
  return R.equals(getId(c1), getId(c2))
}

export const getComaticCompaniesAdditional = (state: RootState): AdditionalCompany[] => {
  const comaticCompanies = getComaticCompanies(state)
  const companies = R.values(getCompanies(state))
  const additionalCompanies: AdditionalCompany[] = R.map((comaticCompany: Company) => {
    // Add state to store
    let syncState: AdditionalState = 'Neu'
    let id = 0
    const mergedLabels = comaticCompany.tags ? ['Comatic', ...comaticCompany.tags] : ['Comatic']
    let tags: string[] = ['Comatic']

    // Update?
    const existing: Company[] = R.filter((company: Company) =>
      compareComaticId(comaticCompany, company)
    )(companies)
    if (existing && existing.length > 0) {
      syncState = 'Update'

      // Add existing labels, tags
      if (existing[0].tags) {
        mergedLabels.push(...existing[0].tags)
      }
      // uniq and sort labels
      tags = R.uniq(R.sort((a, b) => a.localeCompare(b), mergedLabels))

      const isIdentical = compareComaticCompany(
        {
          ...comaticCompany,
          tags,
          labels: tags,
        },
        existing[0]
      )
      if (isIdentical) {
        syncState = 'Identisch'
      }
      // Set ID
      id = R.propOr(0, 'id')(existing[0])
    }

    return {
      ...comaticCompany,
      id,
      syncState,
      labels: tags,
      tags,
    }
  })(comaticCompanies)
  return additionalCompanies
}

export const compareComaticContact = (c1: Contact, c2: Contact): boolean => {
  return R.allPass([
    R.eqProps('firstname'),
    R.eqProps('lastname'),
    R.eqProps('gender'),
    R.eqProps('title'),
    R.eqProps('job_position'),
    R.eqProps('mobile_phone'),

    // R.eqProps('work_fax'),
    R.eqProps('work_phone'),
    R.eqProps('work_email'),
    R.eqProps('organization_id'),

    R.eqProps('tags'),
    // R.eqProps('tags'), wird nicht mit /contacts/people definiert, daher nicht prüfen. Wird in Moco weiter verwaltet
    // @ts-ignore
    R.eqBy(R.pipe(R.prop('work_address'), R.replace(/\r\n/g, '\n'))),
    // R.eqProps('address'),
  ])(c1, c2)
}

export const getComaticContactsAdditional = (state: RootState): AdditionalContact[] => {
  const comaticContacts = getComaticContacts(state)
  const contacts = R.values(getContacts(state))
  const additionalContacts: AdditionalContact[] = R.map((comaticContact: Contact) => {
    // Add state to store
    let mocoCompany
    let syncState: AdditionalState = 'Neu'
    let id = 0
    const mergedLabels = comaticContact.tags ? ['Comatic', ...comaticContact.tags] : ['Comatic']
    let organizationId
    let tags: string[] = ['Comatic']
    // Update?
    const existing: Contact[] = R.filter((company: Contact) =>
      compareComaticId(comaticContact, company)
    )(contacts)
    if (existing && existing.length > 0) {
      syncState = 'Update'

      // Merge labels and tags of contact
      if (existing[0].tags) {
        mergedLabels.push(...existing[0].tags)
      }
      // uniq and sort labels
      tags = R.uniq(R.sort((a, b) => a.localeCompare(b), mergedLabels))

      const isIdentical = compareComaticContact(
        {
          ...comaticContact,
          tags,
        },
        existing[0]
      )
      if (isIdentical) {
        syncState = 'Identisch'
      }
      // Set ID
      id = R.propOr(0, 'id')(existing[0])
      if (existing[0].company) {
        mocoCompany = existing[0].company
      }
    }
    // Get Company from ComaticId
    if (comaticContact.custom_properties.ComaticParentId) {
      const company = getCompanyOfComaticId(state, comaticContact.custom_properties.ComaticParentId)
      if (company) {
        organizationId = company.id ? company.id : -1
      }
    }

    return {
      ...comaticContact,
      id,
      customer_id: organizationId,
      syncState,
      company: mocoCompany,
      tags: tags,
    }
  })(comaticContacts)

  return additionalContacts
}

export const getComaticCompaniesDeleted = (state: RootState) => {
  const allComaticCompanies = R.values(getCompanies(state))
  const allComaticImport = R.values(getComaticCompanies(state))

  return R.filter<Company>(company => {
    // Check if it is a comatic contact
    if (R.path(['custom_properties', 'ComaticId'])(company)) {
      // Check if the contact still exists in the import
      const result = R.find(
        R.pathEq(['custom_properties', 'ComaticId'], company.custom_properties.ComaticId)
      )(allComaticImport)
      if (!result) {
        return true
      }
    }
    return false
  })(allComaticCompanies)
}

export const getComaticContactsDeleted = (state: RootState) => {
  const allComaticContacts = R.values(getContacts(state))
  const allComaticImport = R.values(getComaticContacts(state))

  return R.filter<Contact>(contact => {
    // Check if it is a comatic contact
    if (R.path(['custom_properties', 'ComaticId'])(contact)) {
      // Check if the contact still exists in the import
      const result = R.find(
        R.pathEq(['custom_properties', 'ComaticId'], contact.custom_properties.ComaticId)
      )(allComaticImport)
      if (!result) {
        return true
      }
    }
    return false
  })(allComaticContacts)
}

export const getComaticStep = (state: RootState, currentDate = new Date()): ComaticStep => {
  if (
    moment(currentDate).isAfter(moment(state.general.lastSyncData, 'DD.MM.YYYY'), 'day') ||
    state.general.syncContext !== SyncContext.complete
  ) {
    return ComaticStep.outOfSync
  }
  return state.comatic.comaticStep
}

export default {
  getComaticCompanies,
  getComaticContacts,
  getComaticCompaniesAdditional,
  getComaticContactAdditional: getComaticContactsAdditional,
}
