import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { TextField, Button, Typography } from '@material-ui/core'
import { login } from '../state/modules/authentication/actions'
import { RouteComponentProps } from 'react-router'
import Layout from '../components/Layout/layout'
import { RootState } from '../state/store'
import { compose } from 'recompose'

type PublicProps = {}

type LoginProps = {
  isAuthenticated: boolean
  errorMessage: string
  authenticate: (email: string, password: string) => any
}

type ComponentProps = PublicProps & LoginProps & RouteComponentProps<any>

const LoginPage: React.SFC<ComponentProps> = ({
  isAuthenticated,
  errorMessage,
  location,
  authenticate,
}) => {
  const loginHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    authenticate(e.currentTarget.email.value, e.currentTarget.password.value)
  }

  if (isAuthenticated) {
    return <Redirect to={'/overview'} />
  }

  return (
    <Layout routes={[]} location={location}>
      <Typography variant={'display1'}>Login</Typography>
      <form onSubmit={loginHandler} autoComplete="off">
        <TextField
          id="email"
          label="E-Mail"
          // className={classes.textField}
          margin="normal"
        />
        <br />
        <TextField
          id="password"
          label="Passwort"
          type={'password'}
          // className={classes.textField}
          margin="normal"
        />
        <br />
        {errorMessage ? <p style={{ color: 'red' }}>{errorMessage}</p> : null}
        <Button variant={'contained'} type={'submit'} color={'primary'}>
          Login
        </Button>
      </form>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.authentication.isAuthenticated,
  errorMessage: state.authentication.errorMessage,
})

const mapDispatchToProps = {
  authenticate: login,
}

export default compose<ComponentProps, LoginProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LoginPage)
