import * as React from 'react'
import * as R from 'ramda'
import { compose } from 'recompose'
import CustomReactTable from '../CustomTable'
import { connect } from 'react-redux'
import { getData, CustomerTask } from '../../state/modules/stats/customerTaskSelector'
import { RootState } from '../../state/store'
import {
  ColumnConfiguration,
  ExcelButton,
  Format,
  getColumns,
  getExport,
  SumAggregator,
  totalFooter,
} from '../../util/table'

type TaskStatsPropsPublic = {
  // id: number
}

interface TaskStatsProps {
  data: CustomerTask[]
}

const TaskStats: React.SFC<TaskStatsProps> = ({ data }) => {
  const firstColumnConfig: ColumnConfiguration[] = [
    {
      id: 'customer',
      label: 'Kunde',
      accessor: (props: CustomerTask) => `${props.customerNr} ${props.customerName}`,
      format: Format.name,
    },
  ]

  const secondColumnConfig: ColumnConfiguration[] = [
    {
      id: 'fee',
      label: 'Honorar',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'fee'),
      format: Format.currency,
    },
    {
      id: 'expenses',
      label: 'Zusatzleistungen',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'expenses'),
      format: Format.currency,
    },
    /*{
      id: 'spesen',
      label: 'Spesen',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'spesen'),
      format: Format.currency,
    },*/
    {
      id: 'billed',
      label: 'Fakturiert',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'billed'),
      format: Format.currency,
    },
    {
      id: 'temporary',
      label: 'Prov. & AA',
      aggregate: SumAggregator,
      footer: totalFooter(data, 'temporary'),
      format: Format.currency,
    },
  ]

  // Get Task columns
  const allTasks: string[] = R.reduce((taskKeys: string[], stat: CustomerTask) => {
    return [...taskKeys, ...R.keys(stat.tasks)]
  }, [])(data)

  const sortAscending: (taskNames: string[]) => string[] = R.sort(
    R.comparator((a: string, b: string) => R.lt(a, b))
  )
  // @ts-ignore
  const allTasksUniqSorted = R.pipe(R.uniq, sortAscending)(allTasks)

  const taskColumnConfig: ColumnConfiguration[] = R.map((key: string) => {
    return {
      id: key,
      label: key,
      accessor: (props: CustomerTask) => props.tasks[key],
      aggregate: SumAggregator,
      // @ts-ignore
      footer: totalFooter(R.map(R.prop('tasks'))(data), key),
      format: Format.currency,
    }
  })(allTasksUniqSorted)

  const columnConfig = [...firstColumnConfig, ...taskColumnConfig, ...secondColumnConfig]

  const columns = getColumns(columnConfig)

  return (
    <React.Fragment>
      <ExcelButton onClick={getExport('Kunden-Rentabilität', columnConfig, data)} />
      <CustomReactTable data={data} columns={columns} pivotBy={[]} />
    </React.Fragment>
  )
}

const mapStateToProps = (state: RootState, props: TaskStatsPropsPublic): TaskStatsProps => ({
  data: getData(state),
})

export default compose<TaskStatsProps, TaskStatsPropsPublic>(connect(mapStateToProps))(TaskStats)
