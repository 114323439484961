import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import Layout from '../components/Layout/layout'
import { routes } from './routes'
import AAList from '../components/AAList'
import { connect } from 'react-redux'
import { RootState } from '../state/store'
import { compose } from 'recompose'
import { authenticationSelector } from '../state/modules/authentication'
import { Typography } from '@material-ui/core'

type ProjectStatsProps = {
  loggedIn: boolean
}

type ComponentProps = ProjectStatsProps & RouteComponentProps<any>

const ProjectStats: React.StatelessComponent<ComponentProps> = ({ location, loggedIn }) => {
  if (!loggedIn) {
    return <Redirect to="/" />
  }

  return (
    <Layout routes={routes} location={location}>
      <Typography variant={'display1'}>Angefangene Arbeiten (in CHF)</Typography>
      <AAList />
    </Layout>
  )
}

const mapStateToProps = (state: RootState): ProjectStatsProps => ({
  loggedIn: authenticationSelector.isAuthenticated(state),
})

export default compose(
  connect(mapStateToProps)
  // @ts-ignore
)(ProjectStats)
