import * as React from 'react'
import { TextField } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField'

type DatePickerCustomInputProps = {} & TextFieldProps

const DatePickerCustomInput = (
  config: TextFieldProps
): React.StatelessComponent<DatePickerCustomInputProps> => props => {
  return (
    <TextField
      type="string"
      InputProps={{
        'aria-readonly': true,
      }}
      label="Date"
      placeholder=""
      {...config}
      {...props}
      variant="standard"
    />
  )
}

export default DatePickerCustomInput
