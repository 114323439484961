import * as R from 'ramda'
import { RootState } from '../../store'
import { getActivities, getBillableActivities } from '../activities/selectors'
import { getBudgetRelevantExpenses, getExpensesBetweenDates } from '../expenses/selectors'
import {
  ExtendedProject,
  getActiveProjects,
  getActiveProjectsAcquisition,
  mapToExtendedProjects,
} from '../projects/selectors'
import { getCompanies } from '../companies/selectors'
import { getInvoices } from '../invoices/selectors'
import { getFilteredState } from './statFilter'
import { getFilter } from '../general/selectors'
import { StatisticNames } from '../general/types'
import { getOffers } from '../offers/selectors'
import { getAAInfo } from './helper/aa'

export type ProjectOverview = {
  customer: string
  identifier: string
  name: string
  budget: number
  amountCHF: number
  // spesen: number, // Neu: Spesen in Zusatzleistungen
  expenses: number
  subtotal: number
  invoice: number
  correction: number
  total: number
  remainBudget: number
}

export const getData = (state: RootState, acquisitionList = false): ProjectOverview[] => {
  const filter = getFilter(state, StatisticNames.aaList)

  const stateFilter = getFilteredState(state, filter)

  const companies = getCompanies(state)
  const offers = R.values(getOffers(state))
  const projects = acquisitionList
    ? getActiveProjectsAcquisition(stateFilter.filteredState)
    : getActiveProjects(stateFilter.filteredState)
  const billableActivities = acquisitionList
    ? R.values(getActivities(stateFilter.filteredState))
    : getBillableActivities(stateFilter.filteredState)
  const billableExpenses = getExpensesBetweenDates(
    filter.startDate,
    filter.endDate,
    R.values(getBudgetRelevantExpenses(stateFilter.filteredState))
  )
  const billableInvoices = R.values(getInvoices(stateFilter.filteredState))

  const extendedProjects: ExtendedProject[] = mapToExtendedProjects(
    projects,
    companies,
    billableActivities,
    billableExpenses,
    billableInvoices,
    offers
  )

  const projectOverviews: ProjectOverview[] = R.map((p: ExtendedProject) => {
    return getAAInfo(p, acquisitionList, filter.endDate)
  })(extendedProjects)

  return R.sortWith<ProjectOverview>([
    R.ascend(R.prop('customer')),
    R.ascend(R.prop('identifier')),
  ])(projectOverviews)
}
