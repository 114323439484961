import moment from 'moment'
import { TypeKeys as generalTypes } from './constants'
import { GeneralActions } from './actions'
import { GeneralState, StatisticNames, SyncContext } from './types'
import { ProjectTypeFilter } from '../stats/statFilter'
import { getCurrentYear, getFirstDateOfYear } from '../../../util/date'

export const initFilter = () => {
  const first = getCurrentYear()
  const today = moment(new Date())

  return {
    projectType: ProjectTypeFilter.all,
    labels: [],
    startDate: first.format('YYYY-MM-DD'),
    endDate: today.format('YYYY-MM-DD'),
  }
}

const initState = {
  pendingRequests: 0,
  maxRequests: 0,
  openSidebar: false,
  persistStorage: false,
  error: '',
  lastSyncData: 'Noch nicht synchronisiert',
  lastSyncTime: '',
  lastReSyncData: '',
  lastReSyncTime: '',
  syncContext: SyncContext.empty,
  statFilters: {
    aaList: {
      ...initFilter(),
      startDate: null,
    },
    customer: initFilter(),
    employee: initFilter(),
  },
}

const activities = (
  state: GeneralState = {
    ...initState,
  },
  action: GeneralActions
): GeneralState => {
  switch (action.type) {
    case generalTypes.ANSWER_DATA: {
      return {
        ...state,
        pendingRequests: state.pendingRequests - 1 >= 0 ? state.pendingRequests - 1 : 0,
      }
    }

    case generalTypes.REQUEST_DATA: {
      const maxRequests =
        state.pendingRequests + 1 > state.maxRequests
          ? state.pendingRequests + 1
          : state.maxRequests
      return {
        ...state,
        pendingRequests: state.pendingRequests + 1,
        persistStorage: true, // save state to localstorage after all pendingRequests are done, then set to false
        maxRequests,
      }
    }

    case generalTypes.PERSIST_STORAGE_DONE: {
      return {
        ...state,
        persistStorage: false,
      }
    }

    case generalTypes.TOGGLE_SIDEBAR: {
      const isOpen = state.openSidebar
      return {
        ...state,
        openSidebar: !isOpen,
      }
    }

    case generalTypes.CLEAR_ALL: {
      if (action.payload.reason === 'sync') {
        const date = moment()
        const lastSyncData = date.format('DD.MM.YYYY')
        const lastSyncTime = date.format('HH:mm')
        return {
          ...state,
          error: '',
          maxRequests: 0,
          lastSyncData,
          lastSyncTime,
          lastReSyncData: '',
          lastReSyncTime: '',
          // saved differently to localstorage for sync
          syncContext: SyncContext.complete,
        }
      } else {
        // Logout logic
        return {
          ...state,
          error: '',
          maxRequests: 0,
          lastSyncData: 'Noch nicht synchronisiert',
          lastSyncTime: '',
          lastReSyncData: '',
          lastReSyncTime: '',
          syncContext: SyncContext.empty,
        }
      }
    }

    case generalTypes.CLEAR_RESYNC: {
      const date = moment()
      const lastReSyncData = date.format('DD.MM.YYYY')
      const lastReSyncTime = date.format('HH:mm')

      return {
        ...state,
        maxRequests: 0,
        lastReSyncData,
        lastReSyncTime,
      }
    }

    case generalTypes.SYNC_ERROR: {
      // const error = action.payload.error;
      return {
        ...state,
        error:
          'Synchronisations-Fehler. Evt. keine Berechtigung oder Authentifizierung abgelaufen. Bitte ausloggen, und anmelden und nochmals versuchen',
      }
    }

    case generalTypes.UPDATE_FILTER: {
      const statistic = action.statistic

      const convertPayload = {
        ...action.payload,
        startDate: action.payload.startDate
          ? action.payload.startDate.format('YYYY-MM-DD')
          : state.statFilters[statistic].startDate,
        endDate: action.payload.endDate
          ? action.payload.endDate.format('YYYY-MM-DD')
          : state.statFilters[statistic].endDate,
      }

      // Reset startDate
      if (statistic === StatisticNames.customer && action.payload.endDate) {
        convertPayload.startDate = getFirstDateOfYear(moment(action.payload.endDate)).format(
          'YYYY-MM-DD'
        )
      }

      return {
        ...state,
        statFilters: {
          ...state.statFilters,
          [statistic]: {
            ...state.statFilters[statistic],
            ...convertPayload,
          },
        },
      }
    }

    default:
      return state
  }
}

export default activities
