import { all, Effect, put, select, take } from 'redux-saga/effects'
import { TypeKeys as generalTypes } from '../modules/general/constants'
import { TypeKeys } from '../modules/comatic/constants'
import { AdditionalContact } from '../modules/comatic/types'
import { getComaticContactsAdditional } from '../modules/comatic/selectors'
import * as R from 'ramda'
import { getAdvertizerContactsAdditional } from '../modules/comatic/selectorsAdvertizer'
import { stubSelector } from '../modules/general/selectors'

export function* fetchContacts() {
  yield put({
    type: generalTypes.REQUEST_DATA,
    payload: {
      resource: 'contacts/people',
      params: {
        // type: customer/supplier/organization
      },
    },
  })
}

export function* syncAdvertizerContacts() {
  while (true) {
    // Wait for a sync company action
    yield take(TypeKeys.SYNC_ADVERTIZER_CONTACTS)

    const comaticContacts: AdditionalContact[] = yield select(getAdvertizerContactsAdditional)

    const effects: Effect[] = R.map((contact: AdditionalContact) => {
      if (contact.syncState === 'Neu') {
        return put({
          type: generalTypes.POST_PUT_DATA,
          payload: {
            requestType: 'POST',
            resource: 'contacts/people',
            data: {
              ...R.omit(['id', 'syncState'], contact), //
            },
          },
        })
      }

      if (contact.syncState === 'Update') {
        return put({
          type: generalTypes.POST_PUT_DATA,
          payload: {
            requestType: 'PUT',
            resource: `contacts/people/${contact.id}`,
            data: {
              ...R.omit(['id', 'syncState', 'birthday'])(contact as Required<AdditionalContact>),
            },
          },
        })
      }

      return select(stubSelector)
      /*
        return put({
          type: generalTypes.NOTHING_TO_CHANGE_DATA,
          payload: {
            resource: 'company',
            params: [],
            data: {
              ...contact
            }
          }
        });
        */
    })(comaticContacts)

    yield all(effects)
  }
}

export function* syncComaticContacts() {
  while (true) {
    // Wait for a sync company action
    yield take(TypeKeys.SYNC_CONTACTS)

    const comaticContacts: AdditionalContact[] = yield select(getComaticContactsAdditional)

    const effects: Effect[] = R.map((contact: AdditionalContact) => {
      // Default value for mandatory field
      const firstname = contact.firstname ? contact.firstname : 'A.'
      const lastname = contact.lastname ? contact.lastname : 'A.'
      if (contact.syncState === 'Neu') {
        return put({
          type: generalTypes.POST_PUT_DATA,
          payload: {
            requestType: 'POST',
            resource: 'contacts/people',
            data: {
              ...R.omit(['id', 'syncState'], contact),
              firstname,
              lastname,
            },
          },
        })
      }

      if (contact.syncState === 'Update') {
        return put({
          type: generalTypes.POST_PUT_DATA,
          payload: {
            requestType: 'PUT',
            resource: `contacts/people/${contact.id}`,
            data: {
              ...R.omit(['id', 'syncState'])(contact as Required<AdditionalContact>),
              firstname,
              lastname,
            },
          },
        })
      }

      return select(stubSelector)
      /*
        return put({
          type: generalTypes.NOTHING_TO_CHANGE_DATA,
          payload: {
            resource: 'company',
            params: [],
            data: {
              ...contact
            }
          }
        });*/
    })(comaticContacts)

    yield all(effects)
  }
}
