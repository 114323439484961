import * as React from 'react'
import cx from 'classnames'
import * as H from 'history'
import { RouteProps } from 'react-router'
import { NavLink } from 'react-router-dom'
import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  WithStyles,
} from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'
import { Color, SidebarClasses } from './sidebarStyle'
import { RoutePath } from '../../page/routes'
import { getConfig } from '../../state/sagas/api/config'

export type SidebarProps = {
  color: Color
  logo: string // Src logo
  image?: string // Src, background image
  logoText: string
  routes: RoutePath[]
  open: boolean
  toggle: () => void
  logout: () => void
  isAuthenticated: boolean
}

export type ComponentProps = SidebarProps & RouteProps & WithStyles<SidebarClasses | Color>

export const Sidebar: React.SFC<ComponentProps> = props => {
  const config = getConfig()
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName: H.Path, exact: boolean): boolean {
    if (props.location) {
      if (exact) {
        return props.location.pathname === routeName
      } else {
        return props.location.pathname.indexOf(routeName) === 0
      }
    } else {
      return false
    }
  }

  const { classes, color, image, logoText, routes } = props

  const links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.redirect) {
          return null
        }
        const listItemClasses = cx({
          [' ' + classes[color]]: activeRoute(prop.path, prop.exact ? prop.exact : false),
        })
        const whiteFontClasses = cx({
          [' ' + classes.whiteFont]: activeRoute(prop.path, prop.exact ? prop.exact : false),
        })
        return (
          <NavLink
            to={prop.path}
            onClick={props.toggle}
            className={classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                <prop.icon />
              </ListItemIcon>
              <ListItemText
                primary={prop.sidebarName}
                className={classes.itemText + whiteFontClasses}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        )
      })}
      {props.isAuthenticated ? (
        <ListItem onClick={props.logout} button className={classes.itemLink}>
          <ListItemIcon className={classes.itemIcon}>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText
            primary={'Logout'}
            className={classes.itemText + ' ' + classes.whiteFont}
            disableTypography={true}
          />
        </ListItem>
      ) : null}
    </List>
  )
  const brand = (
    <div className={classes.logo}>
      <a
        href={config.originalUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.logoLink}
      >
        {/*<div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
  </div>*/}
        {logoText}
      </a>
    </div>
  )
  return (
    <div>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={props.toggle}
          ModalProps={
            {
              // keepMounted: true, // Better open performance on mobile.
            }
          }
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {/* <HeaderLinks /> */}
            {links}
          </div>
          {image !== undefined ? (
            <div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  )
}
