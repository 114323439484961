import { TypeKeys as types } from './constants';
// import { TypeKeys as generalTypes } from '../general/constants'
import { AuthenticationAction } from './actions'
import { AuthenticationState } from './types'

const initState = {
  isAuthenticated: false,
  userId: 0,
  token: ''
}

const authentication = (
  state: AuthenticationState = {
    ...initState,
  },
  action: AuthenticationAction
): AuthenticationState => {
  switch (action.type) {
    case types.LOGIN_SUCCESSFUL: {
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        userId: action.payload.userId,
        errorMessage: undefined
      }
    }

    case types.LOGIN_UNSUCCESSFUL: {
      return {
        ...state,
        isAuthenticated: false,
        errorMessage: action.payload.message,
      }
    }

    case types.LOGIN_ERROR: {
      return {
        ...state,
        isAuthenticated: false,
        errorMessage: 'Anmeldung fehlgeschlagen - ' + action.payload.error.message
      }
    }

    case types.LOGOUT_SUCCESSFUL: {
      return {
        ...state,
        isAuthenticated: false,
        token: '',
        userId: 0,
        errorMessage: undefined
      }
    }

    /* todo: implement again
    case types.REPLACE_ALL: {
      return { ...state, detailsById: action.payload, currentEvent: Object.keys(action.payload)[0] };
    }*/

    default:
      return state
  }
}

export default authentication
