// import { TypeKeys as types } from './constants';
import { TypeKeys as generalTypes } from '../general/constants'
import { ScheduleAction } from './actions'
import { ScheduleState } from './types'

const initState = {
  detailsById: {},
}

const expenses = (
  state: ScheduleState = {
    detailsById: {},
    ...initState,
  },
  action: ScheduleAction
): ScheduleState => {
  switch (action.type) {
    case generalTypes.ADD_DATA: {
      const resource = action.payload.resource
      if (resource === 'schedules') {
        return {
          ...state,
          detailsById: {
            ...state.detailsById,
            ...action.payload.data,
          },
        }
      } else {
        return state
      }
    }

    case generalTypes.CLEAR_ALL: {
      return {
        ...state,
        detailsById: {},
      }
    }

    default:
      return state
  }
}

export default expenses
