// import { TypeKeys as types } from './constants';
import { TypeKeys as generalTypes } from '../general/constants'
import { ContactAction } from './actions'
import { ContactState } from './types'

const initState = {
  detailsById: {},
}

const contacts = (
  state: ContactState = {
    detailsById: {},
    ...initState,
  },
  action: ContactAction
): ContactState => {
  switch (action.type) {
    case generalTypes.ADD_DATA: {
      if (action.payload.resource.indexOf('contacts/people') > -1) {
        return {
          ...state,
          detailsById: {
            ...state.detailsById,
            ...action.payload.data,
          },
        }
      } else {
        return state
      }
    }

    case generalTypes.CLEAR_ALL: {
      return {
        ...state,
        detailsById: {},
      }
    }

    default:
      return state
  }
}

export default contacts
