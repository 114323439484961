import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Layout from '../../components/Layout/layout'
import { routes } from '../routes'
import { RootState } from '../../state/store'
import { User } from '../../state/modules/users/types'
import { userSelector } from '../../state/modules/users/index'
import { authenticationSelector } from '../../state/modules/authentication'
import EmployeeSelection from './EmployeeSelection'
import { Typography } from '@material-ui/core'

type OverviewEmployeeProps = {
  activeUsers: User[]
  inactiveUsers: User[]
  externalUsers: User[]
  loggedIn: boolean
}

type ComponentProps = OverviewEmployeeProps & RouteComponentProps<any>

const PeoplePage: React.SFC<ComponentProps> = ({
  location,
  match,
  activeUsers,
  inactiveUsers,
  externalUsers,
  loggedIn,
}) => {
  if (!loggedIn) {
    return <Redirect to="/" />
  }

  return (
    <Layout routes={routes} location={location}>
      <Typography variant={'display1'}>Mitarbeitende</Typography>
      <EmployeeSelection activeUserId={0} users={activeUsers} />
      <Typography variant={'headline'}>Ehemalige Mitarbeitende</Typography>
      <EmployeeSelection hideInfo={true} activeUserId={0} users={inactiveUsers} />
      <Typography variant={'headline'}>Externe/Zusätzliche Accounts</Typography>
      <EmployeeSelection hideInfo={true} activeUserId={0} users={externalUsers} />
    </Layout>
  )
}

const mapStateToProps = (state: RootState): OverviewEmployeeProps => ({
  activeUsers: userSelector.getActiveVivaUsers(state),
  inactiveUsers: userSelector.getInactiveUsers(state),
  externalUsers: userSelector.getExterneUsers(state),
  loggedIn: authenticationSelector.isAuthenticated(state),
})
// @ts-ignore
export default compose(connect(mapStateToProps))(PeoplePage)
