import * as R from 'ramda'
import { RootState } from '../../store'
import { Company } from './types'
import { getProjects } from '../projects/selectors'
import { Project } from '../projects/types'
import { CompanyList, Prediction } from './types'

export const getCompanies = (state: RootState): CompanyList => {
  return state.companies.detailsById
}

const getCompaniesOfProject =
  (companies: CompanyList) =>
  (project: Project): Company =>
    companies[project.customer.id]

const filterParentCompany = (companies: Company[]) =>
  R.filter((company: Company) => {
    return company.identifier !== 'K0001'
  })(companies)

const uniqCompany: (companies: Company[]) => Company[] = R.uniqWith(
  (c: Company, b: Company) => c.id === b.id
)

const sortCompany: (companies: Company[]) => Company[] = R.sortBy(R.prop('name'))

export const getCustomers = (state: RootState, withoutOwnCompany = false): Company[] => {
  const companies = getCompanies(state)
  const projects = getProjects(state)

  const getCompanyOfProject = getCompaniesOfProject(companies)

  const customers: Company[] = R.map(getCompanyOfProject)(R.values(projects))

  if (withoutOwnCompany) {
    return R.pipe(uniqCompany, filterParentCompany, sortCompany)(customers)
  }
  return R.pipe(uniqCompany, sortCompany)(customers)
}

export const getCompany = (state: RootState, id: number): Company => {
  return getCompanies(state)[id]
}

export const getCompanyOfComaticId = (state: RootState, comaticId: string): Company => {
  const companies = R.values(getCompanies(state))
  const getRelCompanies: (companies: Company[]) => Company[] = R.filter(
    R.pathEq(['custom_properties', 'ComaticId'], comaticId)
  )

  const relCompanies: Company[] = getRelCompanies(companies)
  return relCompanies[0]
}

export const getCompanyOfAdvertizerId = (state: RootState, comaticId: string): Company => {
  const companies = R.values(getCompanies(state))
  const getRelCompanies: (companies: Company[]) => Company[] = R.filter(
    R.pathEq(['custom_properties', 'AdvertizerId'], comaticId)
  )

  const relCompanies: Company[] = getRelCompanies(companies)
  return relCompanies[0]
}

export const getPredictionTotal = (predictions: Prediction[] | undefined) => {
  if (predictions) {
    // @ts-ignore
    const nums: number[] = R.map(R.prop('value'))(predictions)
    return R.sum(nums)
  }
  return 0
}

export const getStatRelevantCompanies = (state: RootState): CompanyList => {
  const companies = getCompanies(state)
  const projects = Object.values(getProjects(state))
  // const relevantCompanies: CompanyList = {}

  const relevantCompanies = R.filter((company: Company) => {
    // filter the companies with projects
    /* Revert, not all companies has a project fields
    if (company && company.projects && company.projects.length > 0) {
      return true
    }
    return false
    */
    // return company && company.projects && company.projects.length > 0 ? true : false;

    return R.find(R.pathEq(['customer', 'id'], company.id))(projects) ? true : false
  })(companies)

  // @ts-ignore
  return relevantCompanies
}

export default {
  getCompanies,
  getCompany,
  getCustomers,
}
