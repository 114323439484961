import { AxiosError } from 'axios'
import { TypeKeys as types } from './constants'

interface LoginAction {
  type: types.LOGIN
  payload: {
    email: string
    password: string
  }
}

interface LogoutAction {
  type: types.LOGOUT
  payload: {

  }
}

interface LoginSuccessfulAction {
  type: types.LOGIN_SUCCESSFUL,
  payload: {
    token: string
    userId: number
  }
}

interface LoginUnSuccessfulAction {
  type: types.LOGIN_UNSUCCESSFUL,
  payload: {
    message: string
  }
}

interface LoginErrorAction {
  type: types.LOGIN_ERROR,
  payload: {
    error: AxiosError
  }
}

interface LogoutSuccessful {
  type: types.LOGOUT_SUCCESSFUL,
}


export type AuthenticationAction = LoginAction | LogoutAction | LoginSuccessfulAction | LoginUnSuccessfulAction | LoginErrorAction | LogoutSuccessful;

/**
 * Selects the gender with the corresponding ID
 * @param id
 */
export const login = (email: string, password: string): LoginAction => ({
  type: types.LOGIN,
  payload: {
    email,
    password,
  },
})

export const logout = (): LogoutAction => ({
  type: types.LOGOUT,
  payload: {
  },
})
