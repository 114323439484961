import * as React from 'react'
import { routes } from './routes'
import Layout from '../components/Layout/layout'
import { RouteComponentProps } from 'react-router'
import { Typography } from '@material-ui/core'

type NoRightsPageProps = {} & RouteComponentProps<any>

const NoRightsPage: React.StatelessComponent<NoRightsPageProps> = ({ location }) => {
  return (
    <Layout routes={routes} location={location}>
      <Typography variant={'display1'}>Berechtigung fehlt</Typography>
      <p>
        Diesem internen Benutzer wurde keine Berechtigung zur Benutzung des Statistik-Tools erteilt.
        <br />
        Bitte im MOCO unter Personal/ZugangStatistikTool Berechtigung verwalten.
      </p>
    </Layout>
  )
}

export default NoRightsPage
