import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router'
import Layout from '../components/Layout/layout'
import { routes } from './routes'
import SyncButton from '../components/SyncButton'
import { compose } from 'recompose'
import { RootState } from '../state/store'
import { authenticationSelector } from '../state/modules/authentication'
import { Typography } from '@material-ui/core'
import { getSyncError } from '../state/modules/general/selectors'

type OverviewProps = {
  loggedIn: boolean
  syncError: string
}

type ComponentProps = OverviewProps & RouteComponentProps<any>

const Overview: React.SFC<ComponentProps> = ({ location, loggedIn, syncError }) => {
  if (!loggedIn) {
    return <Redirect to="/" />
  }

  return (
    <Layout routes={routes} location={location}>
      <Typography variant={'display1'}>Übersicht</Typography>

      <SyncButton />

      {syncError ? <p style={{ color: 'red' }}>{syncError}</p> : null}
    </Layout>
  )
}

const mapStateToProps = (
  state: RootState,
  props: RouteComponentProps<{ id: string }>
): OverviewProps => ({
  loggedIn: authenticationSelector.isAuthenticated(state),
  syncError: getSyncError(state),
})
// @ts-ignore
export default compose(connect(mapStateToProps))(Overview)
