import * as React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { CustomerProfitability, getData } from '../../state/modules/stats/profitabilitySelector'
import { Company } from '../../state/modules/companies/types'
import { RootState } from '../../state/store'
import AllFilter from '../filter/AllFilter'
import { StatisticNames } from '../../state/modules/general/types'
import ProfitabilityStatsAll from './profitability/ProfitabilityStatsAll'

type ProfitabilityStatsPropsPublic = {
  id: number
  customer: Company
}

interface ProfitabilityStatsProps {
  data: CustomerProfitability[]
  customer: Company
}

const ProfitablityStats: React.SFC<ProfitabilityStatsProps> = ({ customer, data }) => {
  // const archivedBBE: number[] = R.map(R.prop('bbeCurrent'))(archivedData)
  // const activeBBE: number[] = R.map(R.prop('bbeCurrent'))(activeData)
  // const totalBBE = R.sum([...activeBBE, ...archivedBBE])

  return (
    <React.Fragment>
      <AllFilter statistic={StatisticNames.customer} disabledStart={true} />

      <ProfitabilityStatsAll customer={customer} data={data} />
    </React.Fragment>
  )
}

const mapStateToProps = (
  state: RootState,
  props: ProfitabilityStatsPropsPublic
): ProfitabilityStatsProps => ({
  data: getData(state, props.id),
  customer: props.customer,
})

export default compose<ProfitabilityStatsProps, ProfitabilityStatsPropsPublic>(
  connect(mapStateToProps)
)(ProfitablityStats)
