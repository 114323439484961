import { Company } from '../companies/types'
import { Contact } from '../contacts/types'

export type AdditionalState = 'Neu' | 'Update' | 'Identisch'

export type AdditionalCompany = Company & {
  syncState: AdditionalState
}

export type AdditionalContact = Contact & {
  syncState: AdditionalState
}

export type ComaticCompanyImport = {
  Adress_ID: string
  Adress_Status: string
  Anrede: string
  Vorname: string
  Name: string
  Adresse1: string
  Adresse2: string
  Adresse3: string
  Plz: string
  Ort: string
  Region: string
  Land: string
  'Telefon 1': string
  'Telefon 2': string
  'Telefon 3': string
  Telefax: string
  'E-Mail': string
  Waehrung: string
  Sprache: string
  Briefanrede: string
  Adress_Changed: string
  ErfasstDat: string
  ChangedDat: string
  ChangedAdm: string
  Parent_ID: string
  Adressart: string
  Lieferadresse: string
  Kontakttyp: string
  Rayon: string
  Preisliste: string
  InternetURL: string
  Rechnungsadresse: string
  Zustelladresse: string
  Lagerort: string
  Lieferart: string
  EK_Preise: string
  EK_Konditionen: string
  VK_Konditionen: string
  Rechnungskopien: string
  Mahndatum: string
  Mahnstufe: string
  Mahnstop: string
  Kreditlimite: string
  Adress_Referenz: string
  StandardText_ID: string
  ProjektKst: string
  TeilLS: string
  MWStType: string
  Remote_ID: string
  LSVE_Datum: string
  x_Carwash_Pflege: string
  x_Online_IT_DL: string
  x_Finanzdienstleister: string
  x_Batterien: string
  x_Schmierstoffe: string
  x_Werkstatt_Werkzeug: string
  x_Klimageraete: string
  x_Diagnosegeraete: string
  x_Reifen_Raeder: string
  x_Ersatzteile: string
  x_Ausbildung: string
  x_Garagenkonzepte: string
  x_Autohandel: string
  x_Tankstellen: string
  x_Carrosserie: string
  x_Autozubehoer_Tuning: string
  x_Nutzfahrzeuge: string
  x_Messen: string
  x_Chemie: string
  x_Diverses: string
  x_Werbeagentur: string
  x_Segment_AI: string
  x_Segment_A_W: string
  x_TdSG: string
  x_Vermarktungs_NL: string
  x_AGVS_Kooperation: string
  x_AGVS_Sponsoring: string
  x_AboverteilerAI: string
}

export type ComaticContactImport = {
  Adress_ID: string
  Firma: string
  Anrede: string
  Vorname: string
  Name: string
  Adresse1: string
  Adresse2: string
  Adresse3: string
  Plz: string
  Ort: string
  Land: string
  Region: string
  'Telefon 1': string
  'Telefon 2': string
  'Telefon 3': string
  Telefax: string
  'E-Mail': string
  Sprache: string
  Briefanrede: string
  Adress_Status: string
  Adress_Changed: string
  ErfasstDat: string
  ChangedDat: string
  ChangedAdm: string
  Parent_ID: string
  Adressart: string
  Lieferadresse: string
  Kontakttyp: string
  Waehrung: string
  Rayon: string
  Preisliste: string
  InternetURL: string
  Rechnungsadresse: string
  Zustelladresse: string
  Lagerort: string
  Lieferart: string
  EK_Preise: string
  EK_Konditionen: string
  VK_Konditionen: string
  Rechnungskopien: string
  Mahndatum: string
  Mahnstufe: string
  Mahnstop: string
  Kreditlimite: string
  Adress_Referenz: string
  StandardText_ID: string
  ProjektKst: string
  TeilLS: string
  MWStType: string
  Remote_ID: string
  LSVE_Datum: string
  Carwash_Pflege: string
  Online_IT_DL: string
  Finanzdienstleister: string
  Batterien: string
  Schmierstoffe: string
  Werkstatt_Werkzeug: string
  Klimageraete: string
  Diagnosegeraete: string
  Reifen_Raeder: string
  Ersatzteile: string
  Ausbildung: string
  Garagenkonzepte: string
  Autohandel: string
  Tankstellen: string
  Carrosserie: string
  Autozubehoer_Tuning: string
  Nutzfahrzeuge: string
  Messen: string
  Chemie: string
  Diverses: string
  Werbeagentur: string
  Segment_AI: string
  Segment_A_W: string
  TdSG: string
  Vermarktungs_NL: string
  AGVS_Kooperation: string
  AGVS_Sponsoring: string
  AboverteilerAI: string
  Tx2Adresse2: string
  Tx2Adresse3: string
  Tx2Plz: string
  Tx2Ort: string
  Tx2Land: string
  Tx2Region: string
  Tx2Adress_Status: string
}

export type AdvertizerCompanyImport = {
  type: string
  name: string
  labels: string // convert to string[]
  fax: string
  website: string
  address: string
  phone: string
  email: string
  currency: string

  id: string
  comaticId: string // should be empty. Deleted before
}

export type AdvertizerContactImport = {
  firstname: string
  lastname: string
  gender: string
  labels: string // convert to string[]
  title: string
  job_position: string
  mobile_phone: string
  work_fax: string
  work_phone: string
  work_email: string
  home_email: string
  home_address: string
  work_address: string
  birthday: string | null
  phonePrivate: string
  id: string
  parentId: string
  comaticId: string // should be empty, delete before!
}

export enum ComaticStep {
  outOfSync = 'outOfSync',
  companyFileSelection = 'companyFileSelection',
  companyOverview = 'companyOverview',
  syncCompanies = 'companySync',
  contactFileSelection = 'contactFileSelection',
  contactOverview = 'contactOverview',
  syncContacts = 'contactSync',
  finished = 'syncFinished',
}

export interface ComaticState {
  companies: Company[]
  contacts: Contact[]
  readonly comaticStep: ComaticStep
  readonly comaticRequests: number
}
