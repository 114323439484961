import { all, put } from 'redux-saga/effects'
import * as _ from 'lodash'
import { TypeKeys as generalTypes } from '../modules/general/constants'
import { AddAction } from '../modules/general/actions'

export function* fetchProjects() {
  yield put({
    type: generalTypes.REQUEST_DATA,
    payload: {
      resource: 'projects',
      params: {
        include_archived: true,
      },
    },
  })
}

export function* fetchProjectsDetails(action: AddAction) {
  // 2- take from the channel
  const { payload } = action

  // Check if project relevant
  const { resource, data: projects } = payload
  if (resource === 'projects') {
    // Request expenses
    yield all(
      _.map(projects, project => {
        if (project.custom_properties && project.custom_properties.SyncZusatzleistungen) {
          return put({
            type: generalTypes.REQUEST_DATA,
            payload: {
              resource: `projects/${project.id}/expenses`,
              params: [],
            },
          })
        }
        return put({
          type: 'DUMMY_PLACEHOLDER',
          payload: {},
        })
      })
    )
    // Request recurring expenses, they are included expenses
    /*
    yield all(
      _.map(projects, project =>
        put({
          type: generalTypes.REQUEST_DATA,
          payload: {
            resource: `projects/${project.id}/recurring_expenses`,
            params: [],
          },
        })
      )
    )
    */
  }
}
