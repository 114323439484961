export enum SagaActionConstants {
  // API Relevant
  SYNC_REQUEST = 'sync/REQUEST',
  SYNC_FINISHED = 'sync/FINISHED',

  // API Relevant
  SYNC_RE_REQUEST = 'sync/REREQUEST',
  SYNC_RE_FINISHED = 'sync/REFINISHED',

  FETCH_START = 'fetch/START',
  FETCH_FINISHED = 'fetch/FINISHED',

  // Fetch one request
  FETCH_REQUEST_START = 'fetch/REQUEST_START',
  FETCH_REQUEST_FAILED = 'fetch/REQUEST_FAILED',
  FETCH_REQUEST_SUCCESS = 'fetch/REQUEST_SUCCESS',

  // Replace store is called after alle Data is synchronized
  REPLACE = 'store/REPLACE',
}
