import { all, call, fork, put, takeEvery, take, race } from 'redux-saga/effects'
import { SagaActionConstants } from './constants'
import { fetchActivities } from './activities'
import { fetchProjects, fetchProjectsDetails } from './projects'
import { watchRequests } from './api/requestQueue'
import { watchAnswers } from './api/answerQueue'
import { TypeKeys as generalTypes } from '../modules/general/constants'
import { fetchContacts, syncAdvertizerContacts, syncComaticContacts } from './contacts'
import { fetchCompanies, syncAdvertizerCompanies, syncComaticCompanies } from './companies'
import { fetchUsers } from './users'
import { fetchInvoices } from './invoices'
import { fetchOffers } from './offers'
import { fetchSchedules } from './schedules'
import { loginFlow, logoutFlow } from './authentication'
import { watchImport } from './api/syncQueue'
import { generalActions } from '../modules/general'
// import { generalActions } from '../modules/general'

export interface SyncPayload {
  from?: string
  to?: string
}

export interface SyncAction {
  type: string
  payload: SyncPayload
}

export const syncAction = (params: SyncPayload): SyncAction => ({
  type: SagaActionConstants.SYNC_REQUEST,
  payload: {
    ...params,
  },
})

export const reSyncAction = (params: SyncPayload): SyncAction => ({
  type: SagaActionConstants.SYNC_RE_REQUEST,
  payload: {
    ...params,
  },
})

/** Fetch details dependent on requests */
function* fetchDetails() {
  yield takeEvery(generalTypes.ANSWER_DATA, fetchProjectsDetails)
}

/**
 * Syncs the whole project from the beginning (from date in action)
 * @param action option with from date to define earliest start (first entry)
 */
function* syncMocoData(action: SyncAction) {
  yield put(generalActions.clear('sync'))

  yield all([
    call(fetchProjects),
    call(fetchActivities, action),
    call(fetchContacts),
    call(fetchCompanies),
    call(fetchOffers),
    call(fetchUsers),
    call(fetchInvoices),
    call(fetchSchedules),
    race({
      projectDetails: call(fetchDetails),
      cancel: take([generalTypes.CLEAR_RESYNC, generalTypes.CLEAR_ALL]),
    }),
  ])
}

/**
 * Syncs the moco data most important data and reduced activities
 * @param action option with from date, defined in sync button
 */
function* reSyncMocoData(action: SyncAction) {
  yield put(generalActions.clearResync('resync'))

  yield all([call(fetchProjects), call(fetchActivities, action), call(fetchInvoices)])
}

/**
 * Takes one sync request per time
 * Call syncMocoData
 * Give result back to outer function
 * @returns {IterableIterator<any>}
 */
function* mySaga() {
  yield fork(loginFlow)
  yield fork(logoutFlow)
  yield fork(watchImport)

  while (true) {
    yield all([
      takeEvery(SagaActionConstants.SYNC_REQUEST, syncMocoData),
      takeEvery(SagaActionConstants.SYNC_RE_REQUEST, reSyncMocoData),
      syncComaticCompanies(),
      syncComaticContacts(),
      syncAdvertizerCompanies(),
      syncAdvertizerContacts(),
      watchRequests(),
      watchAnswers(),
    ])
  }
}

export default mySaga
