// import { TypeKeys as types } from './constants';
import { TypeKeys as generalTypes } from '../general/constants'
import { RecurringExpenseAction } from './actions'
import { RecurringExpenseState } from './types'
import * as _ from 'lodash'

const initState = {
  detailsById: {},
}

const expenses = (
  state: RecurringExpenseState = {
    detailsById: {},
    ...initState,
  },
  action: RecurringExpenseAction
): RecurringExpenseState => {
  switch (action.type) {
    case generalTypes.ADD_DATA: {
      const resource = action.payload.resource
      if (resource.indexOf('/recurring_expenses') >= 0) {
        const idStart = resource.indexOf('/') + 1
        const idEnd = resource.indexOf('/', idStart)
        const projectId = parseInt(resource.slice(idStart, idEnd), 10)
        const recurringExpenseData: any = _.reduce(
          action.payload.data,
          (result, expense) => {
            return {
              ...result,
              [expense.id]: {
                ...expense,
                project_id: projectId,
              },
            }
          },
          {}
        )
        return {
          ...state,
          detailsById: {
            ...state.detailsById,
            ...recurringExpenseData,
          },
        }
      } else {
        return state
      }
    }

    case generalTypes.CLEAR_ALL: {
      return {
        ...state,
        detailsById: {},
      }
    }
    /* todo: implement again
    case types.REPLACE_ALL: {
      return { ...state, detailsById: action.payload, currentEvent: Object.keys(action.payload)[0] };
    }*/

    default:
      return state
  }
}

export default expenses
