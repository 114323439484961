import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { HashRouter as Router } from 'react-router-dom'
import App from './App'
import './index.css'
import store from './state/store'
import { IntlProvider } from 'react-intl'
import moment from 'moment'
import 'moment/locale/de-ch'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/dist/locale-data/en'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/dist/locale-data/de'

moment.locale('de-ch')

ReactDOM.render(
  <IntlProvider locale="de-CH">
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </IntlProvider>,
  document.getElementById('root') as HTMLElement
)
