import { put } from 'redux-saga/effects'
import { TypeKeys as generalTypes } from '../modules/general/constants'

export function* fetchSchedules() {
  // const { payload } = action;

  // Add to request queue
  yield put({
    type: generalTypes.REQUEST_DATA,
    payload: {
      resource: 'schedules',
      params: {
        include_archived: true,
      },
    },
  })
}
