import * as R from 'ramda'
import moment from 'moment'
import { RootState } from '../../store'
import { getActivities } from '../activities/selectors'
import {
  ExtendedProject,
  getCorrection,
  getProjects,
  mapToExtendedProjects,
} from '../projects/selectors'
import { getExpenses, getBudgetIrrelevantExpensesTotal } from '../expenses/selectors'
import { getInvoices, getInvoiceMaxDate } from '../invoices/selectors'
import { getFilter } from '../general/selectors'
import { StatisticNames } from '../general/types'
import { DataFilter, getFilteredState } from './statFilter'
import { getCustomers } from '../companies/selectors'
import { getOffers } from '../offers/selectors'
import { getProjectBBE } from './helper/bbe'
import { getAAInfo } from './helper/aa'
import { Moment } from 'moment'

export type CustomerProfitability = {
  // Projekt	Fakturiert	Honorar	Spesen	Zusatzleistung	Total Agentur	Korrektur	Total AA aktuell	AA Vorjahr	BBE aktuell
  // Total Agentur Brutto	Total Agentur Netto	Korrektur	Korrektur %
  projectName: string // Projekt
  billedCurrent: number // Fakturiert
  feeCurrent: number // Honorar

  // spesen: number // Spesen in Zusatzleistungen
  expenses: number // Zusatzleistungen

  subTotal: number // Total Agentur
  correction: number // Korrektur
  total: number // Total AA aktuell

  feeDifferent: number // AA Vorjahr
  bbeCurrent: number // BBE aktuell

  totalBrutto: number // Total Agentur Brutto
  totalNetto: number // Total Agentur Netto

  correctionNegative: number // Korrektur
  correctionPercentage: number // Korrektur %
}

/*
const getCustomerProfitabilityArchived = (
  project: ExtendedProject
): CustomerProfitabilityArchived => {
  const billedCurrent = getInvoicesTotal(project.invoices)
  const expenses = getExpensesTotal(project.expenses)
  const feeExpense = getActivitiesTotal(project.activities)
  const spesen = getSpesen(feeExpense)

  const delta = billedCurrent - feeExpense - expenses - spesen
  const deltaPercentage = (delta / billedCurrent) * 100

  return {
    projectName: project.name,
    billedCurrent,
    expenses,
    feeExpense,
    spesen,
    delta,
    deltaPercentage,
    bbeCurrent: billedCurrent,
  }
}

const getCustomerProfitabilityActive = (filter: DataFilter) => (
  project: ExtendedProject
): CustomerProfitabilityActive => {
  const budget = project.budget ? project.budget : 0

  const allActivities = project.activities
  // Fees from selected period Todo: Refactor
  const startDate = filter.startDate ? filter.startDate : moment('2000-01-01')
  // TODO: Rethink about endDate. Guaranteed?
  const endDate = filter.endDate ? filter.endDate : moment('2099-01-01')

  const currentActivities = getFeeCurrent(startDate, endDate)(allActivities)
  const feeCurrentWithoutCorrection = getActivitiesTotal(currentActivities)

  const differentActivities = getFeeDifferentPeriod(startDate, endDate)(allActivities)
  const feeDifferentWithoutCorrection = getActivitiesTotal(differentActivities)
  const totalFeeWithoutCorrection = feeCurrentWithoutCorrection + feeDifferentWithoutCorrection
  // const correction = project.custom_properties.Bewertungskorrektur ? project.custom_properties.Bewertungskorrektur : 0;
  const correction = getCorrection(endDate.year().toString())(project)
  const correctionCurrent = (feeCurrentWithoutCorrection / totalFeeWithoutCorrection) * correction
  const correctionDifferent =
    (feeDifferentWithoutCorrection / totalFeeWithoutCorrection) * correction
  const feeCurrent = feeCurrentWithoutCorrection - correctionCurrent
  const feeDifferent = feeDifferentWithoutCorrection - correctionDifferent

  const totalFee = feeCurrent + feeDifferent

  const bbeCurrent = feeCurrent

  const spesen = getSpesen(feeCurrent + feeDifferent)
  const expenses = getExpensesTotal(project.expenses)

  const delta = budget - totalFee - spesen - expenses
  const deltaPercentage = isNaN(delta / budget) ? 100 : (delta / budget) * 100

  return {
    projectName: project.name,
    budget,
    feeCurrent,
    feeDifferent,
    expenses,
    spesen,
    correction,
    delta,
    deltaPercentage,
    bbeCurrent,
  }
}*/

type CustomerFunction = (customerID: number) => (project: ExtendedProject) => boolean
const sameCustomer: CustomerFunction = (customerId: number) =>
  R.pathEq(['customer', 'id'], customerId)

type SameInvoiceFunction = (date: Moment) => (project: ExtendedProject) => boolean
export const sameInvoiceYear: SameInvoiceFunction =
  (date: Moment) => (project: ExtendedProject) => {
    const invoiceDate = getInvoiceMaxDate(project.invoices)
    if (project.active) {
      return true
    }
    return date.isSameOrBefore(invoiceDate, 'year')
  }

/*
const hasActivitiesInPeriod = (startDate: Moment, endDate: Moment) => (
  project: ExtendedProject
) => {
  const currentActivities = getFeeCurrent(startDate, endDate)(project.activities)
  if (currentActivities) {
    return currentActivities.length > 0
  }
  return false
}


export const getDataOfActive = (
  rootState: RootState,
  customerId: number
): CustomerProfitabilityActive[] => {
  const filter = getFilter(rootState, StatisticNames.customer)

  const allStates = getFilteredState(rootState, {
    ...filter,
    // get all activities, separate later
    startDate: moment('2010-01-01'),
    endDate: moment('2099-12-31'),
    projectType: ProjectTypeFilter.active,
  })

  const startDate = filter.startDate ? filter.startDate : moment('2010-01-01')
  const endDate = filter.endDate ? filter.endDate : moment('2099-12-31')

  const projects = allStates.filteredByInvoiceDate

  const relevantProjects: ExtendedProject[] = R.filter(
    R.both(sameCustomer(customerId), hasActivitiesInPeriod(startDate, endDate))
  )(projects)

  return R.map(getCustomerProfitabilityActive(filter))(relevantProjects)
}
*/

export const getCustomerProfitability =
  (filter: DataFilter) =>
  (project: ExtendedProject): CustomerProfitability => {
    const endDate = filter.endDate ? filter.endDate : moment()
    const invoiceDate = getInvoiceMaxDate(project.invoices)
    const isFinished = endDate.isSameOrAfter(invoiceDate, 'day') && !project.active

    const bbeInfo = getProjectBBE(filter)(project)
    const aaInfo = getAAInfo(project, false, endDate)
    const correction = isFinished ? 0 : getCorrection(endDate.year().toString())(project)

    const feeCurrent = isFinished ? 0 : aaInfo.amountCHF //
    // const spesen = isFinished ? 0 : aaInfo.spesen // Neu: Spesen in Zusatzleistungen
    const expenses = isFinished
      ? getBudgetIrrelevantExpensesTotal(project.expenses)
      : aaInfo.expenses

    // Total Agentur
    const subTotal = isFinished ? 0 : feeCurrent + expenses // + spesen // Neu: Spesen in Zusatzleistungen

    const totalAgencyCurrent = bbeInfo.invoiceTotal + bbeInfo.aaIsTotal

    // Total AA aktuell
    const total = isFinished ? 0 : feeCurrent + expenses - correction // + spesen // Neu: Spesen in Zusatzleistungen
    const totalBrutto = isFinished ? aaInfo.subtotal : totalAgencyCurrent + correction

    const correctionNegative = isFinished ? totalAgencyCurrent - totalBrutto : -correction

    return {
      projectName: project.name, // Projekt
      billedCurrent: bbeInfo.invoiceTotal, // Fakturiert
      feeCurrent, // Honorar

      // spesen,  Spesen neun in Zusatzleistungen
      expenses, // Zusatzleistungen

      subTotal, // Total Agentur
      correction, // Korrektur
      total, // Total AA aktuell

      feeDifferent: bbeInfo.aaBefore, // AA Vorjahr
      bbeCurrent: bbeInfo.bbe, // BBE aktuell

      totalBrutto, // Total Agentur Brutto
      totalNetto: totalAgencyCurrent, // Total Agentur Netto

      correctionNegative, // Korrektur
      correctionPercentage: (correctionNegative / totalAgencyCurrent) * 100,
    }
  }

export const getData = (rootState: RootState, customerId: number): CustomerProfitability[] => {
  const filter = {
    ...getFilter(rootState, StatisticNames.customer),
    startDate: moment([2010, 0, 1]),
  }
  const allStates = getFilteredState(rootState, filter)

  const state = allStates.filteredByLabelState
  const projects = getProjects(state)
  const customers = getCustomers(state, true)

  // We need all activities to distinguish between activities in period and outside
  const activities = getActivities(state)
  const expenses = getExpenses(state)
  const invoices = getInvoices(state)
  const offers = getOffers(state)

  const extendedProjects = mapToExtendedProjects(
    R.values(projects),
    customers,
    R.values(activities),
    R.values(expenses),
    R.values(invoices),
    R.values(offers)
  )

  const endDate = filter.endDate ? filter.endDate : moment()

  const relevantProjects: ExtendedProject[] = R.filter(
    R.both(sameCustomer(customerId), sameInvoiceYear(endDate))
  )(extendedProjects)

  return R.map(getCustomerProfitability(filter))(relevantProjects)
}
